adSearchQueryBoxController.$inject = ['$scope', '$element', '$state', 'adSearchDialogService', '$timeout', 'adSearchQueryBoxService', 'adSearchService', 'advancedSearchService', 'context', 'homeModel', 'bvDialog', 'localizedMessages'];

function adSearchQueryBoxController($scope, $element, $state, adSearchDialogService, $timeout, adSearchQueryBoxService, adSearchService, advancedSearchService, context, homeModel, bvDialog, localizedMessages){
    var maxCount = 3;
    var maxLength = 100;

    var searchString = adSearchQueryBoxService.searchString;
    searchString.val="";
    $scope.searchString = searchString;
    adSearchQueryBoxService.setElement($element);

    var sleep = function(s, func){
        var i = 1;
        var interval = setInterval(function(){
            if(i >= s){
                clearInterval(interval);
                func();
            }
            i++;
        }, s !== undefined ? s : 1000);

        return interval;
    }

    var startSearching = function(){
    	setTimeout( function () {
    		if(adSearchQueryBoxService.searchString.val.length >= maxCount){
                adSearchDialogService.showAdSearch(adSearchQueryBoxService.searchString.val, $element);
            }
            // else{
            //     if('close' in adSearchDialogService.dialog)
            //         adSearchDialogService.dialog.close();
            // }

            if(typeof adSearchQueryBoxService.searchString.callback == 'function')
                adSearchQueryBoxService.searchString.callback();
    	}, 10);
    }

    var sleep_;

    const doSearch = (e) => {
        if(adSearchQueryBoxService.searchString.val.length < maxLength){
            clearInterval(sleep_);
            if($state.current.name != 'home.adsearch')
                sleep_ = sleep(0, startSearching);

            var adSearch = adSearchService.getInstance('ADVANCED');
            $timeout(function(){
                if(adSearch && 'filterPanelInstance' in adSearch){
                    if('keywordSelection' in adSearch.filterPanelInstance){
                        adSearch.filterPanelInstance.updateKeywords(adSearchQueryBoxService.searchString.val);
                    }
                }
            });
        }
        else{
            e.preventDefault();
            e.stopPropagation();
            bvDialog.showMessage(localizedMessages.get('YOU_HAVE_REACHED_THE_MAXIMUM_OF_CHARACTERS'));
        }
    }

    if(homeModel.isSearchEnabled.val){
        $element.bind('keydown paste', function(e){
            if(!StringUtils.isEnterKey(e)){
                if(StringUtils.isRightCharacter(e))
                    doSearch(e);
            }
        });
    }
}