NewApprovalDetailsController.$inject = ['$scope','$state','approvalsService', 'approvalAlertService', '$stateParams', 'context', 'bvDialog', 'reactInterface', 'localizedMessages','homeService'];

function NewApprovalDetailsController($scope, $state, approvalsService, approvalAlertService, $stateParams, context, bvDialog, reactInterface, localizedMessages, homeService)
{

	$scope.reactInterface = reactInterface;
	$scope.approvalBaseUuid = $stateParams.objectBaseUuid;
	$scope.approvalApiData = $stateParams.approvalApiData;
	$scope.isNewDetailsApprovalUIEnabled = context.getModel().companyVO.isNewDetailsApprovalUIEnabled;
	$scope.isNewApprovalReportUIEnabled = context.getModel().companyVO.isNewApprovalReportUIEnabled;
	
    $scope.tabs = [];
	$scope.tabs.push({
		title : localizedMessages.get("APPROVAL"),
		content : '',
		rightMenuList:approvalRightMenuList
	});
	$scope.middleBarObject.showBack = true;
	$scope.handleLeftIcon = () => {
        const { backButton } = reactInterface.callReactApproval
        backButton()
    }

	$scope.middleBarObject.title = localizedMessages.get("APPROVALS");
	$scope.svgFill = '#FFF';
	
	var selectedApprovalUuid = approvalsService.selectedApprovalUuid;

	var editApproval = function() {
		const approval = approvalsService.getCurrentSelectedApprovalFromList();
		// 'isNewCreateApprovalUIEnabled' property is dictating whether to use react ui or not
		// for react ui, we move to 'home.createNewApproval' with data. and for angular ui, we move to 'home.createApproval' without any data
		// to remove the angular support, condition checking and 'home.createApproval' state is to be removed
		context.getModel().companyVO.isNewCreateApprovalUIEnabled ? $state.go('home.createNewApproval', { 
			objectBaseUuid: selectedApprovalUuid,
			approvalApiData: approval
		}) : $state.go('home.createApproval');
	}

	var deleteApprovalConfirm = function() {
		bvDialog.confirm(localizedMessages.get("DELETE_APPROVAL_CONFIRM_MSG"), deleteApproval);
	}

	var closeApprovalConfirm = function() {
		bvDialog.confirm(localizedMessages.get("CLOSE_APPROVAL_CONFIRM_MSG"), closeApproval);
	}
	
	var reopenApprovalConfirm = function() {
		bvDialog.confirm(localizedMessages.get("REOPEN_APPROVAL_CONFIRM_MSG"), reopenApproval);
	}
	
	var deleteApproval = function() {
		approvalsService.deleteApproval(selectedApprovalUuid).then(function(data){
			console.log(data);
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
			$state.go('home.approvals');
		});
		$scope.handleLeftIcon();
	}

	var closeApproval = function() {
		approvalsService.closeApproval(selectedApprovalUuid).then(function(data){
			console.log(data);
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
			$state.go('home.approvals');
		});
		$scope.handleLeftIcon();
	}
	
	var reopenApproval = function() {
		approvalsService.reopenApproval(selectedApprovalUuid).then(function(data){
			console.log(data);
    		for(var i=0;i<$scope.tabs.length;i++){
    			if($scope.tabs[i].active == true){
    				approvalsService.setSelectedTab(i);
    				break;
    			}
    		}
			$state.go('home.approvals');
		});
		$scope.handleLeftIcon();
	}
	
	var closeAlertDlg = function closeAlertDlg() {
		$scope.alertDlg.close();
	};
	
	var alertApproval = function() {
		approvalAlertService.approval = approvalsService.getCurrentSelectedApprovalFromList();
		approvalAlertService.openAlertDialog();
	}

	var printApproval = function(options) {
		//console.log(options);
		bvDialog.printApproval();
	}

	var editOption = {
		title : localizedMessages.get("EDIT"),
		iconUrl : '',
		click : editApproval,
		stateName: 'ApprovalEdit'
	};

	var alertOption = {
		title : localizedMessages.get("ALERT"),
		iconUrl : '',
		click : alertApproval,
        stateName: 'ApprovalAlert'
	};

	var deleteOption = {
		title : localizedMessages.get("DELETE"),
		click : deleteApprovalConfirm,
		iconUrl : '',
        stateName: 'ApprovalDelete'
	};

	var printOption = {
		title : localizedMessages.get('REPORT'),
		click : printApproval,
		iconUrl: ''
	};

	if ($scope.isNewDetailsApprovalUIEnabled && $scope.isNewApprovalReportUIEnabled) {
		printOption.click = () => {
			const { openGenerateApprovalReportModal } = reactInterface.callReactApproval;
			openGenerateApprovalReportModal();
		}
	}

	var closeOption = {
			title : localizedMessages.get('CLOSE'),
			click : closeApprovalConfirm,
			iconUrl : '',
	        stateName: 'ApprovalClose'
	};
	
	var reopenOption = {
			title : localizedMessages.get('REOPEN'),
			click : reopenApprovalConfirm,
			iconUrl : '',
	        stateName: 'ApprovalReopen'
	};
	
	var approvalRightMenuList = [
	];

	$scope.tabs = [ {
		title : localizedMessages.get("APPROVAL"),
		content : 'openApproval',
		rightMenuList:approvalRightMenuList
	}];


	$scope.getApprovalDetailJSON = function () {
		const data = approvalsService.getCurrentSelectedApprovalFromList();
		const pastDueDate = data.dueDateGMTMillis < Date.now();
		const noDueDate = data.dueDateGMTMillis >= 32503708800000;
		const isClosed = data.closed == "true";
		const isActive = data.isActive;
		const isContentOwner = currentUserVO.isContentOwner;
		const isOwner = data.ownerList.some((user) => { return user.objectBaseUuid == currentUserVO.objectBaseUuid });
		if (isOwner) {
			approvalRightMenuList.push(editOption);
		}
		if (isActive && !pastDueDate && (isOwner || isContentOwner)) {
			approvalRightMenuList.push(alertOption);
		}
		if (isActive && noDueDate) {
			if (!isClosed && isOwner) {
				approvalRightMenuList.push(closeOption);
			}
			if (isClosed && (isOwner || isContentOwner)) {
				approvalRightMenuList.push(reopenOption);
			}
		}
		if (isOwner) {
			approvalRightMenuList.push(deleteOption);
		}
		approvalRightMenuList.push(printOption);
		return data;
	}

	var currentUserVO = context.getModel().userVO;
 
 

	$scope.getApprovalDetailJSON();
}
angular
.module('NewApprovalDetails', ['react.interface'])
.controller('NewApprovalDetailsController', NewApprovalDetailsController)