function conferenceService($rootScope, $q, ajaxService, responseValidatorService) {
	
	var service = {
		getConferenceParticipantsJSON: function(calendarEventObjectBaseUuid)
		{
			var request=ajaxService.getConferenceParticipantsJSON(calendarEventObjectBaseUuid);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		startConferenceJSON: function(calendarEventObjectBaseUuid)
		{
			var request=ajaxService.startConferenceJSON(calendarEventObjectBaseUuid);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		joinConferenceJSON: function(calendarEventObjectBaseUuid)
		{
			var request=ajaxService.joinConferenceJSON(calendarEventObjectBaseUuid);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		leftConference: function(calendarEventObjectBaseUuid, userObjectBaseUuid)
		{
			var request=ajaxService.leftConference(calendarEventObjectBaseUuid, userObjectBaseUuid);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		shareDocument: function(synchronizedCommunicationVOJson)
		{
			var request=ajaxService.shareDocument(synchronizedCommunicationVOJson);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		grantshareDesktop: function(userObjectBaseUuid, calendarEventObjectBaseUuid)
		{
			var request=ajaxService.grantshareDesktop(userObjectBaseUuid, calendarEventObjectBaseUuid);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		changePresenterGrantshareDesktop: function(userObjectBaseUuid, calendarEventObjectBaseUuid, pdfDetails)
		{
			var request=ajaxService.grantshareDesktop(userObjectBaseUuid, calendarEventObjectBaseUuid);
			return request.then(function(data) 
			{
				if(data)
				{
					console.log('pdfDetails: '+pdfDetails);
					$rootScope.$broadcast('presenter:updated', pdfDetails);
				}
				return null;
			});
		},
		synchronizeConferenceJSON: function(userObjectBaseUuid, calendarEventObjectBaseUuid, participantListJson)
		{
			var request=ajaxService.synchronizeConferenceJSON(userObjectBaseUuid, calendarEventObjectBaseUuid, participantListJson);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		getSynchronizedCommunicationVOJSON: function(calendarEventObjectBaseUuid)
		{
			var request=ajaxService.getSynchronizedCommunicationVOJSON(calendarEventObjectBaseUuid);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},
		createDocumentConference: function(selectedDocuments, conferenceParticipants, startDateVO, endDateVO)
		{
			var request=ajaxService.createDocumentConference(selectedDocuments, conferenceParticipants, startDateVO, endDateVO);
			return request.then(function(data) 
			{
				if(data)
				{
					return data;
				}
				return null;
			});
		},

	}
	return service;
}


function presentationService($rootScope, bvDialog, conferenceService, $timeout) {

	var conf = {};
	// conf_event
	// conf_participants
	// conf_sync_key_val
	// conf_sync_vo
	// rollcall
	var otherRunningConfs = [];
	//var showingRunningConfsFlag = false;
	//var showingConfFlag = false;

	var service = {

		/* Conf which you might be presenting or attending */
		alreadyInConf : function() 
		{
			if(conf != null && conf != undefined && conf.conf_event != null && conf.conf_event != undefined) {
				return true
			}
			return false;
		},

		setConf : function(sConf) {
			conf = sConf;
			$rootScope.$broadcast('conf:updated',conf);
		},

		getConf : function() {
			return conf;
		},

		setConfEvent : function(conf_event) {
			conf.conf_event = conf_event;
			$rootScope.$broadcast('conf:updated',conf);
		},

		updateConfParticipants : function(updatedParticipants) 
		{
			if(conf.conf_event != undefined && conf.conf_event != null) 
			{
				conf.conf_participants = updatedParticipants;
				$rootScope.$broadcast('confParticipants:updated', conf);
			}
		},

		updateConfSyncKeyVal : function(confSyncKeyVal) 
		{
			if(conf.conf_event != undefined && conf.conf_event != null) 
			{
				conf.conf_sync_key_val = confSyncKeyVal;
				$rootScope.$broadcast('confSyncKeyVal:updated', conf);
			}
		},
		
		updateConfSyncKeyValByMakePresenter : function(confSyncKeyVal) 
		{
			if(conf.conf_event != undefined && conf.conf_event != null) 
			{
				conf.conf_sync_key_val = confSyncKeyVal;
				$rootScope.$broadcast('confSyncKeyValByMakePresenter:updated', conf);
			}
		},
		

		updateConfSyncVO : function(confSyncKeyVO) 
		{
			if(conf.conf_event != undefined && conf.conf_event != null)
			{	
				conf.conf_sync_vo = confSyncKeyVO;
				$rootScope.$broadcast('confSyncKeyVO:updated', conf);
			}
		},

		updateConfCurrentPresentor: function(user) {
			conf.currentPresentor = user;
			$rootScope.$broadcast('confCurrentPresentor:updated', conf);
		},

		showConfDialog : function() {
			if(service.isShowingConfDialog() != true)
		 		bvDialog.showConfDialog();
		 	service.manageBackDrop();	
		},

		// showingConfDialog: function() {
		// 	showingConfFlag = true;
		// },

		closingConfDialog: function() {
			//showingConfFlag = false;
		 	service.manageBackDrop();	
		},

		isShowingConfDialog : function() {
			var presentation_dialog = document.querySelector('.bv-event-presentation');
			if(presentation_dialog != null && presentation_dialog != undefined)
				return true;

			return false;
		},
		/* End */

		/* All the running confs in which you might be attendee */
		setRunningConfs : function(confs) {
			otherRunningConfs = confs;
			$rootScope.$broadcast('runningConfs:updated',otherRunningConfs);
		},

		getRunningConfs : function() {
			return otherRunningConfs;
		},

		// showingRunningConfsDialog : function() {
		// 	showingRunningConfsFlag = true;
		// },

		closingRunningConfsDialog : function() {
			//showingRunningConfsFlag = false;
		 	service.manageBackDrop();	
		},

		isShowingRunningConfsDialog : function() {
			var running_confs_dialog = document.querySelector('.bv-running-conf-list-dialog');
			if(running_confs_dialog != null && running_confs_dialog != undefined)
				return true;
			return false;
		},

		showRunningConfsListDialog : function(cal_event)
		{
			if(!service.isShowingRunningConfsDialog() &&
				(cal_event != undefined || (otherRunningConfs.length !=0 && Object.keys(conf).length == 0))) {
	        	bvDialog.showRunningConfsListDialog(cal_event);
    		}
		 	service.manageBackDrop();
    	},

    	leaveConf : function(userBaseUuid) 
		{
			if(conf.conf_event == undefined || Object.keys(conf.conf_event).length == 0)
	 		{
	 			return service.setConf({});
	 		}
	 		else 
	 		{ 
		 		return conferenceService.leftConference(conf.conf_event.objectBaseUuid, userBaseUuid).then(function(data) 
			 	{
			 		return service.setConf({});
		 		});
		 	}	
		 	service.manageBackDrop();	
	 	},

	 	manageBackDrop : function() 
	 	{
	 		$timeout(function() {
					var backdrop = document.querySelector('.modal-backdrop');
					// var modal = document.querySelectorAll('.modal');
					// var running_conf_modal = document.querySelector('.bv-running-conf-list-dialog');
					// if(running_conf_modal != null && modal.length > 1) {
					// 	running_conf_modal.style.zIndex = "1050 !important";
					// 	// running_conf_modal.style.zIndex = "1050";
					// } else if(running_conf_modal != null){
					// 	running_conf_modal.style.zIndex = "100 !important";
					// 	// running_conf_modal.style.zIndex = "100";
					// }

					if(backdrop != null) {
						backdrop.style.position = "absolute";
				 		backdrop.style.zIndex = "99";
				 		backdrop.style.zIndex = "99 !important";
					}
			}, 200);
	 	}
		/* End */
	}
	return service;
}

angular
	.module('conference.service', [ 'ajax.service' ])
	.factory('conferenceService',['$rootScope', '$q', 'ajaxService', 'responseValidatorService', conferenceService ])
	.factory('presentationService',['$rootScope','bvDialog', 'conferenceService', '$timeout', presentationService]);