filtercriteriaService.$inject = ['$q', 'ajaxService','localizedMessages'];

function filtercriteriaService($q, ajaxService,localizedMessages) {
	this.reportFilterObject = {};
	var service = {	
			getFilterSettings: function(reportType){
			      this.useractivity = {
					  multiSelection: true,
					  isExclusive: false,
			    	  showDateRangeFilter: true,
					  showTeamSpaceFilter: true,
					  showGroupFilter: true,
					  showBoardTeamspaces: false,
					  showUserFilter: true,
					  showActivityFilter: {
						  enabled:false
					  }
				  };

				this.userdetails = {
					multiSelection: true,
					isExclusive: false,
					showDateRangeFilter: false,
					showTeamSpaceFilter: true,
					showGroupFilter: true,
					showBoardTeamspaces: false,
					showUserFilter: true,
					showActivityFilter: {
						enabled:false
					}
				};

				this.passwordexpiry = {
					multiSelection: true,
					isExclusive: false,
					showDateRangeFilter: false,
					showTeamSpaceFilter: true,
					showGroupFilter: true,
					showBoardTeamspaces: false,
					showUserFilter: true,
					showActivityFilter: {
						enabled:false
					}
				};
			      this.groupattendancetracking = {
						  multiSelection: true,
						  isExclusive: false,
				    	  showDateRangeFilter: true,
						  showTeamSpaceFilter: false,
						  showGroupFilter: false,
						  showBoardTeamspaces: true,
						  showUserFilter: false,
						  showActivityFilter: {
							  enabled:false
						  }
					  };
			      this.memberattendancetracking = {
						  multiSelection: false,
						  isExclusive: false,
				    	  showDateRangeFilter: true,
						  showTeamSpaceFilter: false,
						  showGroupFilter: false,
						  showBoardTeamspaces: false,
						  showUserFilter: true,
						  showActivityFilter: {
							  enabled:false
						  }
					  };
			      this.documentlist = {
		    		  multiSelection: true,
					  isExclusive: false,
			    	  showDateRangeFilter: false,
			    	  showTeamSpaceFilter: true,
			    	  showGroupFilter: false,
			    	  showBoardTeamspaces: false,
			    	  showUserFilter: false,
					  showActivityFilter: {
						  enabled:false
					  }
			      };
			      this.retentionpolicies = {
			    		  multiSelection: true,
						  isExclusive: false,
				    	  showDateRangeFilter: true,
				    	  showTeamSpaceFilter: false,
				    	  showGroupFilter: false,
				    	  showBoardTeamspaces: false,
				    	  showUserFilter: false,
						  showActivityFilter: {
							  enabled:false
						  }
				      };
			      this.documentpermissions = {
		    		  multiSelection: false,
					  isExclusive: false,
		    		  showDateRangeFilter: false,
			    	  showTeamSpaceFilter: true,
			    	  showGroupFilter: true,
			    	  showBoardTeamspaces: false,
			    	  showUserFilter: true,
					  showActivityFilter: {
						  enabled:false
					  }
			      };
			      this.meetinglist = {
		    		  multiSelection: true,
					  isExclusive: false,
			    	  showDateRangeFilter: true,
			    	  showTeamSpaceFilter: true,
			    	  showGroupFilter: false,
			    	  showBoardTeamspaces: false,
			    	  showUserFilter: false,
			    	  showActivityFilter: {
						  enabled:false
					  }
			      };
			      this.userchanges = {
		    		  multiSelection: true,
					  isExclusive: false,
			    	  showDateRangeFilter: true,
			    	  showTeamSpaceFilter: false,
			    	  showGroupFilter: false,
			    	  showBoardTeamspaces: false,
			    	  showUserFilter: true,
			    	  showActivityFilter: {
						  enabled:true,
						  activity:[{
							    id: 1,
							    type: 'User Added',
							    selected: true,
								label: localizedMessages.get('USER_ADDED')
							  },
							  {
							    id: 2,
							    type: 'User Activated',
							    selected: false,
								label: localizedMessages.get('USER_ACTIVATED')
							  },
							  {
							    id: 3,
							    type: 'User Deactivated',
							    selected: false,
								label: localizedMessages.get('USER_DEACTIVATED')
							  },
							  {
							    id: 4,
							    type: 'User Locked',
							    selected: false,
								label: localizedMessages.get('USER_LOCKED')
							  },
							  {
							    id: 5,
							    type: 'User Unlocked',
							    selected: false,
								label: localizedMessages.get('USER_UNLOCKED')
							  },
							  {
							    id: 6,
							    type: 'User Group Changed',
							    selected: false,
								label: localizedMessages.get('USER_GROUP_CHANGED')
							  },
							  {
							    id: 7,
							    type: 'User Teamspace Changed',
							    selected: false,
								label: localizedMessages.get('USER_TEAMSPACE_CHANGED')
							  }]
					  }
			      };
			      this.documentactivity = {
		    		  multiSelection: true,
					  isExclusive: false,
			    	  showDateRangeFilter: true,
			    	  showTeamSpaceFilter: false,
			    	  showGroupFilter: true,
			    	  showUserFilter: true,
			    	  showActivityFilter: {
						  enabled:true,
						  activity:[{
							    id: 1,
							    type: 'File Viewed',
							    selected: true,
								label: localizedMessages.get('FILE_VIEWED_ACTIVITY')
							  },
							  {
							    id: 2,
							    type: 'File Printed',
							    selected: false,
								label: localizedMessages.get('FILE_PRINTED')
							  },
							  {
								id: 3,
								type: 'File Downloaded',
							    selected: false,
								label: localizedMessages.get('FILE_DOWNLOADED')
							  },
							  {
								id: 4,
								type: 'File Edited',
							    selected: false,
								label: localizedMessages.get('FILE_EDITED')
							  },
							  {
							    id: 5,
							    type: 'File Uploaded',
							    selected: false,
								label: localizedMessages.get('FILE_UPLOADED')
							  },
							  {
							    id: 6,
							    type: 'File Deleted',
							    selected: false,
								label: localizedMessages.get('FILE_DELETED')
							  },
							  {
							    id: 7,
							    type: 'File Emailed',
							    selected: false,
								label: localizedMessages.get('FILE_EMAILED')
							  },
							  {
							    id: 8,
							    type: 'File Moved',
							    selected: false,
								label: localizedMessages.get('FILE_MOVED')
							  },
							  {
							    id: 9,
							    type: 'File Copied',
							    selected: false,
								label: localizedMessages.get('FILE_COPIED')
							  },
							  {
								id: 10,
								type: 'File Renamed',
							    selected: false,
								label: localizedMessages.get('FILE_RENAMED')
							  },
							  {
								  id: 11,
								  type: 'File PDF Downloaded',
								  selected: false,
								  label: localizedMessages.get('FILE_PDF_DOWNLOADED')
							  },
							  {
								id: 12,
								type: 'Folder Created',
							    selected: false,
								label: localizedMessages.get('FOLDER_CREATED')
							  },
							  {
								id: 13,
								type: 'Folder Deleted',
							    selected: false,
								label: localizedMessages.get('FOLDER_DELETED')
							  },
							  {
								id: 14,
								type: 'Folder Moved',
							    selected: false,
								label: localizedMessages.get('FOLDER_MOVED')
							  },
							  {
								id: 15,
								type: 'Folder Copied',
							    selected: false,
								label: localizedMessages.get('FOLDER_COPIED')
							  },
							  {
								id: 16,
								type: 'Folder Renamed',
							    selected: false,
								label: localizedMessages.get('FOLDER_RENAMED')
							  }]
					  }
				  };

				  this.userbriefcasereport = {
                      multiSelection: false,
                      isExclusive: false,
                      showDateRangeFilter: false,
                      showTeamSpaceFilter: false,
                      showGroupFilter: false,
                      showBoardTeamspaces: false,
                      showUserFilter: true,
                      showActivityFilter: {
                          enabled:false
                      }
                  };

                  this.syncedinformationreport = {
                      multiSelection: false,
                      isExclusive: false,
                      showDateRangeFilter: false,
                      showTeamSpaceFilter: false,
                      showGroupFilter: false,
                      showBoardTeamspaces: true,
                      showUserFilter: false,
                      showActivityFilter: {
                          enabled: false
                      }
                  };
                  this.folderpermissions = {
                		  multiSelection: false,
    					  isExclusive: false,
    		    		  showDateRangeFilter: false,
    			    	  showTeamSpaceFilter: true,
    			    	  showGroupFilter: false,
    			    	  showBoardTeamspaces: false,
    			    	  showUserFilter: false,
    					  showActivityFilter: {
    						  enabled:false
    					  }
    			  };

			      switch (reportType) {
				      case 'useractivity':
				          return this.useractivity;
				          break;
					  case 'userdetails':
						  return this.userdetails;
						  break;
				      case 'documentlist':
				    	  return this.documentlist;
				    	  break;
				      case 'retentionpolicies':
				    	  return this.retentionpolicies;
				    	  break;
				      case 'memberattendancetracking':
				    	  return this.memberattendancetracking;
				    	  break;
				      case 'groupattendancetracking':
				    	  return this.groupattendancetracking;
				    	  break;
				      case 'meetinglist':
				    	  return this.meetinglist;
				    	  break;
				      case 'userchanges':
				    	  return this.userchanges;
				    	  break;
				      case 'documentactivity':
				    	  return this.documentactivity;
				    	  break;
				      case 'documentpermissions':
                          return this.documentpermissions;
                          break;
                      case 'userbriefcasereport':
                          return this.userbriefcasereport;
                          break;
                      case 'syncedinformationreport':
                          return this.syncedinformationreport;
					  case 'passwordexpiry' :
					  	  return this.passwordexpiry;
					  case 'folderpermissions' :
					  	  return this.folderpermissions;	  
				  }
		    },
			getFilterData: function(restUrl){
				var request = ajaxService.getFilterData(restUrl);
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			},
			getUsersFilterData: function(postData, restUrl){
				var request = ajaxService.getUsersFilterData(postData, restUrl);
				return request.then(function(response) {
					if (response) {
						return response.data;
					}
					return null;
				});
			}
	
	}
	return service;
}
	