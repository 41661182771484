filtercriteriaController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'context', 'localizedMessages', 'filtercriteriaService'];

function filtercriteriaController($scope, $rootScope, $state, $timeout, context, localizedMessages, filtercriteriaService){
	var m = moment;
	var ml = StringUtils.momentL;
	var reportType = $state.$current.name.split("home.reports.")[1];
	var getFilterDataRestUrl = 'getFilterData';
	var getUsersFilterDataRestUrl = 'getUsersFilterData';
	$scope.teamSpaceFilter;
	$scope.groupsFilter;
	$scope.boardTeamspaceFilter;
	$scope.reportFilterObject;
	$scope.initStartedMsg = 'Initialize Started';
	$scope.initFinishedMsg = 'Initialize Finished';
	$scope.filterObjectMsg = 'Filter Object Updated';
	$scope.filterSettings = filtercriteriaService.getFilterSettings(reportType);
	$scope.getFilterData;
	$scope.getUsersFilterData = [];
	$scope.searchUser;
	$scope.userDropdownOpen;
	$scope.showSelectedAllTeamSpaces = false;
	$scope.showSelectedAllGroups = false;
	$scope.showSelectedBoardTeamspace = false;
	$scope.isExclusive = $scope.filterSettings.isExclusive;
	$scope.title = {
	        refineResults : localizedMessages.get('REFINE_RESULTS'),
	        dateRange : localizedMessages.get('DATE_RANGE'),
	        teamSpaces : localizedMessages.get('TEAM_SPACES'),
	        groups : localizedMessages.get('GROUPS'),
	        appliedGroups : localizedMessages.get('APPLIED_GROUPS_UPPERCASE'),
	        users : localizedMessages.get('USERS'),
	        appliedUsers : localizedMessages.get('APPLIED_USERS_UPPERCASE'),
	        reset: localizedMessages.get('RESET'),
	        last: localizedMessages.get('LAST'),
	        days: localizedMessages.get('DAYS'),
	        month: localizedMessages.get('MONTH'),
	        months: localizedMessages.get('MONTHS'),
	        year: localizedMessages.get('YEAR'),
	        customDateRange: localizedMessages.get('CUSTOM_DATE_RANGE'),
	        selectDateRangeMsg: localizedMessages.get('SELECT_DATE_RANGE'),
	        from: localizedMessages.get('FROM'),
	        to: localizedMessages.get('TO'),
	        searchTeamSpaces: localizedMessages.get('SEARCH_TEAM_SPACES'),
	        searchTeamspacesMsg : localizedMessages.get('SEARCH_TEAMSPACES'),
	        searchGroups : localizedMessages.get('SEARCH_GROUPS'),
	        appliedTeamSpaces: localizedMessages.get('APPLIED_TEAM_SPACES'),
	        selectAll : localizedMessages.get('SELECT_ALL'),
	        activityType : localizedMessages.get('ACTIVITY_TYPE'),
	        allTeamspacesSeleceted : localizedMessages.get('ALL_TEAMSPACES_SELECTED'),
	        allGroupsSelected : localizedMessages.get('ALL_GROUPS_SELECTED'),
	        boardTeamspaceFilter : localizedMessages.get('BOARD_TEAMSPACE_FILTER'),
	        searchBoardAndTeamspace : localizedMessages.get('SEARCH_BOARD_AND_TEAMSPACE'),
	        appliedBoardAndTeamspace : localizedMessages.get('APPLIED_BOARD_AND_TEAMSPACE_UPPERCASE'),
	        allBoardAndTeamspaceSelected : localizedMessages.get('ALL_BOARD_AND_TEAMSPACE_SELECTED')
	};
	
	$scope.isOpen = function(elemName){
		$timeout(function() {
			switch(elemName){
		        case 'teamspace':
		        	document.getElementById('teamspace').value = '';
		        	$scope.search = {teamSpaceFilter : ''};
		        	angular.element('#teamspace').focus();
		        	$scope.userPostData.filterCriteria.username = '';
		        break;
		        case 'groups':
		        	document.getElementById('groups').value = '';
		        	$scope.search = {groupsFilter : ''};
		        	angular.element('#groups').focus();
		        	$scope.userPostData.filterCriteria.username = '';
		        break;
		        case 'boardTeamspace':
		        	document.getElementById('boardTeamspace').value = '';
		        	$scope.search = {boardTeamspaceFilter : ''};
		        	angular.element('#boardTeamspace').focus();
		        	$scope.userPostData.filterCriteria.username = '';
		        break;
			}
        }, 500);
	}
	
	//* Date Range selection Object 
	$scope.rangeSelection = {
			select : '7days',
			from: 0,
			to: 0,
	        datePickFrom : {},
	        datePickTo : {},
	        dateFormate: 'MMM Do YYYY'
    };
	
	//* Date Range calculations 
	$scope.calculateRange = function(refresh){
		var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			switch($scope.rangeSelection.select){
		        case 'selectDateRange':
		        	$scope.rangeSelection.from = null;
		        	$scope.rangeSelection.to = null;
		        break;
		        case '7days':
		        	var sDate = new Date(m().add(-7, 'days').startOf('day').valueOf());
		        	var eDate = new Date(m().endOf('day').valueOf());
		        	$scope.rangeSelection.from = Date.parse((monthNames[sDate.getMonth()] + " " + sDate.getDate() + ", " + sDate.getFullYear()).toString() + " 00:00:00");
		        	$scope.rangeSelection.to = Date.parse((monthNames[eDate.getMonth()] + " " + eDate.getDate() + ", " + eDate.getFullYear()).toString() + " 23:59:59");
		        break;
		        case '15days':
		        	var sDate = new Date(m().add(-15, 'days').startOf('day').valueOf());
		        	var eDate = new Date(m().endOf('day').valueOf());
		        	$scope.rangeSelection.from = Date.parse((monthNames[sDate.getMonth()] + " " + sDate.getDate() + ", " + sDate.getFullYear()).toString() + " 00:00:00");
		        	$scope.rangeSelection.to = Date.parse((monthNames[eDate.getMonth()] + " " + eDate.getDate() + ", " + eDate.getFullYear()).toString() + " 23:59:59");
		        break;
		        case '1month':
		        	var sDate = new Date(m().add(-1, 'month').startOf('day').valueOf());
		        	var eDate = new Date(m().endOf('day').valueOf());
		        	$scope.rangeSelection.from = Date.parse((monthNames[sDate.getMonth()] + " " + sDate.getDate() + ", " + sDate.getFullYear()).toString() + " 00:00:00");
		        	$scope.rangeSelection.to = Date.parse((monthNames[eDate.getMonth()] + " " + eDate.getDate() + ", " + eDate.getFullYear()).toString() + " 23:59:59");
		        break;
		        case '6months':
		        	var sDate = new Date(m().add(-6, 'month').startOf('day').valueOf());
		        	var eDate = new Date(m().endOf('day').valueOf());
		        	$scope.rangeSelection.from = Date.parse((monthNames[sDate.getMonth()] + " " + sDate.getDate() + ", " + sDate.getFullYear()).toString() + " 00:00:00");
		        	$scope.rangeSelection.to = Date.parse((monthNames[eDate.getMonth()] + " " + eDate.getDate() + ", " + eDate.getFullYear()).toString() + " 23:59:59");
		        break;
		        case '1year':
		        	var sDate = new Date(m().add(-1, 'year').startOf('day').valueOf());
		        	var eDate = new Date(m().endOf('day').valueOf());
		        	$scope.rangeSelection.from = Date.parse((monthNames[sDate.getMonth()] + " " + sDate.getDate() + ", " + sDate.getFullYear()).toString() + " 00:00:00");
		        	$scope.rangeSelection.to = Date.parse((monthNames[eDate.getMonth()] + " " + eDate.getDate() + ", " + eDate.getFullYear()).toString() + " 23:59:59");
		        break;
		        case 'custom':
		        	var sDate = new Date(m().startOf('day').valueOf());
                    var eDate = new Date(m().endOf('day').valueOf());
                    $scope.rangeSelection.from = Date.parse((monthNames[sDate.getMonth()] + " " + sDate.getDate() + ", " + sDate.getFullYear()).toString() + " 00:00:00");
                    $scope.rangeSelection.to = Date.parse((monthNames[eDate.getMonth()] + " " + eDate.getDate() + ", " + eDate.getFullYear()).toString() + " 23:59:59");
                    $timeout(function() {
                    	  angular.element('#date-picker-to').datepicker().datepicker("setDate", new Date());
                    	  angular.element('#date-picker-from').datepicker().datepicker("setDate", new Date());                       
                          angular.element('#date-picker-from').triggerHandler('click');                         
                     }, 500);

                break;
			}
			if(refresh){
				$scope.filterObject.rangeSelection.startDate = $scope.rangeSelection.from;
				$scope.filterObject.rangeSelection.endDate = $scope.rangeSelection.to;
				updateReportFilterObj();
			}
	};
	
	$scope.toggleDropdown = function(dropDownId) {
		$timeout(function() {
			angular.element(dropDownId).triggerHandler('click');
        }, 100);
  	};

	
	//* Data Sets convert to Array
	$scope.getSetAsArr = function (set) {
	    var arr = [];
	    set.forEach(function (value) {
	        arr.push(value);
	    });
	    return arr;
	};
	
	//* Data Sets values convert to Array
	$scope.getSetValAsArr = function (set) {
	    var arr = [];
	    set.forEach(function (value) {
	        arr.push(value.ID);
	    });
	    return arr;
	};
	
	//* User post data Object
	$scope.userPostData = { 
			"pageNumber": 1, 
		    "size": -1, 
		    "sortField": "date", 
		    "sortOrder": "desc", 
		    "filterCriteria" : { 
		        "users": [ 
		            ], 
		            "teamspaces": [ 
		            ], 
		            "groups": [ 
		            ],
		        "startDate": null, 
		        "endDate": null, 
		        "username": null, 
		    } 
	};
	
	//* Filter Object
	$scope.filterObject = {
			reportType: reportType,
			rangeSelection: {
				startDate: null,
				endDate: null
			},
			availableTeamspaces: null,
			availableGroupsMap: new Map(),
			applicableGroups: new Set(),
			eligibleGroups: [],
			availableUsers: new Map(),
			selectedTeamspaces: new Set(),
			selectedGroups: new Set(),
			selectedBoardTeamspaces: new Set(),
			boardTeamspaceItems: [],
			selectedUsers: new Set(),
			selectedActivityTypes: new Set(),
	};
	
	//* Fetching users filter data 
	$scope.getUsers = function(){
		filtercriteriaService.getUsersFilterData($scope.userPostData, getUsersFilterDataRestUrl).then(function(response) {
			if(response){
				$scope.getUsersFilterData = response;
			}
		});
	};
	
	//* Filter Object watcher
	$scope.$watch('filterObject', function (newValue, oldValue) {
		$scope.filterObject = newValue;
	}, true);
	
	
	//* User Object watcher
	$scope.$watch('userPostData.filterCriteria.username', function (newValue, oldValue) {
		if(newValue && newValue.length > 2){
			$scope.getUsers();
			$timeout(function() {
                if(!angular.element('#searchField').hasClass('open')){
                	angular.element('#users').triggerHandler('click');
                }
            }, 300);
		}else{
			
			$scope.getUsersFilterData = [];
		}
	}, true);
	
	
	//* init starts 
	var init = function(){
		$scope.$emit('initStarted', $scope.initStartedMsg);
		console.log(reportType + " reporttype")
		//** Default Activity Type
		if(reportType == 'userchanges'){
			$scope.filterObject.selectedActivityTypes.add('User Added');
		}else if(reportType == 'documentactivity'){
			$scope.filterObject.selectedActivityTypes.add('File Viewed');
		}else if(reportType == 'userdetails'){
			$scope.rangeSelection.select="selectDateRange"
			$scope.rangeSelection.from = null;
			$scope.rangeSelection.to = null;
			$scope.filterObject.rangeSelection.startDate = null;
			$scope.filterObject.rangeSelection.endDate = null;

		}
		
		filtercriteriaService.getFilterData(getFilterDataRestUrl).then(function(response) {
			if(response){
				$scope.filterObject.availableTeamspaces = response.teamspaces;
				response.teamspaces.forEach(function(space){	
					space.Groups.forEach(function(group){
						$scope.filterObject.availableGroupsMap.set(group.ID,group.Name);
						$scope.filterObject.applicableGroups.add(group.ID);
					});
				});
				
				response.companyGroups.forEach(function(group){
					$scope.filterObject.availableGroupsMap.set(group.ID,group.Name);
					$scope.filterObject.applicableGroups.add(group.ID);
				});
				
				$scope.filterObject.applicableGroups.forEach(function(val){
					$scope.filterObject.eligibleGroups.push({'ID':val,'Name':$scope.filterObject.availableGroupsMap.get(val)})
				});
				
				$scope.filterObject.boardTeamspaceItems = response.companyGroups.concat($scope.filterObject.eligibleGroups, response.teamspaces);
				
				//** Default Select All TeamSpace
				if(reportType == 'documentlist'){
					$scope.filterObject.selectedTeamspaces = new Set($scope.filterObject.availableTeamspaces);
					$scope.showSelectedAllTeamSpaces = true;
					filtercriteriaService.reportFilterObject = $scope.filterObject;
					$scope.reportFilterObject = filtercriteriaService.reportFilterObject;
					$scope.$emit('initFinished', $scope.initFinishedMsg);
				}else{
					filtercriteriaService.reportFilterObject = $scope.filterObject;
					$scope.reportFilterObject = filtercriteriaService.reportFilterObject;
					$scope.$emit('initFinished', $scope.initFinishedMsg);
				}
			}
		});
	};
	
	
	//** Disable filters - not disabling filters MEETX-35182
	$scope.getDisabledFilters = function(reportType){
		if(reportType == 'documentpermissions'){
			$timeout(function() {
				if(($scope.filterObject.selectedTeamspaces).size > 0 || ($scope.filterObject.selectedGroups).size > 0 || ($scope.filterObject.selectedUsers).size > 0){
					filtercriteriaService.documentpermissions.isExclusive = false;
					$scope.isExclusive = $scope.filterSettings.isExclusive;
				}else{
					filtercriteriaService.documentpermissions.isExclusive = false;
					$scope.isExclusive = $scope.filterSettings.isExclusive;
				}
	        }, 500);
		}
	}
	
	
    //* Refreshing Report Filter object
	var updateReportFilterObj = function(){
		$scope.userPostData.filterCriteria.teamspaces = $scope.getSetValAsArr($scope.filterObject.selectedTeamspaces);
		$scope.userPostData.filterCriteria.groups = $scope.getSetAsArr($scope.filterObject.selectedGroups);
		//$scope.userPostData.filterCriteria.boardTeamspace = $scope.getSetValAsArr($scope.filterObject.selectedBoardTeamspaces);
		filtercriteriaService.reportFilterObject = $scope.filterObject;
		// $scope.getDisabledFilters(reportType);
		$scope.$emit('filterObjectChanged', $scope.filterObjectMsg);
	};
	
	
	//* Filter triggers
	
	//** Date Range filter 
   $scope.dateChangeFrom = function(){
	    var timeStampFrom = Date.parse($scope.rangeSelection.datePickFrom.startingDate + " 00:00:00");
    	$scope.rangeSelection.from = timeStampFrom;
    	$scope.filterObject.rangeSelection.startDate = $scope.rangeSelection.from;
    	var toDateValue = new Date(Date.parse($scope.rangeSelection.datePickTo.instance.element[0].value));
	    var toDateStartingDate = new Date(Date.parse($scope.rangeSelection.datePickTo.startingDate));
        if($scope.filterObject.rangeSelection.endDate === null || toDateValue > toDateStartingDate){
        	$timeout(function() {
                angular.element('#date-picker-to').triggerHandler('click');
            }, 500);
        }else{
        	updateReportFilterObj();
        }
    };
    $scope.dateChangeTo = function(){
    	var timeStampTo = Date.parse($scope.rangeSelection.datePickTo.startingDate + " 23:59:59");
    	$scope.rangeSelection.to = timeStampTo;
    	$scope.filterObject.rangeSelection.endDate = $scope.rangeSelection.to;
        updateReportFilterObj();
    };
    $scope.resetDateRangeFilter = function(){
    	$scope.rangeSelection.select = 'selectDateRange';
    	$scope.calculateRange(true);
    	angular.element('#date-picker-from').datepicker().datepicker("setDate", new Date());
    	angular.element('#date-picker-to').datepicker().datepicker("setDate", new Date());
	};
    
    
    
    //** Team space filter
	$scope.selectTeamSpace = function(selectedTeamSpace) {
		$scope.filterObject.selectedTeamspaces.add(selectedTeamSpace);
		$scope.filterObject.teamSpaceFilter = '';
    	updateReportFilterObj();
    	$scope.toggleDropdown('#teamSpaceDropDown');
	};
	$scope.selectAllTeamSpace = function() {
		$scope.showSelectedAllTeamSpaces = true;
		var teamSpaces = $scope.filterObject.availableTeamspaces;
		teamSpaces.forEach(function(space){
			$scope.filterObject.selectedTeamspaces.add(space);
		});
		$scope.filterObject.teamSpaceFilter = '';
    	updateReportFilterObj();
    	$scope.toggleDropdown('#teamSpaceDropDown');
	};
	$scope.removeTeamSpace = function(item){
		$scope.filterObject.selectedTeamspaces.delete(item);
    	updateReportFilterObj();
	};
	$scope.resetTeamspaceFilter = function(){
		$scope.showSelectedAllTeamSpaces = false;
		$scope.filterObject.selectedTeamspaces.clear();
		updateReportFilterObj();
	};
	
	//** Groups filter
	$scope.selectGroup = function(selectedGroup) {
		$scope.filterObject.selectedGroups.add(selectedGroup);
		$scope.filterObject.groupsFilter = '';
    	updateReportFilterObj();
    	$scope.toggleDropdown('#groupsDropDown');
	};
	$scope.selectAllGroups = function() {
		$scope.showSelectedAllGroups = true;
		var groups = $scope.filterObject.applicableGroups;
		groups.forEach(function(group){
			$scope.filterObject.selectedGroups.add(group);
		});
		$scope.filterObject.groupsFilter = '';
    	updateReportFilterObj();
    	$scope.toggleDropdown('#groupsDropDown');
	};
	$scope.removeGroup = function(item){
		$scope.filterObject.selectedGroups.delete(item);
    	updateReportFilterObj();
	};
	$scope.resetGroupFilter = function(){
		$scope.showSelectedAllGroups = false;
		$scope.filterObject.selectedGroups.clear();
		updateReportFilterObj();
	};
	
	
	//** boardTeamspace filter
	$scope.selectBoardTeamspace = function(selectedBoardTeamspace) {
		$scope.filterObject.selectedBoardTeamspaces.add(selectedBoardTeamspace);
		$scope.filterObject.boardTeamspaceFilter = '';
    	updateReportFilterObj();
    	$scope.toggleDropdown('#boardTeamspaceDropDown');
	};	
	$scope.removeBoardTeamspace = function(item){
		$scope.filterObject.selectedBoardTeamspaces.delete(item);
    	updateReportFilterObj();
	};
	$scope.resetBoardTeamspaceFilter = function(){
		$scope.showSelectedBoardTeamspace = false;
		$scope.filterObject.selectedBoardTeamspaces.clear();
		updateReportFilterObj();
	};
	
	//** Users filter
	$scope.selectedUsers = function(userID, userName) {
		$scope.filterObject.availableUsers.set(userID, userName);
		$scope.filterObject.selectedUsers.add(userID);
    	$scope.userPostData.filterCriteria.username = '';
    	updateReportFilterObj();
    	$scope.toggleDropdown('#users');
	};
	$scope.removeUser = function(item){
		$scope.filterObject.selectedUsers.delete(item);
    	updateReportFilterObj();
	};
	$scope.resetUsersFilter = function(){
		$scope.filterObject.selectedUsers.clear();
		$scope.userPostData.filterCriteria.username = '';
		updateReportFilterObj();
	};
	
	//** Activity Type Filter
	$scope.activityTypeSelected = function(activitySelected, activityType){
		if (activitySelected) {
      		$scope.filterObject.selectedActivityTypes.add(activityType);
      	}else{
      		$scope.filterObject.selectedActivityTypes.delete(activityType);
      	}
		updateReportFilterObj();
	}
	
	$scope.resetActivityTypeFilter = function(){
		angular.forEach($scope.filterSettings.showActivityFilter.activity, function(activity_id) {              
			activity_id.selected = false;
	    });
		$scope.filterObject.selectedActivityTypes.clear();
		updateReportFilterObj();
	}
	
	//** Reset Filter
	$scope.resetAll = function(){
		$scope.resetDateRangeFilter();
		$scope.resetTeamspaceFilter();
		$scope.resetGroupFilter();
		$scope.resetBoardTeamspaceFilter();
		$scope.resetUsersFilter();
		$scope.resetActivityTypeFilter();
	};
	
	// Default daterange setting
	$scope.calculateRange(true);
	init();
}
function unique(){
	 return function(collection, keyname) {
	      // we define our output and keys array;
	      var output = [], 
	          keys = [];
	      
	      // we utilize angular's foreach function
	      // this takes in our original collection and an iterator function
	      angular.forEach(collection, function(item) {
	          // we check to see whether our object exists
	          var key = item[keyname];
	          // if it's not already part of our keys array
	          if(keys.indexOf(key) === -1) {
	              // add it to our keys array
	              keys.push(key); 
	              // push this item to our final output array
	              output.push(item);
	          }
	      });
	      // return our array which should be devoid of
	      // any duplicates
	      return output;
	   };
}
