var EmailController = [
	"$scope","$rootScope", "$state","reactInterface", "localizedMessages", "homeService", "$location", "context",
	function($scope, $rootScope, $state, reactInterface, localizedMessages, homeService, $location, context) {
		var inboxOpenIntent, 
			composeOpenIntent, 
			tagMenuIntent, 
			deleteIntent, 
			deleteTrashIntent, 
			printAll,
			readIntent,
			restoreIntent;

		var currentPortalUuid = context.getModel().portalVO.objectBaseUuid;
		var userIsPortalOwner = false;
		var userIsContentOwner = false;
		var portalPrivileges = context.getModel().userVO.portalPrivileges || [];
		for( var portalPrivilege of portalPrivileges ) {
			if( portalPrivilege.portalBaseUuid === currentPortalUuid ) {
				userIsPortalOwner = portalPrivilege.portalOwner;
				userIsContentOwner = portalPrivilege.contentOwner;
			}
		}

		$scope.showComposeButton = (
			!context.getModel().companyVO.readOnlyEmail === true ||
			userIsContentOwner === true ||
			userIsPortalOwner === true
		);

		setTimeout(function(){
			$scope.EmailSelectedValue = homeService.getEmailSelectedValue();
			$rootScope.EmailSelectedOption = $scope.EmailSelectedValue;
			}, 500);

		
		$scope.openInbox = function() {
			inboxOpenIntent();
		};
		$scope.openCompose = function() {
			composeOpenIntent();
		};
		$scope.openTagMenuItem = function() {
			tagMenuIntent();
		};
		$scope.deleteItem = function() {
			deleteIntent();
		};
		$scope.deleteTrashItem = function() {
			deleteTrashIntent();
		};
		$scope.printAll = function() {
			printAll();
		};
		$scope.readItem = function() {
			readIntent();
		};
		$scope.restoreItem = function() {
			restoreIntent();
		};
		
		$scope.handleLeftIcon = function() {
			$scope.openInbox();
		};
		
		const composeButton = {
		  title: localizedMessages.get("COMPOSE"),
		  click: $scope.openCompose,
		  iconUrl: "",
		  svgFill: "#fff"
		};
		
		const tagsButton = {
		  title: localizedMessages.get("ADD_TAGS"),
		  click: $scope.openTagMenuItem,
		  iconUrl: "",
		  svgFill: "#fff"
		};
		
		const deleteButton = {
		  title: localizedMessages.get("DELETE"),
		  click: $scope.deleteItem,
		  iconUrl: "",
		  svgFill: "#fff"
		};
		
		
		const deleteTrashButton = {
		  title: localizedMessages.get("DELETE"),
		  click: $scope.deleteTrashItem,
		  iconUrl: "",
		  svgFill: "#fff"
		};
		
		const printButton = {
			title: localizedMessages.get("PRINT"),
			click: $scope.printAll,
			iconUrl: "",
			svgFill: "#fff"
		  };

		const readButton = {
		  title: localizedMessages.get("MARK_ALL_READ"),
		  click: $scope.readItem,
		  iconUrl: "",
		  svgFill: "#fff"
		};
		
		const restoreButton = {
		  title: localizedMessages.get("RESTORE"),
		  click: $scope.restoreItem,
		  iconUrl: "",
		  svgFill: "#fff"
		};
		
		const defaultTitle = localizedMessages.get("EMAIL");
		var rightMenuList = [composeButton];
		
		$scope.middleBarObject.title = defaultTitle;
		$scope.middleBarObject.showBack = false;
		$scope.svgFill = '#FFF';
		
		$scope.tabs = [{
			title: localizedMessages.get("EMAIL"),
			content: "email",
			rightMenuList: rightMenuList,
			order: 0
		}];
		
		$scope.accessControl = function(values) {
			let tabs = [];
			const {
				pageName,
				activeBoxType,
				params,
				showRightButton,
				allowUsersToPrintEmail,
				allowUsersToDeleteEmail
			} = values;

			let deleteButtons = allowUsersToDeleteEmail ? [deleteButton] : []
			let deleteTrashButtons = allowUsersToDeleteEmail ? [deleteTrashButton] : []
			let printButtons = showRightButton && allowUsersToPrintEmail ? [printButton] : []
			
			if (showRightButton) {
				switch (activeBoxType) {
					case 'INBOX':
						tabs = [tagsButton,...deleteButtons,...printButtons, readButton];
						break;
					case 'SENT':
						tabs = [tagsButton,...deleteButtons,...printButtons];
						break;
					case 'DRAFT':
						tabs = [...deleteButtons,...printButtons];
						break;
					case 'TRASH':
						tabs = [...deleteTrashButtons,...printButtons,restoreButton];
						break;
				};
			};
			switch (pageName) {
				case 'INBOX':

					if( $scope.showComposeButton == true ) {
						tabs.push(composeButton);
					}

					$scope.middleBarObject.title = defaultTitle;
					$scope.middleBarObject.showBack = false;                          
					break;
				case 'COMPOSE_MESSAGE':
					tabs = [];
					let renderedTitle = '';
					if(params && params.type) {
						switch (params.type) {
						case 'EDIT': 
							renderedTitle = localizedMessages.get("EDIT_MESSAGE");
							break;
						case 'REPLY': 
						case 'REPLY_ALL': 
							renderedTitle = localizedMessages.get("REPLY_TO_MESSAGE");
							break;
						case 'FORWARD': 
							renderedTitle = localizedMessages.get("FORWARD_MESSAGE");
								break;
							case 'NEW':
							default:
								renderedTitle = localizedMessages.get("COMPOSE_NEW_MESSAGE");
								break;
						};
					};
					$scope.middleBarObject.title = defaultTitle + '   |   ' + renderedTitle;
					$scope.middleBarObject.showBack = true;
					break;
				default:
					tabs = [];
					$scope.middleBarObject.title = defaultTitle;
					$scope.middleBarObject.showBack = true;
					break;
			};
			
			$scope.tabs = [{
				title: localizedMessages.get("EMAIL"),
				content: "email",
				rightMenuList: tabs,
				order: 0
			}];

			$scope.emailMobileMenu = tabs.map(function(tab) {
			    return {name: tab.title, click: tab.click, value: tab.title}
			});
		};
		
	    $scope.$parent.currentState.value = $state.$current.name;
		var mainUrl = window.location.href
        var plinkUrl = "plink";
		var emailUrl = "email";	
		if ((!(mainUrl.includes(plinkUrl)) && $scope.$parent.currentState.value == "home.email") || (mainUrl.includes(plinkUrl) && mainUrl.includes(emailUrl))) {
			var homesidebar = angular.element(document.querySelector('.nasdaq .bvhome [home-sidebar]'));
			if (homesidebar) {
				if (homeService.homeContext.expandedMenu = true) {
					homeService.homeContext.expandedMenu = false;
				}
			}
		}

		$scope.reactInterface = reactInterface;
		$scope.controllerInterface = {
			bindInboxOpen: function(fn) {
				inboxOpenIntent = fn;
			},
			bindComposeOpen: function(fn) {
				composeOpenIntent = fn;
			},
			bindTagsOpen: function(fn) {
				tagMenuIntent = fn;
			},
			bindDelete: function(fn) {
				deleteIntent = fn;
			},
			bindTrashDelete: function(fn) {
				deleteTrashIntent = fn;
			},
			bindPrintAll: function(fn) {
				printAll = fn;
			},
			bindRead: function(fn) {
				readIntent = fn;
			},
			bindRestore: function(fn) {
				restoreIntent = fn;
			},
			bindAccessControl: function(fn) {
				$scope.accessControl(fn);
			}
		};
	}
];
