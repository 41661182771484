adSearchDialogController.$inject=['$scope', '$rootScope', '$element', 'adSearchService', 'localizedMessages', '$state', 'adSearchQueryBoxService'];

function adSearchDialogController($scope, $rootScope, $element, adSearchService, localizedMessages, $state, adSearchQueryBoxService){

    var bvModal = $scope.bvModalControllerInputs.bvModal;
    var searchString = $scope.bvModalControllerInputs.searchString;
    var element = $scope.bvModalControllerInputs.element;
    $scope.searchString = adSearchQueryBoxService.searchString;
    $scope.searchObject = adSearchService.getDefaultInstance();
    $scope.searchObject.setLimit(10);
    adSearchService.getSearchResult($scope.searchObject, searchString);

    $scope.title = {
        of:                     localizedMessages.get('OF'),
        results:                localizedMessages.get('RESULTS'),
        inAllFilesFolders:      localizedMessages.get('IN_ALL_FILES_FOLDERS'),
        searchCurrentFolder:    localizedMessages.get('SEARCH_CURRENT_FOLDER'),
        advancedSearch:         localizedMessages.get('ADVANCED_SEARCH'),
        first10Results:         localizedMessages.get('FIRST_10_RESULTS')
    }

    $scope.advancedSearchClick = function(){

        var searchString = adSearchQueryBoxService.searchString.val;

        var adSearch = adSearchService.createInstance('ADVANCED').searchObject;
        adSearch.filterPanel.enable = true;

        if(!_.isEmpty(adSearch.filterPanelInstance)){
            adSearch.filterPanelInstance.resetRange();
            adSearch.filterPanelInstance.resetTeamspaceSelection();
            adSearch.filterPanelInstance.resetDocumentsDetails();

            adSearch.filterPanelInstance.addKeyword(searchString);
            adSearch.filterPanelInstance.advancedSearchData.offset = 0;
        }
        //adSearch.setItems($scope.searchObject.getItems());
        adSearchService.getAdvancedSearchResult(adSearch, searchString);

        bvModal.close();

        if($state.current.name == 'home.adsearch')
            $state.go('home.adsearch', null, { reload: true });
        else
            $state.go('home.adsearch');
    }
    $scope.$on("$destroy", function() {
        console.log('controller clean up here')
        $rootScope.showSearch=false;
        document.body.classList.remove("adv-search");
    });

}