userPanelController.$inject = ['$scope', '$rootScope', 'localizedMessages', 'context', '$modal', 'teamService', 'constants', 'bvDialog', '$timeout', 'homeModel', 'commonService', 'loadContext', 'homeService']

function userPanelController($scope, $rootScope, localizedMessages, context, modal, teamService, constants, bvDialog, $timeout, homeModel, commonService, loadContext, homeService){
    var registerScope = null;

    this.$onInit = function () {
        registerScope = $rootScope.$on('primaryEmailUpdated', function(event, newValue) {
            teamService.getUserDataBybaseUuid($scope.selectedUser.objectBaseUuid).then(function(data){
                $scope.selectedUser.email = data.email;
                $scope.profile.email = data.email;
                if(!(data.email.toUpperCase() === newValue.data.toUpperCase())) {
                    bvDialog.showMessage(localizedMessages.get('SAVING_NEW_EMAIL_FAILED'), null, true);
                } else {
                    bvDialog.showMessage(localizedMessages.get('SAVING_NEW_EMAIL_SUCCESS'), null, true);
                }
            });
        });
    }

    this.$onDestroy = function () {
        registerScope();
    }
    var bvModal = $scope.bvModalControllerInputs.bvModal;
    
    $scope.isN3aUser = context.getModel().isN3aUser;
    $scope.selectedUser = $scope.bvModalControllerInputs.selectedUser;
    $scope.profileEditButton=false;
	$scope.isOnPrem = context.getModel().isOnPrem;

    $scope.popupTitle 	= localizedMessages.get('PROPERTIES') + ": " + $scope.selectedUser.name;
    $scope.actionBtnTxt = localizedMessages.get('SAVE');
    $scope.closeBtnTxt 	= localizedMessages.get('CANCEL');
    $scope.editBtnTxt 	= localizedMessages.get('EDIT');
    $scope.signatureLbl = localizedMessages.get("SIGNATURE");
    $scope.editProfileLbl = localizedMessages.get("EDIT_PROFILE");
    $scope.profileFieldsLabel = {
        licenseType: localizedMessages.get('LICENSE_TYPE'),
        firstName: localizedMessages.get('FIRST_NAME'),
        lastName: localizedMessages.get('LAST_NAME'),
        salutation: localizedMessages.get('SALUTATION'),
        countryCode: localizedMessages.get('COUNTRY_CODE'),
        country: localizedMessages.get('COUNTRY'),
        workPhone: localizedMessages.get('WORK_PHONE'),
        mobilePhone: localizedMessages.get('MOBILE_PHONE'),
        mobileEnableSms: localizedMessages.get('MOBILE_ENABLE_SMS'),
        homePhone: localizedMessages.get('HOME_PHONE'),
        primaryEmail: localizedMessages.get('PRIMARY_EMAIL'),
        bio: localizedMessages.get('BIO'),
        secondaryEmails: localizedMessages.get("SECONDARY_EMAILS"),
        jobTitle: localizedMessages.get('JOB_TITLE'),
        company: localizedMessages.get('COMPANY'),
        loginID: localizedMessages.get('LOGIN_ID'),
        editPhoto: localizedMessages.get('EDIT_PHOTO'),
        language: localizedMessages.get('LANGUAGE'),
        roles:localizedMessages.get('ROLES'),
        committee:localizedMessages.get('COMMITTEE'),
        dateFormaInfo:localizedMessages.get('DATE_FORMAT_INFO_FOR_MEETINGS_AND_APPROVALS_MODULE')
    };

    $scope.profileFieldsLeft = [
        {displayField: localizedMessages.get('SALUTATION'), valueField: 'title', type: 'text'},
        {displayField: localizedMessages.get('COUNTRY'), valueField: 'mobilePhoneCountryName', type: 'text'},
        {displayField: localizedMessages.get('COMPANY'), valueField: 'companyName', type: 'text'},
        {displayField: localizedMessages.get('JOB_TITLE'), valueField: 'jobTitle', type: 'text'},
        {displayField: localizedMessages.get('LANGUAGE'), valueField: 'localeType', type: 'text'},
        {displayField: localizedMessages.get('DATE_FORMAT'), valueField: 'dateFormatOption', type: 'text'}
    ];

    $scope.profileFieldsRight = [
        {displayField: localizedMessages.get('COUNTRY_CODE'), valueField: 'mobilePhoneCountryCode'},
        {displayField: localizedMessages.get('WORK_PHONE'), valueField: 'workPhone'},
        {displayField: localizedMessages.get('MOBILE_PHONE'), valueField: 'mobilePhone'},
        {displayField: localizedMessages.get('PRIMARY_EMAIL'), valueField: 'email', type: 'email'},
        {displayField: localizedMessages.get('SECONDARY_EMAILS'), valueField: 'additionalEmailAddresses', type: 'email'},
        {displayField: localizedMessages.get('TIME_FORMAT'), valueField: 'timeFormatOption', type: 'text'}
    ]

    $scope.newCustomFieldName = localizedMessages.get('TYPE_CUSTOM_FIELD_NAME');
    $scope.useComma = localizedMessages.get('USE_COMMA_SEPARATE_EMAILS');
    $scope.addCustom = localizedMessages.get('ADD_CUSTOM');
    $scope.requiredFieldsLabel = localizedMessages.get('INDICATES_REQUIRED_FIELD');

    $scope.licenseTypes = [];
	$scope.onlyNumbers = /^\d+$/;
	$scope.mobileNumberPattern = /^\d{10}$/;
	$scope.firstNameMaxLength = 250;
	$scope.lastNameMaxLength = 250;
	$scope.loginIDMaxLength = 50;
	$scope.phoneMaxLength = 16;

	$scope.emailRegEx = constants.get('EMAIL_REGEX');
	$scope.phoneRegEx = constants.get('PHONE_REGEX_N3A');
	$scope.nameRegEx = constants.get('NAME_REGEX');
	$scope.commonValueRegEx = constants.get('COMMON_VALUE_REGEX');
	$scope.commaValueRegEx = constants.get('COMMA_VALUE_REGEX');
	$scope.specialCharacterValueRegEx = constants.get('SPECIAL_CHARACTERS_VALUE_REGEX');
	$scope.arrowsValueRegEx = constants.get('<>_VALUE_REGEX');

	$scope.SHOW_EDIT_PHOTO = true;
	if(MobileUtil.isApp())
	{
		$scope.SHOW_EDIT_PHOTO = false;
	}

    var dateSettings = [];
    var timeSettings = [];

    // MEETX-49055
    teamService.getDateAndTimeOptions().then(function(data){
        dateSettings = data.dates;
        timeSettings = data.times;

        $scope.dateFormatOptions = dateSettings;
	    $scope.timeFormatOptions = timeSettings;

        var dateFmt = dateSettings.find((t) => {
            return t.value === $scope.selectedUser.dateFormat;
        });

        var timeFmt = timeSettings.find((t) => {
            return t.value === $scope.selectedUser.timeFormat;
        });

        // MEETX-49055
        $scope.dateFormatOption = dateFmt;
        $scope.timeFormatOption = timeFmt;
    });

	$scope.languages = context.getModel().localeList;

	$scope.countries = {};
	$scope.enableEditEmailWizard = context.getEnabledFeatures().enableEmailValidation?context.getEnabledFeatures().enableEmailValidation:false;

    $scope.addPlaceHolderText = function(label) {
        $("div[name='editProfileRoles'] .select2-search-field .select2-input").attr("placeholder",label);
        return true;
    };

    $scope.addPlaceHolderTextCommittee = function(label) {
        $("div[name='editProfileCommittees'] .select2-search-field .select2-input").attr("placeholder",label);
        return true;
    };


    $scope.profileRoles = {
        enabled: context.getEnabledFeatures().enableProfileRoles?context.getEnabledFeatures().enableProfileRoles:false,
        label:localizedMessages.get('SELECT_ROLES'),
        selected:($scope.selectedUser!=undefined
            && $scope.selectedUser.profileRole!=undefined
            && $scope.selectedUser.profileRole.trim().length>0)?$scope.selectedUser.profileRole.split('#'):"",
        values:["Admin", "Board Liaison", "Board Support", "CEO", "Chair of the Board",
            "Corporate Director", "Corporate Secretary",
            "Executive/Senior Management","General Counsel",
            "Independent Director/Non-Executive Director", "Internal Audit", "Other (external user)", "Other (Internal Employee)", "Personal Assistant", "Technology",
            "Trustee"]
    };

    $scope.profileCommittees = {
        enabled: context.getEnabledFeatures().enableProfileCommittees?context.getEnabledFeatures().enableProfileCommittees:false,
        label:localizedMessages.get('SELECT_COMMITTEES'),
        selected:($scope.selectedUser!=undefined
            && $scope.selectedUser.profileCommittee!=undefined
            && $scope.selectedUser.profileCommittee.trim().length>0)?$scope.selectedUser.profileCommittee.split('#'):"",
        values:["Audit Committee", "Risk Committee", "Governance and Nominating Committee", "Compensation Committee"]
    };


    var currentUserVO = context.getModel().userVO;
    $scope.currentUserBaseUuid = currentUserVO.objectBaseUuid;
    $scope.bioLbl = localizedMessages.get("BIO");

    $scope.selectedUser.edited = false;
    $scope.showEditBtns = true;
    
    if((MobileUtil.isApp() && MobileUtil.isJupOffline()) || !context.getModel().showProfileEditBtn)
    {
        $scope.showEditBtns = false;
    }

    $scope.editSignature = function()
    {
        var editSignatureModal = new modal.open({
            windowClass : 	'editSignatureDialog',
            templateUrl :  	'modules/team/CreateSignature.tpl.html',
            scope :      	$scope,
            controller : 	'CreateSignatureController',
            backdrop: 'static',
            keyboard: false
        });
    }

//    $scope.editProfile = function()
//    {
//        $scope.enableEdit = !$scope.enableEdit;
//
//        var editProfileModal = new modal.open({
//            windowClass : 	'editProfileDialog',
//            templateUrl :  	'modules/team/EditProfile.tpl.html',
//            scope :      	$scope,
//            controller : 	'EditProfileController',
//            backdrop :      'static',
//            keyboard: false
//        });
//
//        editProfileModal.result.then(function(data)
//        {
//            teamService.getUserDataBybaseUuid($scope.selectedUser.objectBaseUuid).
//            then(function(data)
//            {
//                $scope.selectedUser = data;
//                $scope.languages = context.getModel().localeList;
//                for(i in $scope.languages)
//                {
//                    if($scope.selectedUser.localeType == $scope.languages[i].value)
//                    {
//                        $scope.selectedUser.localeType = $scope.languages[i].name;
//                    }
//                }
//            })
//        });
//    }

    var homeController = angular.element(document.querySelector('[home-controler]')).scope();
    $scope.getUserThumbnail = homeController.getUserThumbnail;
    $scope.userImages =  homeController.userImages;

    $scope.followUnfollow = $scope.bvModalControllerInputs.followUnFollow;
    $scope.showFollowButton = true;
    if($scope.selectedUser.objectBaseUuid === currentUserVO.objectBaseUuid){
        $scope.showFollowButton=false;
    }
    $scope.followUnfollowClick = function(){
        console.log($scope.selectedUser);
        if($scope.followUnfollow){
            teamService.unSubscribeUser($scope.selectedUser.objectBaseUuid).
            then(function(data){
                $scope.followUnfollow = !$scope.followUnfollow;
            });
        }else{
            teamService.subscribeUser($scope.selectedUser.objectBaseUuid).
            then(function(data){
                $scope.followUnfollow = !$scope.followUnfollow;
            });
        }
    }

    $scope.tabs = [
        {
            title : localizedMessages.get("DETAILS"),
            content : ''
        },
        {
            title : localizedMessages.get("GROUPS"),
            content : ''
        },
        {
            title : localizedMessages.get("TEAM_SPACE"),
            content : ''
        }
    ]

    $scope.userTopPanel = {
       templateUrl: 'modules/dialogs/userpanel/toppanel/toppanel.template.html',
       events : [
//           { name: 'name',         value : localizedMessages.get('NAME_UPPERCASE')},
//           { name: 'due',          value : localizedMessages.get('DUE_UPPERCASE')},
//           { name: 'status',       value : localizedMessages.get('STATUS_UPPERCASE')},
//           { name: 'nameClk',      value : function(){ $scope.selectMenuItem({ value: "name" }) }},
//           { name: 'dueClk',       value : function(){ $scope.selectMenuItem({ value: "dueDateLong" }) }},
//           { name: 'statusClk',    value : function(){ $scope.selectMenuItem({ value: "progress" })}},
           { name: 'tabs',         value : $scope.tabs}
       ]
   }

    $scope.editSave = function(){
        if($scope.selectedUser.edited){
        	$scope.profileEditButton=true;
            var success = $scope.saveProfile();
            if(success !=undefined) {
                $scope.profileEditButton=success;
            }
        }
        else{
            $scope.profile = angular.copy($scope.selectedUser);
        	$scope.profile.customFields = angular.copy($scope.selectedUser.customFields);
        	_.each($scope.languages, function(lang){
                if($scope.profile.localeType == lang.name)
                {
                    $scope.profile.localeType = lang;
                }
            });

            if($scope.profile.bio == null || $scope.profile.bio == undefined || $scope.profile.bio == 'null'){
                $scope.profile.bio = '';
            }

            _.each($scope.countries, function(country){
                if (country.name == $scope.profile.mobilePhoneCountryName) {
                    $scope.profile.mobilePhoneCountryName = country;
                }
            });

            $scope.croppedImage = $scope.getUserThumbnail($scope.profile);

            $scope.selectedUser.edited = true;
        }
    }

    $scope.bioEditorOptions = {
       language: 'en',
       height: '191px',
       resize_enabled: false,
       toolbarLocation: 'bottom',
       toolbarCanCollapse:false,
       toolbarStartupExpanded:true,
       removePlugins: 'elementspath',
       toolbar : [
                      { name: 'basicstyles', 	items: [ 'Bold', 'Italic', 'Underline' ] },
                  ]
    };

    $scope.validateEmails = function(emails) {
        var delimiter = ',';
        var filter  = constants.get("EMAIL_REGEX");
        var error = false;
        var aEmails = emails.split(delimiter);

        for(var index = 0; index < aEmails.length; index++) {
            aEmails[index] = (aEmails[index].replace(/^\s+/,'')).replace(/\s+$/,'');
            if(aEmails[index] != '' && aEmails[index].search(filter) == -1)
                error = true;
        }
        if(error == true ) {
            $scope.frmEditProfile.form.additionalEmails.$setValidity("emails",false);
        } else {
            $scope.frmEditProfile.form.additionalEmails.$setValidity("emails",true);
        }
    }

    $scope.frmEditProfile = {}

    $scope.saveProfile = function() {
        if($scope.frmEditProfile.form.$error.required) {
            bvDialog.showMessage(localizedMessages.get('COMPLETE_REQUIRED_FILEDS'));
            // ngDialog.open({
            // 	template: '<div style="text-align:center">'+ localizedMessages.get('COMPLETE_REQUIRED_FILEDS') + '</div>',
            // 	plain: true
            // });
            
            if($scope.profile.lastName==undefined || $scope.profile.lastName.trim().length === 0){
            	document.getElementById("editProfileLastName").focus();
            }
            
            if($scope.profile.firstName==undefined || $scope.profile.firstName.trim().length === 0){
              	document.getElementById("editProfileFirstName").focus();
            }

            for(var i in $scope.newCustomFields) {
                if($scope.newCustomFields[i].name == "") {
                	document.getElementById("newCustomFieldName"+i).focus();              
                }
            }
            
            return false;
        }

        for(var i in $scope.newCustomFields) {
            if($scope.newCustomFields[i].name == "" && $scope.newCustomFields[i].value != "") {
                bvDialog.showMessage(localizedMessages.get('COMPLETE_REQUIRED_FILEDS'));
                return false;
            }
        }

        if(!$scope.frmEditProfile.form.$valid) {
        	var validationMessages = [];
        	if($scope.frmEditProfile.form.editProfileFirstName.$error.pattern){
            	validationMessages.push( localizedMessages.get("INVALID_FIRST_NAME"));
            }
            if($scope.frmEditProfile.form.editProfileLastName.$error.pattern){
            	validationMessages.push( localizedMessages.get("INVALID_LAST_NAME"));
            }
            if(!($scope.profile.workPhone == '') && $scope.frmEditProfile.form.editProfileWorkPhone.$error.pattern){
            	validationMessages.push( localizedMessages.get("INVALID_WORK_NUMBER"));
            }
            if($scope.frmEditProfile.form.editProfileCompanyName.$error.pattern){
            	validationMessages.push( localizedMessages.get("INVALID_COMPANY_NAME"));
            }
            if(!($scope.profile.mobilePhone == '') && $scope.frmEditProfile.form.editProfileMobilePhone.$error.pattern){
            	validationMessages.push( localizedMessages.get("INVALID_MOBILE_NUMBER") );
            }
            if(($scope.frmEditProfile.form.editProfileEmail!= undefined) && ($scope.frmEditProfile.form.editProfileEmail.$error.pattern)){
            	validationMessages.push( localizedMessages.get("INVALID_EMAIL"));
            }
            if($scope.frmEditProfile.form.editProfileJobTitle.$error.pattern){
            	validationMessages.push( localizedMessages.get("INVALID_JOB_TITLE"));
            }
            if(!$scope.frmEditProfile.form.additionalEmails.$valid){
            	validationMessages.push( localizedMessages.get("INVALID_ADDITIONAL_EMAILS"));
            }
            
            if (validationMessages.length <= 0) {
                var filter = [];
                if($scope.frmEditProfile.form.$error.pattern && $scope.frmEditProfile.form.$error.pattern.length > 0){
                    filter = _.filter($scope.frmEditProfile.form.$error.pattern, function(i){
                        return (i.$viewValue.indexOf('<') != -1 || i.$viewValue.indexOf('>') != -1)
                    });
                }

                if(filter.length > 0){
                    validationMessages.push(localizedMessages.get('MSG_THE_CHARACTERS_ARE_NOT_SUPPORTED'));
                }
                else{
                    validationMessages.push(localizedMessages.get('INVALID_DATA_FOUND'));
                }
            }
            if(validationMessages){
            	bvDialog.showMessage(validationMessages.join(" <br> "),null,null,null,true);
            }
            // ngDialog.open({
            // 	template: '<div style="text-align:center">'+ localizedMessages.get('INVALID_DATA_FOUND') + '</div>',
            // 	plain: true
            // });
            return false;
        }

        var editedProfile = angular.copy($scope.profile);

        if(editedProfile.mobilePhoneCountryName != undefined)
        {
            editedProfile.countryIso3=editedProfile.mobilePhoneCountryName.objectBaseUuid;
            editedProfile.mobilePhoneCountryName = editedProfile.mobilePhoneCountryName.name;
        }

        if($scope.profileRoles.selected!=undefined && $scope.profileRoles.selected.length>0) {
            editedProfile.profileRole=$scope.profileRoles.selected.join('#');
        } else if($scope.profileRoles.enabled) {
            bvDialog.showMessage(localizedMessages.get('SELECT_ATLEAST_ONE_ROLE'));
        	document.querySelector("div[name='editProfileRoles'] .select2-search-field .select2-input").focus();
            
            return false;
        }

        if($scope.profileCommittees.selected!=undefined && $scope.profileCommittees.selected.length>0) {
            editedProfile.profileCommittee=$scope.profileCommittees.selected.join('#');
        } else {// committee is not a mandatory field, it can be empty also
            editedProfile.profileCommittee = "";
        }


        // Below fields might have & which needs to be encoded otherwise JSON parsing fails on server.
        var bio = '';
        if(editedProfile.bio != undefined && editedProfile.bio != null) {
            bio = editedProfile.bio;
        }
        editedProfile.bio = '';
        editedProfile.localeType = editedProfile.localeType.value;
        editedProfile.customFields = editedProfile.customFields || [];

        for(var i in $scope.newCustomFields) {
            if($scope.newCustomFields[i].name != "") {
                editedProfile.customFields.push($scope.newCustomFields[i]);
            }
        }

        $scope.saveProfilePhoto();
        editedProfile.userProfileImage = $scope.croppedImageValue;

        if ($scope.dateFormatOption && Object.keys($scope.dateFormatOption).length != 0) {
            editedProfile.dateFormat = $scope.dateFormatOption.value;
        }

        if ($scope.timeFormatOption && Object.keys($scope.timeFormatOption).length != 0) {
            editedProfile.timeFormat = $scope.timeFormatOption.value;
        }
        
        var jsonString = angular.toJson(editedProfile);
        teamService.editProfile(jsonString , bio).then(function(data) {
            console.log(data);
            if(data == true ) {
                bvDialog.showMessage(localizedMessages.get('PROFILE_CHANGES_SAVED'), null, true);


                teamService.getUserDataBybaseUuid($scope.selectedUser.objectBaseUuid).then(function(data){
                    $scope.selectedUser = data;
                    $scope.profileRoles.selected = ($scope.selectedUser!=undefined
                      && $scope.selectedUser.profileRole!=undefined
                        && $scope.selectedUser.profileRole.trim().length>0)?$scope.selectedUser.profileRole.split('#'):"";
                    // As localetype returned from api may not be correct if any delay in N3A's callback
                    // during profile update, we are not using that value from API response
                    _.each($scope.languages, function(lang){
                        if(editedProfile.localeType == lang.value)
                        {
                            $scope.selectedUser.localeType = lang.name;
                        }
                    });
                    $scope.profileCommittees.selected = ($scope.selectedUser!=undefined
                      && $scope.selectedUser.profileCommittee!=undefined
                        && $scope.selectedUser.profileCommittee.trim().length>0)?$scope.selectedUser.profileCommittee.split('#'):"";


                    $scope.selectedUser.edited = false;
                    $scope.newCustomFields = [];
                });

                // Make sure to reload the context model userVO in order to get the current profile update.
                // This is going to make the reactInterface.getCurrentUser() always up to date.
                homeService.getPortalData().then(function(data){
                    context.getModel().userVO = data.userVO;
                });

                // ngDialog.open({
                // 	template: '<div style="text-align:center">'+ localizedMessages.get('PROFILE_CHANGES_SAVED') + '</div>',
                // 	plain: true
                // });
//                $modalInstance.close('save');
            } else {
                bvDialog.showMessage(localizedMessages.get('PROFILE_CHANGES_SAVE_FAILED'));
                // ngDialog.open({
                // 	template: '<div style="text-align:center"> '+ localizedMessages.get('PROFILE_CHANGES_SAVE_FAILED') + '</div>',
                // 	plain: true
                // });
            }
            $scope.profileEditButton=false;
        })
    }

    teamService.getUserProfileConfigurationsJSON().then(function(data) {

        if(!StringUtils.isNullEmpty(data))
        {
            $scope.firstNameMaxLength = data.FIRST_NAME_MAX_LENGTH;
            $scope.lastNameMaxLength = data.LAST_NAME_MAX_LENGTH;
            $scope.loginIDMaxLength = data.LOGIN_ID_MAX_LENGTH;
        }
    })

    teamService.getCountryNameValueListJSON().then(function(data){
        data.splice(0,1);
        $scope.countries = data;
    });

    teamService.getLicenseTypeDetails().then(function(data) {
        $scope.licenseTypes = data;
    })

    $scope.dateFormatChanged = function(formatOption)
    {
        if(formatOption != "" || Object.keys(formatOption).length != 0) {
            console.log("dateFormatChanged", formatOption)
            $scope.dateFormatOption = formatOption;
        }
    }

    $scope.timeFormatChanged = function(formatOption)
    {
        if(formatOption != "" || Object.keys(formatOption).length != 0) {
            console.log("timeFormatChanged", formatOption)
            $scope.timeFormatOption = formatOption;
        }
    }

    $scope.countryChanged = function(country)
    {
        $scope.profile.mobilePhoneCountryName = country;
        if($scope.profile.mobilePhoneCountryName!= "") {
            $scope.profile.mobilePhoneCountryCode = "+" + $scope.profile.mobilePhoneCountryName.value;
        }
        else {
            $scope.profile.mobilePhoneCountryCode = "";
        }
    }

    $scope.cursorEnd = false;
    $scope.updateScrollbar = {};
    $scope.scrollbarConfig = {
        scrollInertia: 800,
        callbacks: {
            onScroll: function(){
                if(this.mcs.topPct == 100){
                    $scope.$apply(function(){
                        $scope.cursorEnd = true;
                    });
                }
            }
        }
    }

    $scope.newCustomFields = [];
    $scope.addNewCustomField = function() {
        $scope.newCustomFields.push({"name": "", "value": ""});

        $timeout(function() {
            $scope.cursorEnd = false;
            $scope.updateScrollbar.update('scrollTo', 10000);
        });

//        $timeout(function(){
//            var fieldsMain = angular.element(document.querySelector('.fieldsMain'));
//            fieldsMain[0].scrollTop = fieldsMain[0].scrollHeight;
//        }, 30);

    }

    $scope.removeCustomField = function(customField) {
        var i =	$scope.profile.customFields.indexOf(customField);
        $scope.profile.customFields.splice(i,1);
    };

    $scope.editEmailWizard = function() {
        var cancelAction = function () {
            bvModalThis.close();
        };
        var nextAction = function() {
            var editEmailScope = angular.element(document.querySelector(".email-wizard")).scope();
            editEmailScope.onNext(bvModalThis, $scope.selectedUser.email);
        }
        var bvModalThis = bvDialog.editEmailWizard(localizedMessages.get('CANCEL'), cancelAction, localizedMessages.get('NEXT'), nextAction);
        // bvDialog.userCreateConfirm(localizedMessages.get('CONTINUE'), localizedMessages.get('CANCEL'), $scope.continueUserInvitationEdit, $scope.cancelEdit, localizedMessages.get('CREATE_USER'));

    };

    $scope.removeNewCustomField = function(customField) {
        var i =	$scope.newCustomFields.indexOf(customField);
        $scope.newCustomFields.splice(i,1);
    };

    $scope.croppedImage = "";
    $scope.editPhoto = function() {
        var bvModal = new modal.open({
            windowClass: 'editPhotoDialog',
            templateUrl: 'modules/team/EditPhoto.tpl.html',
            scope: $scope,
            controller: 'EditPhotoController',
            backdrop: 'static'
        });

        bvModal.result.then(function(croppedImage){
            $scope.croppedImage = "data:image/png;base64," + croppedImage;
            $scope.croppedImageValue = croppedImage;
        });
    }

    $scope.saveProfilePhoto = function() {
        if($scope.croppedImageValue){
            teamService.updateUserProfileImage($scope.croppedImageValue, "medium")
            .then(function(data) {
                teamService.getUserProfileImage($scope.profile.objectBaseUuid, $scope.profile.objectVersionUuid).
                then(function(data)
                {
                    if(data != '' && data.profileImage != undefined && data.profileImage != '')
                    {
                        homeController.userImages[data.objectBaseUuid]=data.profileImage;
                    }
                })
            });
        }
    }

    $scope.status = { languagesOpen : false, dateFormatOptionsOpen: false, timeFormatOptionsOpen: false };

    $scope.toggleDropdown = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.status.languagesOpen = !$scope.status.languagesOpen;
        $scope.status.dateFormatOptionsOpen = !$scope.status.dateFormatOptionsOpen;
        $scope.status.timeFormatOptionsOpen = !$scope.status.timeFormatOptionsOpen;
    };

    var homeController = angular.element(document.querySelector('[home-controler]')).scope();
    $scope.getUserThumbnail = homeController.getUserThumbnail;
    $scope.userImages =  homeController.userImages;


    $scope.teamspaces = angular.copy(homeModel.listofPortals);

    //Groups

    $scope.tsGroups = []

    var disableUserRemoveOption = function() {
        $scope.showUserRemoveOption = false;
    }

    $scope.getAllGroupsOfPortal = function()
    {
        teamService.getAllGroupsOfUser(context.getModel().portalVO.objectBaseUuid).then(function(data){
            if(data)
                $scope.tsGroups = $scope.tsGroups.concat(data);
        });
 
    }();

    $scope.getGroupUsersForGroup = function(group, isOpen)
    {
        var groupIndex = $scope.tsGroups.indexOf(group);

        disableUserRemoveOption();
        //teamService.getGroupChildren($scope.tsGroups[groupIndex].objectBaseUuid).
        commonService.getGroupUsers($scope.tsGroups[groupIndex].objectBaseUuid).
        then(function(data)
        {
            $scope.tsGroups[groupIndex].groupUsers = [];
            if(data.data.groupUsers)
                $scope.tsGroups[groupIndex].groupUsers = data.data.groupUsers;
        })
    }

    $scope.getGroupUsers = function(objectBaseUuid)
    {
        for(var i in $scope.tsGroups)
        {
            if($scope.tsGroups[i].objectBaseUuid == objectBaseUuid)
            {
                $scope.getGroupUsersByGroupIndex(i);
                return;
            }
        }
    }

    $scope.addToGroupLbl = localizedMessages.get('ADD_TO_GROUP');
    $scope.removeFromGroupLbl = localizedMessages.get('REMOVE_FROM_GROUP');

    $scope.multiSelect = true;
    $scope.selectUsersLbl = localizedMessages.get("SELECT_USERS")

    $scope.addSelectedUserToGroup = function(selectedItems)
    {
        $scope.selectedUsers = selectedItems;
        // if(!keepOpen)
        // 	$scope.userListModal.dismiss('close');

        if($scope.selectedUsers && $scope.selectedUsers.length !=0 )
        {
            $scope.selectedUsersUuid = [];
            for(var i in $scope.selectedUsers)
            {
                $scope.selectedUsersUuid.push($scope.selectedUsers[i].objectBaseUuid);
            }

            teamService.addUserToGroup($scope.addToGroup.objectBaseUuid,JSON.stringify($scope.selectedUsersUuid)).
            then(function(data)
             {
                if(data == null || data == false)
                {
                    bvDialog.showMessage(localizedMessages.get('ADD_USER_TO_GROUP_ERROR'));
                }
                else
                {
                    // ngDialog.open({
                    // 	template: '<div style="text-align:center">' + "Users added." + '</div>',
                    // 	plain: true
                    // });
                }
                $scope.getGroupUsersForGroup($scope.addToGroup);
            })
        }
    }

    $scope.handleClickSms = function(isMobilePhoneEnableSms){
        teamService.enableSmsNotifications(isMobilePhoneEnableSms).then(function(data){
            if(data == null || data == false){
                //TODO: Add Notifications if needed error
            }
            else{
                //TODO: Add Notifications if needed success
            }
        })

    }

}