loadContext.$inject = ['ajaxService', 'context', '$rootScope', 'legacyUserService', '$state'];

function loadContext(ajaxService, context, $rootScope, legacyUserService, $state){
	var service = {
			loadContext : function() {
				if(context.isReady()) {
					if(window.console) console.log("Context is ready !!!");
					return context;
				}
				else
				{
                    // Remove the WalkMe if it has been already loaded
					if (window._walkMe !== undefined) {
                        window._walkMe.removeWalkMe()
                        console.log(">>>>>>>>> walkme - WalkMe removed");
					}
					var request = ajaxService.getPortalData();
					var encryptionKeyError = null;
					return request.then(function(data) {
						if(data !== null && data.boName !=="FailureBO")
						{	
                            if (data.companyVO != undefined && data.companyVO != null && data.userVO != undefined && data.userVO != null) {
								window.walkMeSettings = [];
								window.walkMeSettings.uuid = data.userVO.externalId;
                                window.walkMeSettings.company = data.companyVO.externalId;
								window.walkMeSettings.role = data.userVO.roleList;
								if(data.userVO.mobilePhoneCountryName != undefined && data.userVO.mobilePhoneCountryName != null){
									window.walkMeSettings.country = data.userVO.mobilePhoneCountryName;
								}
								else{
									window.walkMeSettings.country = data.defaultCountryName;
								}
								window.walkMeSettings.DD = data.companyVO.ddUuid;
								window.walkMeSettings.customerclassfield = data.companyVO.customerType;
								window.walkMeSettings.datacenter = data.companyVO.dataCenterName;
								window.walkMeSettings.onboarding = data.companyVO.onboarding;
								console.log(window.walkMeSettings);

								const walkmeEnabledForCustomer = data.companyVO.enableWalkMeTutorials;
								const walkmeVersionUrl = data.companyVO.walkmeVersionJsCDN;
								const walkmeCDNUrl = data.companyVO.walkMeSnippetCDN;
								
								console.log(">>>>>>>>> walkme - walkmeEnabledForCustomer ", walkmeEnabledForCustomer);
								console.log(">>>>>>>>> walkme - walkmeVersionCDN ", walkmeVersionUrl);
								console.log(">>>>>>>>> walkme - walkMeSnippetCDN ", walkmeCDNUrl);
								if (walkmeEnabledForCustomer) {

									// Load Walkme version
									var walkmeVer = document.createElement('script');
									walkmeVer.type = 'text/javascript';
									walkmeVer.async = true;
									walkmeVer.src = walkmeVersionUrl + Math.floor(Date.now() / 60000);
									var s = document.getElementsByTagName('script')[0];
									s.parentNode.insertBefore(walkmeVer, s);
									window._walkmeConfig = {smartLoad:true};
									walkmeVer.onload = function() {
										console.log(">>>>>>>>> walkme - WalkMeVersion added");
										// Load WalkMe script
										var walkme = document.createElement('script');
										walkme.type = 'text/javascript';
										walkme.async = true;
										walkme.src = walkmeCDNUrl.replace(/{VERSION}/g, deploymentInfo.version);
										console.log(">>>>>>>>> walkme - walkMeSnippetCDN Final ", walkme.src);
										var s = document.getElementsByTagName('script')[0];
										s.parentNode.insertBefore(walkme, s);
										window._walkmeConfig = {smartLoad:true};
										console.log(">>>>>>>>> walkme - WalkMe added");
									};
								}

								// Pendo Initialization
							   // Remove code as pendo is not applicable for NBVonPrem
								// if(window.pendo != undefined && window.pendo != null)
								// {
								// 	pendo.initialize({
								// 		visitor: {
								// 			id:data.userVO.objectBaseUuid,
								// 			email:data.userVO.objectBaseUuid,
								// 			companyshortname: data.companyVO.companyShortName,
								// 			companyid: data.companyVO.objectBaseUuid,
								// 			language: initialLocale,
								// 			admin: (data.isUserPortalAdmin && data.isUserPortalAdmin == "TRUE") ? "true" : "false",
								// 			appversion: (data.versionIdWithHC != null && data.versionIdWithHC !=undefined) ? data.versionIdWithHC.split('-')[0] : 1,
                                //          unid: (data.userVO.externalId == null) ? "000000" : data.userVO.externalId
								// 		},
								// 		account: {
								// 			id: data.companyVO.objectBaseUuid,
								// 			companyshortname: data.companyVO.companyShortName,
								// 			datacenter : data.companyVO.dataCenterName,
								// 			accounttype : (data.companyVO.customerType != undefined) ? data.companyVO.customerType : 'unknown',
								// 			name : (data.companyVO.name != undefined && data.companyVO.name != null) ? data.companyVO.name : data.companyVO.companyShortName
								// 		},
								// 		frameIdentitySync: true,
								// 		autoFrameInstall: true
								// 	});
								// }
                                // Datadog RUM initialisation
                                if(data.companyVO.enableDatadogRum && window.datdogRumInitialisation instanceof Function)
                                {
                                    window.datdogRumInitialisation(data);
                                }
							}

							MobileUtil.setUserSessionTimeout(data.userSessionTimeoutMinutes);
							
							var encryptionKeyError = null;						

							bvUsageUtils.addToGlobalUsageMap(bvUsageUtils.key.CompanyShortName, (data.companyVO ? data.companyVO.companyShortName : null));
							bvUsageUtils.addToGlobalUsageMap(bvUsageUtils.key.CompanyUUID, ((data.companyVO && data.companyVO.objectBaseUuid) ? data.companyVO.objectBaseUuid.substring(6,12) : null));
							bvUsageUtils.addToGlobalUsageMap(bvUsageUtils.key.n3aUserName, (data.userVO ? data.userVO.loginID : null));
							bvUsageUtils.addToGlobalUsageMap(bvUsageUtils.key.userActivityID, (data.activityTrackingId ? data.activityTrackingId : null));
							window.userActivityIdForHeader = (data.activityTrackingId ? data.activityTrackingId : null); //Add ActivityId to request Header, used to link EUM and APM data

							if(data.cmkNSet != undefined && data.cmkNSet == true)
							{								
								
								if(data.companyVO != undefined && data.companyVO != null)
								{
									encryptionKeyError = 'requireEncryptionKeyForAdmin';
									data.portalVO = {};									
									context.homeModel.companyBaseUuid = data.companyVO.objectBaseUuid;
									
									if(context.homeModel.serverPublicKey == undefined  && data.cryptoVO != null)
									{
										if(data.cryptoVO.publicCertificate && data.cryptoVO.publicCertificate!="")
											{
												if(data.cryptoVO.publicCertificate.indexOf("-----BEGIN CERTIFICATE-----")==-1)
												{
													data.cryptoVO.publicCertificate="-----BEGIN CERTIFICATE-----  ".concat(data.cryptoVO.publicCertificate,' -----END CERTIFICATE-----')
												}
												context.homeModel.serverPublicKey = X509.getPublicKeyFromCertPEM(data.cryptoVO.publicCertificate);								
										
											}
										}
								}
								else{
									encryptionKeyError = 'requireEncryptionKeyForUser';
									data.portalVO = {};
									data.companyVO = {};
									
									if(data.isN3aLogin){
										$rootScope.encryptionKeyError = 'requireEncryptionKeyForUser';
									}
								}
              				}
              
							context.setModel(data);
							
							legacyUserService.setNasdaqUI(data.companyVO.enableNasdaqUI);
							
							$rootScope.SELECTED_PORTAL_BASEUUID = data.portalVO.objectBaseUuid;
							
							context.setEncryptionKeyError(encryptionKeyError);
							if(window.console) console.log("Context is ready !!!");
							//Navigate only if default landing module is obtained.
							if(data.html5LandingModule != undefined && data.html5LandingModule != null ){								
								console.log('html5LandingModule: ' + data.html5LandingModule.toLowerCase().replace(" ", ""));
								var defaultLandingModule = data.html5LandingModule.toLowerCase().replace(" ", "");
								var defaultJupLandingModule = data.jupHtml5LandingModule.toLowerCase().replace(" ", "");
								$state.go('home.' + (MobileUtil.isApp() ? defaultJupLandingModule : defaultLandingModule), null, { reload: true });
							}
							
							if(data.isN3aLogin){
								$rootScope.isN3AUser = data.isN3aLogin;
							}

						    return context;
					    } else if(data !== null && data.boName ==="FailureBO" && (data.message.toLowerCase().includes("no valid session") || data.message.toLowerCase().includes("sso session not found"))){
							$rootScope.isN3AUser = true;
                            $state.go('logout.ssologout');
						} else{
							$rootScope.isN3AUser = true;
							$rootScope.errorPage= true;

							// if(pendoTrackEvent != undefined)
							// 	pendoTrackEvent("LoginError",{Message:"Redirect to Error Page"});

							$state.go('login.logouterrorpage');
						}
                    }, function(err) {
						if(err.data !== null && err.data.boName ==="FailureBO" && (err.data.message.toLowerCase().includes("no valid session") || err.data.message.toLowerCase().includes("sso session not found"))){
							$rootScope.isN3AUser = true;
							$state.go('logout.ssologout');						
						} else{
							$rootScope.isN3AUser = true;
							$rootScope.errorPage= true;

							// if(pendoTrackEvent != undefined)
							// 	pendoTrackEvent("LoginError",{Message:"Redirect to Error Page"});

							$state.go('login.logouterrorpage');
						}
						/*if(err && err.headers && typeof err.headers == 'function' && err.headers().isn3alogin && err.headers().isn3alogin === 'true'){
                            $rootScope.isN3AUser = true;
                            $state.go('login.auth', {}, { reload: true });
                        }*/
                    });
				}
			},
			setUrlRedirectStr : function(str){
				context.setUrlRedirectStr(str);
			},
			getUrlRedirectStr : function(){
				return context.getUrlRedirectStr();
			},
			getEnabledFeatures : function() {
				var request = ajaxService.getEnabledFeatures();
				return request.then(function(data) {
					if (data !== null) {
						context.setEnabledFeatures(data);
					}
				}, function(err) {
					if(window.console) console.log("500: Failed to enabled features json");
				});
			}
			
	};
	return service;
}