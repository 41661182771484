function getReleaseVersion() {
	return "20241028.075517";
}

function config($stateProvider, $locationProvider, $urlRouterProvider, $qProvider){

    $qProvider.errorOnUnhandledRejections(false);
    $locationProvider.hashPrefix('');

	if(isIgnoreBrowserLogin())
	{
		$urlRouterProvider.otherwise('/home');
		localStorage.removeItem("IGNORE_BROWSER_LOGIN");
	} else
	{
		$urlRouterProvider.otherwise('/home');
	}

	var setMobileClass = function($rootScope){
		if (MobileUtil.isAndroid() || MobileUtil.isIPhone() || MobileUtil.isSurface() || MobileUtil.isIPad()) {
			$rootScope.isMobile = true;
		}
		else{
			$rootScope.isMobile = false;
		}

		$rootScope.isPhone = MobileUtil.isPhone();
	};

    var ssoLogout = function($rootScope, $state){    	    		
        if($rootScope.isN3AUser)
            $state.go('ssologout');
        else
            $state.go('logout');
    };

	$stateProvider

		.state('login', {
			resolve : {
				setMobileClass : setMobileClass,
				checkVersion: function(loginService, $state){
					if(!MobileUtil.isMobileClient())
					{
						loginService.validateBrowserTypeAndVersionNumber().then(function(data){
							if(data.exceptionName && data.message)
							{
								loginService.loginContext().browserTypeAndVersionName = data.message;

								$state.go("notsupportedbrowsertypeorversion");
								return;
							}
							else
								loginService.loginContext({ browserTypeAndVersionName : data});
						});
					}
				}
			},
			templateUrl : 'modules/login/htmlStructure/Container.tpl.html'
		})
		.state('rhredirect', {
			url : '^/rhredirect/:resourceid',
			resolve: {
				loggedIn : function($stateParams, $state, homeModel, plinkHandler, context, $timeout) {
					homeModel.resourceId = $stateParams.resourceid;
					if(!StringUtils.isNullEmpty(homeModel.resourceId) && context.isReady()){
	                    plinkHandler.checkForResourcelinkHandler("");
	                }
	                $timeout(() => {
	                    $state.go('home');
	                });
				}
		}
		})
		.state('plink', {
            url : '^/plink/:plink',
            resolve: {
                loggedIn : function($stateParams, $state, homeModel, plinkHandler, context, $timeout) {
                    homeModel.plink = $stateParams.plink;
                    if(!StringUtils.isNullEmpty(homeModel.plink) && MobileUtil.isSurface() && context.isReady()){
                        plinkHandler.checkForPermalinkHandler("");
                    }
                    $timeout(() => {
                        $state.go('home');
                    });
                }
            }
        })
		.state('reg', {
			resolve : { setMobileClass : setMobileClass },
			templateUrl : 'modules/login/htmlStructure/Container.tpl.html'
		})
		.state('reg.form', {
			url : '/registration/plink/:plink',
			resolve : { setMobileClass : setMobileClass },
			views: {
			      'content': {
			    	  templateUrl: 'modules/registration/UserRegistration.tpl.html',
			    	  controller : 'UserRegistrationController'
			      }
			}
		})
		.state('home', {
			url : '/home',
			controller  : 'HomeController',
			params: { callback: null },
			templateUrl : 'modules/home/htmlStructure/Container.tpl.html',
			resolve: {
				// TODO: Refactor later, need to prevent logout
				setMobileClass : setMobileClass,
				isApp : function(homeService) {
					homeService.ipadDetection();
					//in case of mobile devices, the redirection is not required.
					return MobileUtil.isApp();
				},
				loggedIn : function($q, $state, $stateParams, $timeout, loginService, loadContext, homeInitialize, $location, isApp) {
				    $state.defaultErrorHandler(function() {});

					var deferred = $q.defer();
					 $timeout(function() {
						loginService.clearLoginContext();
						var ctx = loadContext.loadContext();
					 	if(ctx && angular.isFunction(ctx.then))
					 	{
					 		ctx.then(function(context)
			 				{
								 if(!context || !context.isReady())
								 {
									 $state.go('login.auth');
									 deferred.reject();
								 } else {
									 //Redirection Logic
									 if(!loadContext.getUrlRedirectStr() && !isApp) {
									 	var redirectUrl = context.getModel().redirectUrl;
									 	var urlRedirect = null;
									 	if(redirectUrl && redirectUrl!=null){
									 		loadContext.setUrlRedirectStr(redirectUrl);
									 		if(redirectUrl=='/portlet'){
									 			urlRedirect = 'redirect';
									 		}
									 	}
									} else {
										urlRedirect = null;
									}


									 if(urlRedirect && angular.isString(urlRedirect))
									 {
										 $state.go(urlRedirect);
										 deferred.reject();
									 } else if(context.getEncryptionKeyError() == 'requireEncryptionKeyForAdmin'){
										 $state.go('login.submitcompanyencryptionkey');
										 deferred.reject();
									 }
									 else if(context.getEncryptionKeyError() == 'requireEncryptionKeyForUser'){
										 $state.go('logout');
										 deferred.reject();
									 }
									 else{
										 deferred.resolve(context);
										 homeInitialize.initializingStates();
										 var defaultLandingModule = context.getModel().html5LandingModule.toLowerCase().replace(" ", "");
										 var defaultJupLandingModule = context.getModel().jupHtml5LandingModule.toLowerCase().replace(" ", "");
										 if($stateParams.callback && typeof $stateParams.callback == 'function'){
											 $stateParams.callback();
										 }
										 else{
										 	if(window.location.hash.contains("oauth2/zoom/callback/?code")){
										 		var grantCode = window.location.hash.split('?code=')[1];
												$state.go('home.virtualMeetingConfiguration', {
													zoomAuthCode: grantCode
												});
												console.log("virtualMeetingConfiguration state triggred");
											}else if(window.location.hash.contains("oauth2/teams/redirect")) {
												console.log("filtering teams redirect");
												$state.go('home.virtualMeetingConfiguration', {

												});
											}else{
												$state.go('home.' + (MobileUtil.isApp() ? defaultJupLandingModule : defaultLandingModule), null, { reload: true });
												console.log("default state triggred");
											}
										 }
									 }
								 }
			 				});
					 	} else
					 	{
						 deferred.resolve(ctx);
					 	}
					 });
					return deferred.promise;
				}
			}
		})
		.state('resourceid', {
			url : '/resourceid',
			controller  : 'ResourceController',
			resolve: {
				setMobileClass : setMobileClass,
				context : function(loadContext,homeService) {
					homeService.ipadDetection();
					return loadContext.loadContext();
				}
			}
		})
		.state('logout', {
			url : '/logout',
			resolve : {
			    ssoLogout : ssoLogout,
			    setMobileClass : setMobileClass
            },
			templateUrl : 'modules/login/Logout.tpl.html',
			controller  : 'LogoutController'
		})
		.state('ssologout', {
			url : '/ssologout',
			resolve : {
			    ssoLogout : ssoLogout,
			    setMobileClass : setMobileClass
            },
			templateUrl : 'modules/login/ssoLogout.tpl.html',
			controller  : function($timeout, bvDialogService, localizedMessages, $rootScope){				
				
				var delayTime = 500;
				
				if($rootScope.encryptionKeyError == 'requireEncryptionKeyForUser')
				{
					delayTime = 3000;
					bvDialogService.showBvAlertMessage(localizedMessages.get('ERR_ENCR_UNABLE_TO_LOGIN_CONTACT_ADMINISTRATOR'));
				}
					
				$timeout(function(){
					document.location.href = '/ssoauth/ssologout';
					$rootScope.spinerLoader=false;
		    }, delayTime);
				
				
		    }
		})
		.state('nosession', {
			url : '/logout',
			resolve : {
			    ssoLogout : ssoLogout,
			    setMobileClass : setMobileClass
            },
			templateUrl : 'modules/login/Logout.tpl.html',
			controller  : 'LogoutController',
			data: {
				invalidate : false
			}
		})
		.state('redirect', {
			url : '/redirect',
			templateUrl : 'modules/login/Redirect.tpl.html',
			controller  : 'RedirectController',
			data: {
				redirectUrl : '/portlet'
				}
		})
		.state('redirect.rh', {
			url : '^/rh?resourceid',
//			params: { resourceid: null },
			templateUrl : 'modules/login/Redirect.tpl.html',
			controller  : 'RedirectToResourceIDController',
			data: {
				redirectUrl : '/portlet'
				}
		})
		.state('notcertificatesupportedbrowser', {
			url : '/notsupportedbrowser',
			resolve : { setMobileClass : setMobileClass },
			templateUrl : 'modules/login/BrowserVersionNotSupportedForCertificate.tpl.html',
			controller  : 'ErrorPageController'
		})
		.state('notcertificatesupportedfirefoxbrowser', {
			url : '/notsupportedfireoxbrowser',
			resolve : { setMobileClass : setMobileClass },
			templateUrl : 'modules/login/FirefoxVersionNotSupportedForCertificate.tpl.html',
			controller  : 'ErrorPageController'
		})
		.state('notsupportedbrowser', {
			url : '/notsupportedbrowserversion',
			resolve : { setMobileClass : setMobileClass },
			templateUrl : 'modules/login/BrowserVersionNotSupported.tpl.html',
			controller  : 'ErrorPageController'
		})
		.state('notsupportedbrowsertypeorversion', {
			url : '/notsupportedbrowsertypeorversion',
			resolve : { setMobileClass : setMobileClass },
			templateUrl : 'modules/login/BrowserTypeOrVersionNotSupported.tpl.html',
			controller  : 'ErrorPageController'
		})
		.state("internal-discussions", {
			url: "/internal/discussions",
			controller: "InternalDiscussController",
			templateUrl: "modules/internalDiscuss/InternalDiscuss.tpl.html",
			resolve: {
				objectBaseUuid: function($location) {
					return $location.search().documentId;
				},
				loggedInUser: function($location) {
					return $location.search().userId;
				},
				token: function($location) {
					return $location.search().token;
				}
			}
		});
}

function isIgnoreBrowserLogin()
{
	var ignoreBrowserLogin = localStorage.getItem("IGNORE_BROWSER_LOGIN");
	return Boolean(ignoreBrowserLogin != null && ignoreBrowserLogin != undefined && ignoreBrowserLogin == "true");
}

var localeFileUrl = '/login/ipadpublic?servicename=IPadPublicRemoteService&methodname=areYouThereJSON';
var initialLocale = "en-us";

function getLocalizationDetails(acceptLanguages){
	var localeName = null;
	var URL = "resources/localization/";
	var noCacheParam = "?" + getReleaseVersion();
	
	if(acceptLanguages)
		localeName = acceptLanguages.split(",")[0];

	//validate the localeName
	if(MobileUtil.isNullOrEmpty(localeName))
	{
	  localeName = "en-us";
	}

	//console.log("LOCAL NAME:",localeName);
	localeName = localeName.toLowerCase();

	var langCode = "";
	if(localeName.length >= 2)
	{
		langCode = localeName.substring(0,2);
	}

	console.log("LANG-CODE:",langCode);
	console.log("LOCALE-NAME:",localeName);

	switch(langCode)
	{
		case "en" : initialLocale = "en-us"; break; // English
		case "ar" : initialLocale = "ar-sa"; break; // Arabic
		case "de" : initialLocale = "de-de"; break; // German
		case "es" : initialLocale = "es-la"; break; // Spanish
		case "fr" : initialLocale = "fr-fr"; break; // French
		case "ja" : initialLocale = "ja-jp"; break; // Japanese 
		case "pt" : initialLocale = "pt-br"; break; // Portuguese 
		case "tr" : initialLocale = 'tr-tr'; break; // Turkish
		case "it" : initialLocale = 'it-it'; break;
		case "zh" :
		            if(localeName == "zh" || localeName == "zh-tw" 
			          || localeName == "zh_tw" || localeName == "zh-hk" || localeName == 'zh_hk' || localeName.indexOf('hant') != -1)
	    		        initialLocale = 'zh-hk'; // Chinese traditional
					    else if(localeName == "zh-cn" || localeName == "zh_cn" || localeName.indexOf('hans') != -1)
    					initialLocale = 'zh-cn'; // Chinese simplified
			    break;
		default   : initialLocale = "en-us";
	}

	localStorage.setItem('USER_LANG_PREF', initialLocale);

	MobileUtil.setBLocale(initialLocale);
	localeFileUrl = URL + initialLocale + ".js" + noCacheParam;

    if (initialLocale != "en-us") {
        $.getScript(URL + "datepick/" + initialLocale + ".js");
    }

	return  { localeFileUrl : localeFileUrl, initialLocale : initialLocale, localeData : null};
}

function setInitalLocale($window, $http) {
	try
	{
		var language;
		if(!MobileUtil.isApp() && !MobileUtil.isMobileClient()){
			language = document.head.querySelector("[name~=language][content]");
		}
		if(language){
			return Promise.resolve(getLocalizationDetails(language.content));
		} else {
			return $http({ method: 'POST',  url: localeFileUrl, cache:false, headers: {
				'htmlProxy': 'htmlProxy'}
				})
			.then(function(response)
			{
				var acceptLanguages = response.headers()['accept-language'];
				return getLocalizationDetails(acceptLanguages);
			});
		}
	}
	catch(err)
	{
	    console.log("ERR IN FINDING LOCALE:",err);
		return  { localeFileUrl : localeFileUrl, initialLocale : initialLocale, localeData : null};
	}
}

window.deferredBootstrapper.bootstrap({
	  element: window.document.body,
	  module: 'BvMeetXApp',
	  resolve: {
	    STARTUP_CONFIG: ['$http', '$window', function ($http, $window) {
	    	var locale = {};
	    	return setInitalLocale($window, $http).then(function(data){
	    		locale = data;
	    		return $http({ method: 'GET',  url: localeFileUrl, cache:false}).then(function(data)
	    		      {
	    				  //console.log("BVLOCALE RETURNED...");
	    				locale.localeData = data.data;
	    		          return locale;
	    		      },
	    		      function(error)
	    		      {
	    		          console.log("ERROR WHILE GETTING LOCALE FILE. ERROR:",error);
	    		          localeFileUrl = '/html/resources/localization/en-us.js';
	    		          return $http({ method: 'GET',  url: localeFileUrl, cache:false}).then(function(data)
	    		              {
	    		        	  	locale.localeData = data.data;
	    		        	  	return locale;
	    		              },
	    		              function(error)
	    		              {
	    		                  console.log("ERROR WHILE GETTING LOCALE FILE. ERROR:",error);
	    		                  return null;
	    		              }
	    		          );
	    		      });
	    	});
	    }]
	  }
	});

//Angular module for BvMeetXApp app
angular
	.module('BvMeetXApp', [
        'templates',
        'ui.router',
        'localization.service',
        'treeControl',
        'login',
        'userRegistration',
        'home',
        'middleBar',
        'context.service',
        'autofocus',
        'constants.service',
        'ngTap',
        'bvdialog.service',
        'dialogControllers.service',
        'login.service',
        'companyUser.service',
        'legacyUser',
        'ckeditor',
        'validate',
		"internalDiscuss",
		"oc.lazyLoad",

        'bvKeyEnter.directive',
        'img.directive',
        'input.directive',
		'svg.directive',
		'challengeQuestions.module'
	])
	.config(['$stateProvider', '$locationProvider', '$urlRouterProvider', '$qProvider', config])
	.run(['STARTUP_CONFIG', 'localizedMessages', '$rootScope', 'legacyUserService', function (STARTUP_CONFIG, localizedMessages, $rootScope, legacyUserService) {
		localizedMessages.setBvLocaleData(STARTUP_CONFIG);
		if(MobileUtil.isDesktopClient())
		{
			//console.log("JUP DESKTOP CLIENT");
			document.querySelector('body').classList.add('jup-desktop-client');
		}
		if(MobileUtil.isJupOffline())
		{
			//console.log("JUP DESKTOP CLIENT");
			document.querySelector('body').classList.add('jup-offline-mode');
			if(MobileUtil.isDesktopClient())
			{
				document.querySelector('body').classList.add('jup-dc-offline-mode');
			}
		}

		$rootScope.isIE = (StringUtils.getBrowser() == 'MSIE');
		$rootScope.isSurface = MobileUtil.isSurface();
		$rootScope.isDesktopClient = MobileUtil.isDesktopClient();
		$rootScope.isApp = MobileUtil.isApp();
		$rootScope.isNasdaqUI = legacyUserService.getNasdaqUI();
		$rootScope.isApple = MobileUtil.isIPhone() || MobileUtil.isIPod() || MobileUtil.isIPad();
		$rootScope.isJupOffline = MobileUtil.isJupOffline();

	}]);
