reactInterface.$inject = ['context', '$timeout', 'bvDialog', '$state', 'bvLoadingSpinner', 'companyUserService', 'repositoryService', 'surveysAlertService', 'approvalAlertService', 'localizedMessages', 'approvalsService', '$rootScope', 'conferenceService', 'presentationService', 'bvDialogService', 'bvDialogMobile', 'messagesService', 'templatesService', 'calendarService', 'viewfullagendaService','permissionService', 'homeService','pollingService','contextMenuHandler', 'homeModel','viewerContextMenu'];

function reactInterface(context, $timeout ,bvDialog, $state, bvLoadingSpinner, companyUserService, repositoryService, surveysAlertService, approvalAlertService, localizedMessages, approvalsService, $rootScope, conferenceService, presentationService, bvDialogService, bvDialogMobile, messagesService, templatesService, calendarService, viewfullagendaService, permissionService, homeService, pollingService, contextMenuHandler, homeModel ,viewerContextMenu) {
	var service = {
    triggerCustomVPageBeaconHook : function (entType, entAction, entID, entValue){
      bvUsageUtils.triggerCustomVPageBeacon(entType, entAction, entID, entValue);
    },
    addEntityMapForUsageHook : function (entType, entAction, entID, entValue){
      bvUsageUtils.addEntityMapForUsage(entType, entAction, entID, entValue);
    },
    addMapForUsageHook : function (key, value){
      bvUsageUtils.addMapForUsage(key, value);
    },
    bvUsageConstHook : bvUsageUtils.const,
    getCompanyId: function () {
      return context.getContext().getModel().companyVO.objectBaseUuid;
    },
    getAccessToken: function () {
      return context.getContext().getModel().requestCsrfToken
    },
    getSelectedMeetingOption: function () {
      return homeModel.selectedMeetingOption;
    },
    getCurrentUserId: function () {
      return context.getContext().getModel().userVO.objectBaseUuid
    },
    getPermalinkData: function () {
      return context.getContext().getModel().plinkValue;
    },
    clearPermalinkData: function () {
      context.getContext().getModel().plinkValue = "";
    },
    openDocumentViewer: function (documentVO) {
    	if(StringUtils.isFolder(documentVO)) {
			return repositoryService.openFolderContent(documentVO)
		} else {
			return repositoryService.openPdfViewer(documentVO)
		}
    }, 
    openAgendaDocumentViewer: function (documentVO, agendaPreview) {
        return repositoryService.openAgendaPdfViewer(documentVO, agendaPreview)
      },
      openAgendaPdf: function (documentVO) {
          return repositoryService.openAgendaPdf(documentVO)
      },
    setAgendaMeetingId: function(val){        	
		agendaMeetingId = val;        	
		},
	getAgendaMeetingId: function(){
	  	return agendaMeetingId;
	},
	editAgendaDocument: function(documentVO) {
		return viewerContextMenu.openContextMenu("edit",documentVO);
	},
  checkAnnotationAndReplaceTheDocument: function(callbackFunction,documentVO){
    repositoryService.checkIfTheDocumentHasAnnotation(documentVO.objectBaseUuid).then(function(data){
      if(data) {
        let Alertmessage = localizedMessages.get('ALERT_MESSAGE_ANNOTATED_DOCUMENT_EDIT');
        bvDialog.confirm(Alertmessage,callbackFunction,null,null,"Annotation Alert");
      }
      else{
        callbackFunction();
      }
    });
  },
	renameAgendaAttachment: function (documentVO, callback) {
    documentVO.boName="DocumentBO";
		bvDialog.rename(documentVO, function(data)
	            {
	                callback(data);
	            });
  },
  replaceMessage: function(callback){
  	bvDialog.showContinue(localizedMessages.get('ERR_UPLOARD_EXISTING_FILE_NAME_ALERT'), localizedMessages.get('CONTINUE'),function()
            {
        callback();
    });
  },
  checkInCheckOut: function (objectBaseUuid) {
		return repositoryService.checkInCheckOut(objectBaseUuid, false, false);
  },
    openApproval: function (objectBaseUuid) {
      return repositoryService.openApprovalViewer(objectBaseUuid)
    },
    openSurvey: function (objectBaseUuid) {
      return repositoryService.openSurveyViewer(objectBaseUuid)
    },
    getCompanyTree: function () {
      return companyUserService.getCompanyTree()[0];
    },
    getStateName: function () {
      return $state.$current.name;
    },
    isMobile: function () {
      return MobileUtil.isMobileClient();
    },
    isSurface: function () {
      return MobileUtil.isSurface();
    },
    isIOS: function () {
    	return MobileUtil.isIOS();
    },
    isPhone: function () {
    	return MobileUtil.isPhone();
    },
    isIPhone: function () {
    	return MobileUtil.isIPhone();
    },
    isIPad: function () {
    	return MobileUtil.isIPad();
    },
    isAndroid: function () {
    	return MobileUtil.isAndroid();
    },
    isPortret: function () {
    	return MobileUtil.isPortret();
    },
    isDesktopClient: function () {
      return MobileUtil.isDesktopClient();
    },
    isOffline: function () {
      return MobileUtil.isApp() && MobileUtil.isJupOffline();
    },
    isPushEnabled: function () {
       return context.getContext().getModel().pushEnabled;
    },
    getLocaleCode: function () {
	  
    },
    getPortalID: () => context.getContext().getModel().portalVO.objectBaseUuid,
    getCurrentUser: () => context.getContext().getModel().userVO,
    getPortalData: () => context.getContext().getModel(),
    isMeetingCreationAllowed: function () {
        return calendarService.isCurrentUserAllowedToCreateModule();
    },
    openUserDialog: (survey, cb) => bvDialog.showUserManagementToolkitSurveys(survey, cb),
    openSignatureDialog: id => bvDialog.showSignatureDialog({
      objectBaseUuid: id
    }),
    setSelectedApproval: (approvalData) => {
      approvalsService.selectedApprovalUuid = approvalData.objectBaseUuid;
      approvalsService.setCurrentSelectedApprovalFromList(approvalData);
    },
    openApprovalDetails: (approvalData) => {
      approvalsService.selectedApprovalUuid = approvalData.objectBaseUuid;
      approvalsService.setCurrentSelectedApprovalFromList(approvalData);

      context.getModel().companyVO.isNewDetailsApprovalUIEnabled ?
      $state.go('home.newApprovalDetails', {
        objectBaseUuid: approvalData.objectBaseUuid,
        approvalApiData: approvalData
      }) : $state.go('home.approval');
    },
    editApproval: (approvalData) => {
      approvalsService.selectedApprovalUuid = approvalData.objectBaseUuid;
      approvalsService.setCurrentSelectedApprovalFromList(approvalData);

      context.getModel().companyVO.isNewCreateApprovalUIEnabled ?
      $state.go('home.createNewApproval', {
				objectBaseUuid: approvalData.objectBaseUuid,
				approvalApiData: approvalData
			}) : $state.go('home.createApproval');
    },
    printApproval: (approvalData) => {
      approvalsService.selectedApprovalUuid = approvalData.objectBaseUuid;
      approvalsService.setCurrentSelectedApprovalFromList(approvalData);
      bvDialog.printApproval();
    },
    deleteApprovalConfirm: (approvalData) => {
      approvalsService.selectedApprovalUuid = approvalData.objectBaseUuid;
      bvDialog.confirm(localizedMessages.get("DELETE_APPROVAL_CONFIRM_MSG"), function(){
        approvalsService.deleteApproval(approvalData.objectBaseUuid).then(function(data){
          $state.reload();
        });
      });
    },
    closeApprovalConfirm: (approvalData) => {
      approvalsService.selectedApprovalUuid = approvalData.objectBaseUuid;
      bvDialog.confirm(localizedMessages.get("CLOSE_APPROVAL_CONFIRM_MSG"), function(){
        approvalsService.closeApproval(approvalData.objectBaseUuid).then(function(data){
          $state.reload();
        });
      });
    },
    reopenApprovalConfirm: (approvalData) => {
      approvalsService.selectedApprovalUuid = approvalData.objectBaseUuid;
      bvDialog.confirm(localizedMessages.get("REOPEN_APPROVAL_CONFIRM_MSG"), function(){
        approvalsService.reopenApproval(approvalData.objectBaseUuid).then(function(data){
          $state.reload();
        });
      });
    },
    createPush: (approvalData) => {
      bvDialog.showPush(approvalData);
    },
    openRespondentDialog: (approval, cb) => bvDialog.showUserManagementToolkitApprovals(approval, cb),
    editSignatureDialog: cb => bvDialog.editSignatureDialog(cb),
    attachDocument: (cb) => bvDialog.attachDocument(cb),
    bvLoadingSpinner,
    saveSurveyAsTemplate: function() {
      const createNewSurveyController = angular.element(document.querySelector('#newSurveyCreation')).scope()
      if(createNewSurveyController) createNewSurveyController.saveAsTemplate();
    },
    exitFromSurvey: () => $state.go('home.surveys'),
    exitFromSurveyModule: function () {
	    	if($state.$current.name !== 'home.surveys'){
	    	 return true	
	    	}
    	},
    callReact: {},
    setMeetXVars: function ({
      backButton,
      getSurvey,
      setPublished,
      isSurveyPublished,
      templateSavedCallback
    }) {
      if (backButton)
        this.callReact.backButton = backButton
      if (getSurvey)
        this.callReact.getSurvey = getSurvey
      if (setPublished)
        this.callReact.setPublished = setPublished
      if (isSurveyPublished)
        this.callReact.isSurveyPublished = isSurveyPublished
      if (templateSavedCallback)
        this.callReact.templateSavedCallback = templateSavedCallback
    },
    confirmDialog: (questionText, actionTrue, actionFalse, confirmBox, popupTitle) => bvDialog.confirm(questionText, actionTrue, actionFalse, confirmBox, popupTitle),
    confirmDialogSupportHTMLTags: (questionText, actionTrue, actionFalse, confirmBox, popupTitle) => bvDialog.confirmSupportHTMLTags(questionText, actionTrue, actionFalse, confirmBox, popupTitle),
    showMessage: (questionText, windowClass, autoclose) => bvDialog.showMessage(questionText, windowClass, autoclose),
    sendAlert: survey => {
      surveysAlertService.survey = survey;
      surveysAlertService.openAlertDialog()
      $state.go('home.surveys')
    },
    sendApprovalAlert: approval => {
      approvalAlertService.approval = approval;
      approvalAlertService.openAlertDialog()
    },
    htmlCalendarRangeBackward: function () {
      return context.getContext().getModel().htmlCalendarRangeBackward;
    },
    htmlCalendarRangeForward: function () {
      return context.getContext().getModel().htmlCalendarRangeForward;
    },
    clickEventFromReact: function (date, meetingId, events) {
      var EVENTS_LIST = {
        DATE: date,
        EVENTS: events,
        SELECTED_EVENT: meetingId
      };
      $rootScope.EVENTS_LIST = EVENTS_LIST;
      $rootScope.SELECTED_TAB_FROM_CONF = "" + localizedMessages.get('DAILY');
    },
    startEvent: function (meetingId, meeting, eventName) {
      var meetingObject = {
        objectBaseUuid: meetingId,
        event: eventName,
        activeMeeting: meeting
      };
      $state.go('home.meetingDetails', {
        meetingDetails: meetingObject
      })
    },
    startEventFromReact: function (meetingBaseUuid, selectedMeeting, activeMeeting) {
      if (MobileUtil.isApp() && MobileUtil.isJupOffline()) {
        return;
      }
      var conf = {};
      conf.conf_event = activeMeeting
      conferenceService.getConferenceParticipantsJSON(meetingBaseUuid).then(function (data) {
        conf.conf_participants = data.data;
        presentationService.setConf(conf);
        conferenceService.startConferenceJSON(meetingBaseUuid).then(function (data) {
          presentationService.showConfDialog();
        });
      });
    },
    editEvent: function (selectedMeeting, activeMeeting, eventName) {
      // var meetingObject = {
      // calEvent: selectedMeeting,
      // activeMeeting: activeMeeting,
      // event: eventName,
      // eventSelected: true
      // };
      $state.go('home.meetingCreation', {
        objectBaseUuid: activeMeeting.objectBaseUuid,
        operation: eventName
      })
      //homeService.set_current_module_has_unsaved_changes(true, 'CalendarController');
    },
    viewFullAgenda : function(fileObject){
    	var currentViewFullAgendaPath = $state.current.name
    	  $state.go('home.viewfullagenda', {
    		   fileObject: fileObject,
    		   currentViewFullAgendaPath: currentViewFullAgendaPath
    	  });    	
    },
    copyEvent: function (selectedMeeting, activeMeeting, eventName) {
      // var meetingObject = {
      // calEvent: selectedMeeting,
      // activeMeeting: activeMeeting,
      // event: eventName,
      // eventSelected: true
      // };
      $state.go('home.meetingCreation', {
        objectBaseUuid: activeMeeting.objectBaseUuid,
        operation: eventName
      })
    },
    openMeetingTrackAttendees: function (selectedMeetingId) {
    	bvDialog.showMeetingTrackAttendees(selectedMeetingId);
    },
    showRunningConfsListDialog: function (selectedMeetingId) {
    	presentationService.showRunningConfsListDialog();
    },
    deleteEvent: function (activeMeeting, eventName, deleteCallback) {

        
      $rootScope.$on('deleteMeetingNotificationSent', function(event)
         	 {
         		 if (deleteCallback && typeof deleteCallback == "function")
                      deleteCallback();
                    calendarService.getAllCalendarEvents(true, _=>calendarService.refreshMonthView()) 
         	 });
      const deleteMeeting = (objectBaseUuid, deleteCallback) => {
        calendarService.recycleIntive(objectBaseUuid).then(function (data) {
          if (deleteCallback && typeof deleteCallback == "function")
            deleteCallback();
          calendarService.getAllCalendarEvents(true, _=>calendarService.refreshMonthView())
        });
      }


      const deleteMeetingWithAlert = (objectBaseUuid, deleteCallback) => {
    	  calendarService.sendMeetingNotification(activeMeeting,"deleteAlert");
//    	  setTimeout(function afterTwoSeconds() {
//        	  if (deleteCallback && typeof deleteCallback == "function")
//                  deleteCallback();
//                calendarService.getAllCalendarEvents(true, _=>calendarService.refreshMonthView())
//        	}, 5000)
        	
//        calendarService.sendMeetingAlert(activeMeeting, (SELECTED_USERS_TO_ALERT, dialog) => {
//          const userIds = SELECTED_USERS_TO_ALERT.map(user => user.objectBaseUuid);
//          if (SELECTED_USERS_TO_ALERT.length <= 0)
//            bvDialog.showMessage(localizedMessages.get('MSG_TO_SEND_ALERT_SELECT_USER'));
//          else {
//            const userIds = SELECTED_USERS_TO_ALERT.map(user => user.objectBaseUuid);
//            calendarService.deleteAndSendAlertForMeetingCenterCalendarEvent(activeMeeting.objectBaseUuid, angular.toJson(userIds)).then(function (data) {
//              if (deleteCallback && typeof deleteCallback == "function")
//                deleteCallback();
//              calendarService.getAllCalendarEvents(true, _=>calendarService.refreshMonthView())
//            });
//            dialog.close();
//          }
//        });
      }

      if (eventName != undefined) {
        bvDialog.confirm(localizedMessages.get('MSG_ARE_YOU_SURE_TO_DELETE_THIS_MEETING'), () => {
            bvDialog.confirm(localizedMessages.get('SEND_MEETING_RECYCLE_ALERT'),
              deleteMeetingWithAlert.bind(this, activeMeeting.objectBaseUuid, deleteCallback),
              deleteMeeting.bind(this, activeMeeting.objectBaseUuid, deleteCallback)
            );
          },
          null
        );
      }
    },
    alertEvent: function (activeMeeting, eventName) {
      //calendarService.sendMeetingAlert(activeMeeting);
      calendarService.sendMeetingNotification(activeMeeting,eventName);
    },
    showMeetingDetails: function(meetingId, onMeetingDeleted = null, startDate = null, endDate = null){   
    	bvDialog.showMeetingDetailsReact(meetingId,startDate,endDate,null,onMeetingDeleted);
    },
    showMeetingDetailsForAndroidPhone: function (meeting,meetingId){
    	bvDialogMobile.mobileMeetingDetails(meeting,meetingId);
    },
    currentEditEvent: function (meetingDetails) {
    	$rootScope.$broadcast('currentEditMeeting', meetingDetails);
        pollingService.startMeetingEditPolling(meetingDetails.objectBaseUuid);
    },
    showHideManageMeetingBtn: function (bool, selectedMeeting) {
      console.log('selectedMeeting:'+selectedMeeting);
      
      if (bool) {
        const showMeetingSettings = function () {
          if ($rootScope.EVENTS_LIST.EVENTS && $rootScope.EVENTS_LIST.EVENTS.length > 0) {
            const eventsForSettings = $rootScope.EVENTS_LIST.EVENTS.filter(event => event.isEventOwner == 'true')
            if(eventsForSettings) {
              
              // MEETX-49265
              var teamService = angular.injector(['ng','team.service']).get('teamService');
              var currentUser = context.getModel().userVO;

              teamService.getUserDataBybaseUuid(currentUser.objectBaseUuid).then(function(data) { 
                bvDialog.showMeetingSettings(eventsForSettings, $rootScope.EVENTS_LIST.DATE, null, data.timeFormat);
              });
            }
          }
        }

        	  if(selectedMeeting && selectedMeeting.isEventOwner == 'true'){
            	calendarService.tabs[1].rightMenuList.splice(0, 0, {
                    title: localizedMessages.get('MANAGE_MEETINGS'),
                    click: showMeetingSettings,
                    iconUrl: '',
                    stateName: 'ManageMeetings'
                  })
               }
        
      } else {
        const index = calendarService.tabs[1].rightMenuList.findIndex(o => o.stateName == 'ManageMeetings');
        if (index != -1)
          calendarService.tabs[1].rightMenuList.splice(index, 1)
      }
    },
    clickedEventFromMeetX: null,
    sendMessage: function (users) {
      var bvModalInputs = {}

      bvModalInputs.controller = 'ComposeMessageController';
      bvModalInputs.inputs = {};
      messagesService.isSecureEnabled().then(function (response) {
        if (MobileUtil.isAndroid()) {
          bvModalInputs.windowClass = 'bv-mobile-compose-message';
          bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessageMobile');
          bvModalInputs.buttons = null;
          bvModalInputs.inputs.backdrop = false;
          bvModalInputs.inputs.mobileActionButton = true;
          bvModalInputs.inputs.mobileActionButtonImagePath = 'resources/assets/images/android_icons_send_white_2x.png';
          bvModalInputs.showClose = true;
        } else {
          bvModalInputs.inputs.secure = response.data;
          bvModalInputs.inputs.alwaysSecure = context.getModel().companyVO.alwaysSendSecureEmail;
          bvModalInputs.windowClass = 'bv-modal-window-compose-new-message';
          bvModalInputs.buttons = [{}, {}];
          bvModalInputs.inputs.popupTitle = localizedMessages.get('SEND_MESSAGE');
          bvModalInputs.bodyTemplate = templatesService.getUrl('ComposeMessage');
        }
        bvModalInputs.inputs.messageType = 'compose';
        bvModalInputs.inputs.sharedWithList = users.map(user => {
          return {
            name: user.name,
            uuid: user.id,
            displayName: user.displayName
          }
        });
        bvDialogService.showBvModal(bvModalInputs);
      });
    },
    getTabularOptions: function(){
      return {
        maxCharacterCountSurveyTableQuestion: context.getContext().getModel().companyVO.maxCharacterCountSurveyTableQuestion,
        maxColumnSizeSurveyTableQuestion: context.getContext().getModel().companyVO.maxColumnSizeSurveyTableQuestion,
        maxRowSizeSurveyTableQuestion: context.getContext().getModel().companyVO.maxRowSizeSurveyTableQuestion
      }
    },
    refreshConferencePolling: function() {
      const homeController = angular.element(document.querySelector('[home-controler]')).scope()
      if(homeController) homeController.handleRefreshConferencePolling();
    },
    set_meeting_module_has_unsaved_changes: function(status, ctrl) {
    	homeService.set_current_module_has_unsaved_changes(status, ctrl);
    },
    get_meeting_module_has_unsaved_changes: function() {
     return homeService.get_current_module_has_unsaved_changes();
    },
    set_current_module_has_unsaved_changes: function(status, ctrl) {
    	homeService.set_current_module_has_unsaved_changes(status, ctrl);
    },
    get_current_module_has_unsaved_changes: function() {
     return homeService.get_current_module_has_unsaved_changes();
    },
    openUserDialogCBE: function(cbe, cb) {
    	bvDialog.showUserManagementToolkitCBEs(cbe, cb)
    },
    exitFromCBE: () => $state.go('home.surveys'),
    goToAgendaManageTemplates: () => $state.go('home.manageTemplates'),
	createAgendaTemplateEvent: function (eventName, template) {
    $rootScope.showLoader = false;
    $rootScope.showCreateTemplate = true;
    $state.go('home.createAgendaTemplate'
    ,{
		 operation: eventName,
	     templateName: template? template.templateName : '',
		 templateID: template? template.objectBaseUuid : ''
     },
     $timeout(function() {
      $rootScope.showCreateTemplate = false;
      $rootScope.showLoader = true;
   }, 2500))
	},
// cbeid:null,
    cbeObject: null,
    exitFromMeetingCreation: (meetingId) => {
      homeService.set_current_module_has_unsaved_changes(false, 'CalendarController');
      $state.go('home.meetings')
      calendarService.getAllCalendarEvents(true, _=>calendarService.refreshMonthView())
        if(meetingId != null){
            pollingService.updateSessionPollingForMeetingEditEnd(meetingId);
        }
    },
    goToMeetings: () => $state.go('home.meetings'),
    goToMinutesCreation: (meeting) => {
      $state.go('home.meetingCreation', {
        objectBaseUuid: meeting.objectBaseUuid,
        operation: 'editEvent',
        minutesSelected: "true",
        loggedUserRole: meeting.loggedUserRole === 'OWNER' ? 'OWNER' : 'NOT_OWNER'
      })
    },
    isModuleEnabled: (module) => {
    	  var moduleString = context.getContext().getModel().html5Modules;
    	  var isEnabled = false;
    	  if (moduleString) {
    	    var modules = moduleString.split(',');
    	    var moduleItem = modules.find((mod) => {
    	      return mod.includes(module);
    	    })

    	    if (moduleItem) {
    	      var moduleItemDetails = moduleItem.split('#');
    	      if(moduleItemDetails && moduleItemDetails.length == 2) {
	    	      switch (moduleItemDetails[1]) {
	    	        case "2_1":
	    	          isEnabled = false;
	    	          break;
	    	        case "0_1":
	    	          isEnabled = true;
	    	          break;
	    	        case "1_1":
	    	          var isUserPortalAdmin = context.getContext().getModel().isUserPortalAdmin;
	    	          var isCompanyOwner = permissionService.isOwner(context.getModel().companyVO);
	    	          isEnabled = ((isUserPortalAdmin && isUserPortalAdmin === 'TRUE') || isCompanyOwner)? true: false;
	    	          break;
	    	        default:
	    	          isEnabled = false;
	    	          break;
	    	      }
    	      }
    	    }
    	  }
    	  return isEnabled;
    	},
    getEmailUnreadCount: () => {
        homeService.getEmailUnreadCountFromApi();
    },
    updatePreferedEmailOption:emailOption => {
      homeService.getSelectedEmailOption(emailOption);
    },
    getEmailOptionForUser:() => {
      return homeService.getPreferedEmailOptionForUser();
    },
    getEmailUnreadCountValue: () => {
      homeService.getEmailUnreadCountValue();
    },


    startEmailUnreadCountInterval: () => {
        homeService.startEmailUnreadCountInterval();
    },
    clearEmailUnreadCountInterval: () => {
        homeService.clearEmailUnreadCountInterval();
    },
    exitFromApproval: () => {
      homeService.set_current_module_has_unsaved_changes(false, 'CreateNewApprovalController');
      $state.go("home.approvals");
    },
    callReactApproval: {},
    setMeetXVarsApproval: function ({
      backButton,
      toggleApprovalsFilter,
      openGenerateApprovalReportModal
    }) {
      if (backButton)
        this.callReactApproval.backButton = backButton
      if (toggleApprovalsFilter) {
        this.callReactApproval.toggleApprovalsFilter = toggleApprovalsFilter;
      }
      if (openGenerateApprovalReportModal) {
        this.callReactApproval.openGenerateApprovalReportModal = openGenerateApprovalReportModal;
      }
    },
    getLocalizedStringFromHTMLClient: function (key) {
      return localizedMessages.get(key);
    },
    setApprovalsListFilterCounter: undefined,
    enableManageApproval: undefined,
    set_current_module_has_unsaved_changes: function(status, controler) {
    	homeService.set_current_module_has_unsaved_changes(status, controler);
    }
  }
  return service;
}