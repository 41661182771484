function RepositoryListingController($rootScope, $scope, repositoryService, localizedMessages, bvDialogService, conferenceService, commonService, $state, SortUtility, modal, treeService, context, constants, documentContextMenu, permissionService, bvPollingProcessor, registerStatusCategory, repositoryModelService, CopyPasteService, backUtiltiyService, homeService, permissionsService, urlService, contextMenuModel, contextMenuHandler, loadRecycle, selectionStack,bvDialog, homeContext, $filter, homeModel, uploadFileService, newItemsService, toppanelService, templatesService, SmartSelection, companyUserService, $timeout)
{
		$scope.repositoryList = {};
		$scope.breadCrumbList = [];

		$scope.repositoryTreeModel = [];
		$scope.hideBy = false;
		$scope.showPortalName = false;
		$scope.showUploadButton = false;

        $scope.allowedRememberFolderModules = "FILE_CHOOSER, DOCUMENTS"
        var rootFolder
        var documentsToJSONMove  = [];

        $scope.filterOptions = {name: ""};

        $scope.MobileUtil = MobileUtil;
        
      //changes for MEETX-11798
        var newItemModel = repositoryModelService.getInstance("NEW_ITEM");
        if(StringUtils.isNullEmpty(newItemModel.repositoryList().browseList))
    	{
        	if(!MobileUtil.isJupOffline()){
        	var niService=repositoryService.getNewItemService();
        	niService.loadLightWeightNewItemsJson()
			.then(function(response)
			{
				if(response.data.boName == "FailureBO") { return; }
				newItemModel.repositoryList(response.data);
				newItemModel.newItemsLoadedFull(true);
			});
           }
    	}
        else if(!newItemModel.newItemsLoadedFull()){
        	if(!MobileUtil.isJupOffline()){
        	var niService=repositoryService.getNewItemService();
        	niService.loadLightWeightNewItemsJson()
			.then(function(response)
			{
				if(response.data.boName == "FailureBO") { return; }
				newItemModel.repositoryList(response.data);
				newItemModel.newItemsLoadedFull(true);
			});
          } 	 
        }
        var favoritesModel = repositoryModelService.getInstance("FAVORITES");
        if(StringUtils.isNullEmpty(favoritesModel.repositoryList().browseList))
    	{
        	repositoryService.getDocumentsJSONForBriefCase().then(function(data)
                    {		
                		favoritesModel.repositoryList(data.data);
                    });
    	}
        $scope.startsWith = function (actual, expected)
        {
            var lowerStr = (actual + "").toLowerCase();
			return lowerStr.indexOf(expected.toLowerCase()) === 0;
        };

        $scope.scrollConfigForTree = {
            autoHideScrollbar: true,
            theme: 'minimal-dark'
        };
        $scope.scrollConfig = {};
        if($scope.currentInstance == "DOCUMENTS" || $scope.currentInstance == "FAVORITES") {
            $scope.scrollConfig = $scope.scrollConfigForTree;
        }

		$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
        $scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
        $scope.linkIcon = 'resources/assets/images/link.png';

        $scope.bvcheckfalseSmall = 'resources/assets/svgs/bvcheckfalseSmall.svg'
       	$scope.bvcheckfalseMedium = 'resources/assets/svgs/bvcheckfalseMedium.svg'
   		$scope.bvcheckfalseLarge = 'resources/assets/svgs/bvcheckfalseLarge.svg'

   		$scope.bvchecktrueSmall = 'resources/assets/svgs/bvchecktrueSmall.svg'
   		$scope.bvchecktrueMedium = 'resources/assets/svgs/bvchecktrueMedium.svg'
   		$scope.bvchecktrueLarge = 'resources/assets/svgs/bvchecktrueLarge.svg'

        $scope.listofUploadFiles = [];//list of files which are selected to upload

		$scope.selectedObjectFromContextMenu = ""; //Object is selected from ContextMenu on RightClick

		var treeSelectionModel = treeService.getInstance($scope.currentInstance);

		var backUtiltiy  = backUtiltiyService.getInstance($scope.currentInstance);

		var allowedTree = false;

		var repositoryModel;

		var loadingFilesIndex;

		$scope.selectedItems = []; //multiple selected items
		$scope.filterLbl = localizedMessages.get("FILTER");
		$scope.followingLabel = localizedMessages.get("FOLLOWING");

		$scope.virusInfectedLabel = localizedMessages.get("VIRUS_INFECTED");
		$scope.msg_no_documents_in_folder = localizedMessages.get('MSG_NO_DOCUMENTS_IN_FOLDER');
		$scope.msg_no_favorites = localizedMessages.get('MSG_COMMON_NO_DOCUMENTS');
		$scope.isEditing = localizedMessages.get("IS_EDITING");
		$scope.checkedOutBy = localizedMessages.get("CHECKING_OUT_BY");
		$scope.none_items=localizedMessages.get("MSG_COMMON_NO_DOCUMENTS");
        $scope.byLbl = localizedMessages.get("BY").toLowerCase();
        $scope.onLbl = localizedMessages.get("ON");
        $scope.trashTitle = localizedMessages.get("TRASH");
		$scope.editedLbl = localizedMessages.get("EDITED");
		$scope.modifiedLbl = localizedMessages.get("MODIFIED");
		$scope.addedLbl = localizedMessages.get("ADDED");
		var virusScanFailureLabel = localizedMessages.get("VIRUS_SCAN_FAILURE");
		var scanengineConnectionFailureLabel = localizedMessages.get("SCANENGINE_CONNECTION_FAILURE");
		$scope.scanFailureLabel =scanengineConnectionFailureLabel;

		bvPollingProcessor.registerForPolling($scope,registerStatusCategory.DOCUMENT);
		bvPollingProcessor.registerForPolling($scope,registerStatusCategory.FOLDER);

        $scope.$on('HOME_CONTROLLER_CLICK', function(event){
            //console.log("Event",event);
            if($scope.showRightClick)
                $scope.showRightClick = false;
        });


        /*
         * Handle Polling Service for refresh the current View
         *
         */
        $scope.reloadFolder = function(folderId)
        {
        	if(folderId == treeSelectionModel.getSelectedNode().objectBaseUuid)
    		{
        		$scope.getDocumentsForParentBaseUuid(treeSelectionModel.getSelectedNode().objectBaseUuid);
    			getFolderTreeData(treeSelectionModel.getSelectedNode());
    		}
        }

        /***
         * Handling Polling for replaceing the documents
         *
         */
        $scope.replaceDocument= function (updated)
        {
        	angular.forEach($scope.repositoryList.browseList, function(businessObject,key)
	        {
				if( businessObject.objectBaseUuid == updated.objectBaseUuid )
				{
					$scope.repositoryList.browseList.splice(key, 1, updated);
				}
			})
        }

        var removeDocumentFromList = function (objectBaseUuid)
        {
        	angular.forEach($scope.repositoryList.browseList, function(businessObject,key)
	        {
				if( businessObject.objectBaseUuid == objectBaseUuid )
				{
					$scope.repositoryList.browseList.splice(key, 1);

					if($scope.repositoryList.browseList.length<1)
					{
						$scope.repositoryList.noDocumentsInFolder = true;
	        			$scope.msg_no_documents = localizedMessages.get('MSG_NO_DOCUMENTS');
	        			$scope.none_items=localizedMessages.get("MSG_COMMON_NO_DOCUMENTS");
					}
				}
			})
        }


        /**
         * TODO - Copylink function is not implemented in HTML documents contextmenu
         *
         */
        var copylink = function()
        {
        	var baseUuid = $scope.selectedObjectFromContextMenu.objectBaseUuid;
        	var currentLocation = window.location.href;
        	var position = currentLocation.indexOf("MeetX");
        	var sublocation = currentLocation.substring(0, position+8);
        	var newlocation = sublocation+baseUuid;

        };

        //TODO createShortCut

        var createShortCut = function()
        {
        	var documentsToJSON = getBusinessObjectArrayJSONforSelectedContextMenu();

        	var selectedObjectBaseUuid = treeSelectionModel.getSelectedNode().objectBaseUuid;

        	if($scope.selectedObjectFromContextMenu.objectBaseUuid == selectedObjectBaseUuid)
        	{
        			repositoryService.getParentNode(selectedObjectBaseUuid).then(function(parent)
		        	{
        				treeSelectionModel.setSelectedNode(parent);
        				repositoryService.createShortCut(parent.objectBaseUuid, documentsToJSON).then(function(data)
		    		        	{
		    		        		if(data.data)
		    		        		{
		    		        			repositoryModel.repositoryList(data.data);
		    		        		}
		    		        	});
		        	});

        	}
        	else
        	{
        		repositoryService.createShortCut(treeSelectionModel.getSelectedNode().objectBaseUuid, documentsToJSON).then(function(data)
	        	{
	        		if(data.data)
	        		{
	        			repositoryModel.repositoryList(data.data);
	        		}
	        	});
        	}

        }

        var renameFile = function(renameObject)
        {
        	if(renameObject.objectBaseUuid == treeSelectionModel.getSelectedNode().objectBaseUuid)
    		{
        		treeSelectionModel.getSelectedNode().name = renameObject.name;
    		}
        	else
    		{
        		angular.forEach($scope.repositoryList.browseList, function(businessObject)
	        	{
					if( businessObject.objectBaseUuid == renameObject.objectBaseUuid )
					{
						businessObject.name = renameObject.name;
					}
	        	});

	    		angular.forEach(treeSelectionModel.getSelectedNode().children, function(businessObject)
	        	{
					if( businessObject.objectBaseUuid == renameObject.objectBaseUuid )
					{
						businessObject.name = renameObject.name;
					}
	        	});

	    		if(sessionStorage.getItem("documentsSortOrder") != "" && sessionStorage.getItem("documentsSortOrder") != "null" && sessionStorage.getItem("documentsSortOrder") != undefined) {
	    			var sortOrder = JSON.parse(sessionStorage.getItem("documentsSortOrder"));
	    			var repositoryController = angular.element(document.querySelector('[bv-repository-listing]')).scope();
	    			if(repositoryController != undefined && repositoryController.currentInstance == "DOCUMENTS")
	    				repositoryController.selectMenuItem(sortOrder);
	    		}
    		}
        }

        /**
         *    Callback handler for document context menu
         *
         */

        $scope.menuCallback = function(type, param){
        	switch (type)
        	{
    			case 'COLLATE':
    				$scope.callParentController("COLLATE",param);
    				break;
    			case 'EDIT_COLLATE':
    				$scope.callParentController("EDIT_COLLATE",param);
    				break;
                case 'BOARDBOOK':
                    $scope.callParentController("BOARDBOOK",param);
                    break;
                case 'EDIT_BOARDBOOK':
                	$scope.callParentController("EDIT_BOARDBOOK",param);
                    break;
                case 'OPEN_DOCUMENT':
                	openFile(param[0]);
                    break;
                case 'RENAME':
                	renameFile(param[0]);
                    break;
                case 'MENU_STYLE':
                	$scope.menuStyle = {'top': param[0].y + "px",'left': param[0].x + "px"};
                    break;
                case 'MENU_ARROW_STYLE':
                    $scope.menuLeftArrowStyle = {'top': param.y + "px", 'left': param.x + "px"};
                    break;
                case 'DELETE':
                	deleteBusinessObject(param[0]);
                	break;
                case 'MOVE_DOCUMENT':
                	moveDocument(param[0], param[1]);
                	break;
                case 'HIDE':
                	
                	if(treeSelectionModel.getSelectedNode().objectBaseUuid !== $scope.repositoryTreeModel[0].objectBaseUuid){
                		var parentNode = treeService.findParentNodeById(treeSelectionModel.getSelectedNode().objectBaseUuid, $scope.repositoryTreeModel[0]);
                		getSelectedTreeNode(parentNode);
                	}else{
                		getSelectedTreeNode(treeSelectionModel.getSelectedNode());
                	}
                	//treeSelectionModel.getSelectedNode().isHidden=param[0];
                	$scope.getDocumentsForParentBaseUuid(treeSelectionModel.getSelectedNode().objectBaseUuid);
                	$scope.setSelectedNode(treeSelectionModel.getSelectedNode());
                	//treeSelectionModel.getChildrenForTreeNode(treeService.findParentNodeById(treeSelectionModel.getSelectedNode().objectBaseUuid, $scope.repositoryTreeModel[0]), repositoryService.findFoldersAndSearchObjectsForParentUuid);
                	//treeService.findParentNodeById(treeSelectionModel.getSelectedNode().objectBaseUuid, $scope.repositoryTreeModel[0])
                	//$scope.setSelectedNode(treeService.findParentNodeById(treeSelectionModel.getSelectedNode().objectBaseUuid, $scope.repositoryTreeModel[0]));
                    break;
                case 'REMOVE_FROM_FAVORITES':
                	//$scope.getDocumentsJSONForBriefCase();
                	$scope.callParentController("REMOVE_FROM_FAVORITES",param);
                    break;
                case 'MAKE_DEFAULT':
                    //$scope.getDocumentsJSONForBriefCase();
                    $scope.callParentController("MAKE_DEFAULT",param);
                    break;
                default:
                    break;
    		}
        }

        var getSelectedTreeNode = function(selectedNode)
        {
        	repositoryService.findFoldersAndSearchObjectsForParentUuid(selectedNode.objectBaseUuid).then(function(data)
        	{
        		selectedNode.children = [];

        		selectedNode.children = data.data;

        	});
        };
		$scope.myFiles = [];

		$scope.upload = function(files)
		{
			$scope.listofUploadFiles = files;
			$scope.uploadSelectedFiles();
		}


		/**
		 * Get children businessObject in list view for Selected Folder BaseUuid
		 *
		 */
        $scope.getDocumentsForParentBaseUuid = function (baseUuid)
        {
        	repositoryService.getDocumentsJSONForParentBaseUuid(baseUuid).then(function(data)
        	{
        		if($scope.repositoryDisplayObjects != undefined && (($scope.repositoryDisplayObjects.onlyFiles != undefined && $scope.repositoryDisplayObjects.onlyFiles) || !StringUtils.isNullEmpty($scope.repositoryDisplayObjects.permissionCheck)))
    			{
        			for(var i = 0 ; i < data.data.length ; i++)
        			{
        				if($scope.repositoryDisplayObjects.onlyFiles)
        					data.data[i].hideCheckBox = StringUtils.isFolder(data.data[i].objectBaseUuid);
        				if(!StringUtils.isNullEmpty($scope.repositoryDisplayObjects.permissionCheck))
        					permissionCheckForDocument(data.data[i]);

    	    		}
    			}
        		repositoryModel.repositoryList(data.data, $scope.currentInstance);
        	});
        };

        var permissionCheckForDocument = function (document)
        {
			if($scope.repositoryDisplayObjects.permissionCheck == "own" && !permissionService.isOwner(document))
			{
				document.hideCheckBox = true;
			}
			else if($scope.repositoryDisplayObjects.permissionCheck == "edit" && !permissionService.isEdit(document))
			{
				document.hideCheckBox = true;
			}
			else if($scope.repositoryDisplayObjects.permissionCheck == "share" && !permissionService.isShareAllowed(document))
			{
				document.hideCheckBox = true;
			}
        }

        /*
         * Get Childeren businessObject in tree for Selected Folder BaseUuid
         *
         */

		var getFolderTreeData = function(folderObject)
        {
			treeSelectionModel.getChildrenForTreeNode(folderObject, repositoryService.findFoldersAndSearchObjectsForParentUuid);
        };


        /**
         * Right Click event handler for tree
         */

        $scope.handleRightClickForTree = function(xPos, yPos, selectedContextItem, event)
		{
        	if($scope.repositoryDisplayObjects.disableRightClick)
        		return;

        	if(selectedContextItem.collapsed == undefined)
        		selectedContextItem.collapsed = true;

			$scope.setSelectedNode(selectedContextItem);

			contextMenuModel.parentObject = $scope.currentInstance+"_TREE";

			$scope.rightClickDispatcher(xPos, yPos, selectedContextItem, event);
		}

        $scope.handleMenuClick = function(event, seldocument)
        {
        	$scope.handleRightClick(event.pageX, event.pageY, seldocument, event);
        }
        /**
         * Right Click Menu Handler for Document Lisitng
         *
         */

        $scope.handleRightClick = function (xPos, yPos, selectedContextItem, event)
        {
        	contextMenuModel.parentObject = $scope.currentInstance;

        	$scope.rightClickDispatcher(xPos, yPos, selectedContextItem, event)
        }

		$scope.rightClickDispatcher = function (xPos, yPos, selectedContextItem, event)
        {
            $scope.rightClickedItem = "";
			$scope.showRightClick = false;
			var contextMenuCheckOptions = {
					alreadySubscribed : false,
					setFollowers: false
			}

			var selectedItemUuids = [];

        	if(selectedContextItem === undefined)
        		selectedContextItem = treeSelectionModel.getSelectedNode();
            else
                $scope.rightClickedItem = selectedContextItem.objectBaseUuid

        	$scope.getSelectedItemsBaseUuid();

        	$scope.selectedObjectFromContextMenu = "";

        	contextMenuModel.xPos = xPos;
			contextMenuModel.yPos = yPos;
			contextMenuModel.event = event;
			contextMenuModel.currentInstance = $scope.currentInstance;

			contextMenuModel.parentFolder = treeSelectionModel.getSelectedNode();

			var showMultiSelectMenu = false;

			for (var i = 0, len = $scope.selectedItems.length; i < len; i++)
			{
				if($scope.selectedItems[i].objectBaseUuid == selectedContextItem.objectBaseUuid)
				{
					showMultiSelectMenu = true;
					break;
				}
			}

			if(showMultiSelectMenu)
			{
				repositoryService.isAlreadySubscribed($scope.selectedItems).then(function(data){

        			contextMenuCheckOptions.alreadySubscribed = data.data;

        			if($scope.selectedItems.length == 1)
	            	{
	            		var selectedObject = $scope.selectedItems[0];

            			contextMenuModel.selectedObject = selectedObject;
            			contextMenuModel.contextMenuCheckOptions = contextMenuCheckOptions;
            			contextMenuModel.multiple = false;

            			$scope.showRightClick = true;
	    	        }
	    	        else
	            	{
            			contextMenuModel.selectedObject = $scope.selectedItems;
            			contextMenuModel.contextMenuCheckOptions = {};
            			contextMenuModel.multiple = true;

            			$scope.showRightClick = true;
	            	}
        		});
			}
			else
        	{
				deSelectedAlltheItems();
				
				permissionsService.isSetFollowersEnabled(selectedContextItem.objectBaseUuid).then(function(data){
	                	 contextMenuCheckOptions.setFollowers = data.data;
	                	 
	                	 repositoryService.isAlreadySubscribed(selectedContextItem).then(function(data){

	             			contextMenuCheckOptions.alreadySubscribed = data.data;

	             			contextMenuModel.selectedObject = selectedContextItem;
	             			contextMenuModel.contextMenuCheckOptions = contextMenuCheckOptions;
	             			contextMenuModel.multiple = false;

	             			$scope.showRightClick = true;

	             		});
	                	 
	              });
        		
        	}


        };

        $scope.$watch('showRightClick', function(){
	        		if(!$scope.showRightClick && $scope.selectedObjectFromContextMenu)
			    		$scope.selectedObjectFromContextMenu.selectedOption = false;
			})

        /**
         * Get all the multiple selected businessObject from the list View.
         *
         */

		$scope.getSelectedItemsBaseUuid = function()
        {
        	var selectedBaseUuids = [];
        	$scope.selectedItems = [];

        	angular.forEach($scope.repositoryList.browseList, function(businessObjectVO)
        	{
        		  if (businessObjectVO.selected && businessObjectVO.selected == true)
        		  {
        			  $scope.selectedItems.push(businessObjectVO);
        			  selectedBaseUuids.push(businessObjectVO.objectBaseUuid);
        		  }
        	});

        	return selectedBaseUuids;
        }

        /**
         * Deselect all the businessObject from the list View.
         *
         */

		var deSelectedAlltheItems = function()
        {
        	angular.forEach($scope.repositoryList.browseList, function(businessObjectVO)
        	{
        		  if (businessObjectVO.selected && businessObjectVO.selected == true)
        		  {
        			  businessObjectVO.selected = false;
        		  }
        	});
        }

        /*
         * Check for is multiple Selected
         *
         */
//        var isMultipleSelected = function()
//        {
//        	var isMultiple = false;
//        	var i = 0;
//
//        	angular.forEach($scope.repositoryList.browseList, function(businessObjectVO)
//        	{
//        		  if (businessObjectVO.selected && businessObjectVO.selected == true)
//        		  {
//        			  i++;
//        			  if(i > 1)
//        				  return true;
//        		  }
//        	});
//
//        	return selectedObjects;
//        }

        /**
         * Loading Documents For New Items
         *
         */

        var getDocumentsForNewItems = function()
        {
        	repositoryService.getDocumentsJSONForNewItems().then(function(data)
        	{
        		repositoryModel.repositoryList(data.data);
        	});
        };
        //changes for NBV-175
         $scope.fetchScanStatusOnScanException = function(document){
           if( (document.scanStatus === 'ERROR_INVALID_INPUT') ||
               (document.scanStatus === 'FILE_SIZE_TOO_LARGE') ||
               (document.scanStatus === 'NO_AV_LICENSE')) {
             $scope.scanFailureLabel = virusScanFailureLabel;
             return true;
             }
            if((document.scanStatus === 'ERROR_SECURE_SOCKET_CREATION') ||
               (document.scanStatus === 'IP_UNRESOLVED') ||
               (document.scanStatus === 'ERROR_SOCKET_COMMUNICATION') ||
               (document.scanStatus === 'ERROR_ON_SERVER_READ') ||
               (document.scanStatus === 'ERROR_READ_WRITE_TIMEOUT') ||
               (document.scanStatus === 'ERROR_SERVER_TOO_BUSY') ||
               (document.scanStatus === 'MAX_TRIES_REACHED') ||
               (document.scanStatus === 'ERROR_OUTPUT_STREAM_OPEN') ||
               (document.scanStatus === 'ERROR_FILE_INPUT_STREAM_OPEN') ||
               (document.scanStatus === 'ERROR_FILE_READ') ||
               (document.scanStatus === 'ERROR_FILE_INPUT_STREAM_CLOSE') ||
               (document.scanStatus === 'INTERNAL_SERVER_ERROR') ||
               (document.scanStatus === 'FILE_ACCESS_FAILED') ||
               (document.scanStatus === 'RESOURCE_UNAVAILABLE') ||
               (document.scanStatus === 'ERROR_ON_SERVER_WRITE') ||
               (document.scanStatus === 'ERROR_INPUT_STREAM_OPEN')
               ) {
               $scope.scanFailureLabel =scanengineConnectionFailureLabel;
               return true;
               }
             else
             return false;
         }
        /*
         * Event handler for click event in document list view
         *
         */

        $scope.handleRepositoryForItemSelected = function (event, document)
        {
        	console.log(document);
        	console.log($scope.currentInstance);
            if($scope.currentInstance === "AGENDA_FILE_CHOOSER")
            {
                if(StringUtils.isFolder(document.objectBaseUuid))
                {
                    $scope.getDocumentsForParentBaseUuid(document.objectBaseUuid);
                    addDocumentToBackButtonStack(document);
                }
                else
                {
                    var link = urlService.geCopyLinkURL(document.objectBaseUuid);
                    IDRLinkManipulator.addHyperlink(link);
                }
                return;
            }
            if($scope.currentInstance==="FILE_CHOOSER")
            {

            	if(StringUtils.isFolder(document.objectBaseUuid))
            	{
            		 $scope.filterOptions = {name: ""};

            	}

            }


            if($scope.showRightClick && $scope.currentInstance !="FOLDER_LISTING")
                return;

        	if(event && event.target.id != "repositoryCheckbox")
    		{
        		var isCtrlKeyDown = event.ctrlKey || event.metaKey;
                var isShiftKeyDown = event.shiftKey;

              // Select multiple allows for ranges - use shift key
                if(isShiftKeyDown || isCtrlKeyDown)
            	  return;

                if($scope.repositoryDisplayObjects && $scope.repositoryDisplayObjects.disableOpen)
            		return;
             // MEETX-29910- changes start
              //  if(document.extensionType=="csv")
              //  {
              //  	console.log(document);
              //  	bvDialog.showMessage(localizedMessages.get("MSG_EROR_FILE_TYPES"));
              //  	return;
              //  }
              // MEETX-29910- changes end
                $scope.openDocument(document, ($scope.currentInstance === "FOLDER_LISTING"));

                addDocumentToBackButtonStack(document);
//                 if(!($scope.currentInstance == "FILE_CHOOSER" && MobileUtil.isMobileClient()))
//                    event.stopPropagation(); // so that item don't get selected on name click
				// disable Folder selection for Surface device
                if(MobileUtil.isSurface()){
                	document.selected = true;
                	if(StringUtils.isFolder(document.objectBaseUuid)){
                		document.selected = false;
                	}
                	selectionStack.updateSelectedItems(document);
                }
                else{
                	if(MobileUtil.isMobileClient()){
                    	$scope.repositoryList.browseList.forEach(function(document){document.selected = false});
                    	document.selected = true;
                    	selectionStack.updateSelectedItems(document);
                    }
                }
            }
        };


        $scope.uploadFiles = function(target, files)
        {
            var uploadFileObjects = {};

    		uploadFileObjects.action = "upload";
    		uploadFileObjects.parentId = treeSelectionModel.getSelectedNode().objectBaseUuid;
    		uploadFileObjects.onUploadComplete = $scope.onUploadComplete;
    		uploadFileObjects.listofUploadFiles = files;
    		uploadFileObjects.hideUploadFileButtons = true;
            $scope.currentInstance = "DOCUMENTS";
            var selectedNode = JSON.parse(sessionStorage.getItem($scope.currentInstance +"-lastSelectedFolder-" + $scope.$root.SELECTED_PORTAL_BASEUUID));
			if ((selectedNode) && (selectedNode.objectBaseUuid != uploadFileObjects.parentId)){
				bvDialog.showMessage(localizedMessages.get('FILE_UPLOAD_FOLDER_MISMATCH_ERROR'));
			}
            else{
                bvDialogService.uploadFile(uploadFileObjects);
            }
        }


        var openDialogForUpload = function()
        {
        	$scope.parentId = treeSelectionModel.getSelectedNode().objectBaseUuid;
        	repositoryService.getDocumentsJSONForParentBaseUuid($scope.parentId).then(function(data)
    	    	{
//    		        $scope.selectedNode = data.data;
//    		        $scope.uploadFileModal = new modal.open({
//    		        	template: '<div bv-upload-file upload-complete="onUploadComplete" action="upload" upload-parent="{{parentId}}" selected-node="selectedNode"></div>',
//    	        	    plain:true,
//    	        	    scope: $scope,
//    		            windowClass : 	'bv-modal-window-repository-upload-file',
//    				});
//    		        ngDialog.open({
//    		        	template: '<div bv-upload-file upload-complete="onUploadComplete" action="upload" upload-parent="{{parentId}}" selected-node="selectedNode"></div>',
//    		    	    plain:true,
//    		    	    scope: $scope
//    		    	});
    		    var uploadFileObjects = {};

        		uploadFileObjects.action = "upload";
        		uploadFileObjects.parentId = treeSelectionModel.getSelectedNode().objectBaseUuid;
        		uploadFileObjects.onUploadComplete = $scope.onUploadComplete;

        		bvDialogService.uploadFile(uploadFileObjects);
    	    	});
        };

  
        $scope.singleClickSelection = function (event, documents, currentSelectedIndex)
        {
       
        	/*if(documents.isPdfACompliant != undefined && documents.isPdfACompliant != null && documents.isPdfACompliant =='false'){
        		console.log("stopping propagation for NC document")
        		event.stopPropagation();
        		return;
        	}
        	else*/
        	{
          $timeout(() => {
            const toppanel = toppanelService.getInstance('REPOSITORY');
            if($scope.repositoryList.browseList.filter(documents => documents.selected).length == $scope.repositoryList.browseList.length && document.getElementById("selectAllCheckBox")){
            	document.getElementById("selectAllCheckBox").classList.add('deSelectAllLbl');
            	$rootScope.selectAll = true;
            }
            else if(document.getElementById("selectAllCheckBox")){
			  document.getElementById("selectAllCheckBox").classList.remove('deSelectAllLbl');
			  $rootScope.selectAll = false;
			}
		  })
		  	
        	console.log("Repositoy clicked event");

        	if($scope.currentInstance == "TRASH"){
        		if($scope.callParentController instanceof Function){
        			$scope.callParentController("TRASH_CHECKBOX", null);
        		}
        	}

        	var mobile=MobileUtil.isMobileClient();

        	if(mobile && !MobileUtil.isSurface())
        	{
        		for(var i=0;i<$scope.repositoryList.browseList.length;i++)
        		{
        			if(documents.objectBaseUuid!=$scope.repositoryList.browseList[i].objectBaseUuid)
        			{
        				$scope.repositoryList.browseList[i].selected=false;
        			}
        		}
        	}
        	
        	if(MobileUtil.isSurface())
        	{
        		for(var i=0;i<$scope.repositoryList.browseList.length;i++)
        		{
        			if(documents.objectBaseUuid == $scope.repositoryList.browseList[i].objectBaseUuid)
        			{
        				if($scope.repositoryList.browseList[i].selected){
        					$scope.repositoryList.browseList[i].selected = true;
    					}
        				else{
        					$scope.repositoryList.browseList[i].selected = false;
        				}
        			}
        		}
        	}        	
        	console.log(mobile);
        	}
        };

        /**
         * Adding businessObject into the stack for Back Button
         *
         */

        var addDocumentToBackButtonStack = function (document)
        {
        	if(StringUtils.isNullEmpty($scope.middleBarObject))
        		return;

        	if(StringUtils.isFolder(document.objectBaseUuid))
        	{
        		backUtiltiy.pushButtonStack(document);
    	    	$scope.middleBarObject.showBack = backUtiltiy.showBack;
    	    	$scope.middleBarObject.title = backUtiltiy.title;
        	}
        }

        $scope.openDocument = function (document, keepFolderViewerOpen = false)
        {
        	var parentBaseUuid = document.objectBaseUuid;

        	if(StringUtils.isFolder(parentBaseUuid))
        	{
                $scope.setSelectedNode(document, true);
    //     		//$scope.breadCrumbList.push(document);
    //     		$scope.getDocumentsForParentBaseUuid(parentBaseUuid);

				// if(allowedTree)
				// {
				// 	//$scope.selectedTreeNode.collapsed = false;
    //                 treeSelectionModel.getSelectedNode().collapsed = false;

				// 	setSelectedTreeNodeFromList(document);
				// }

				// uploadFilePermission(document);

        	}
        	else
        	{
				if(!keepFolderViewerOpen){
					var folderViewer = angular.element('#folderViewerListing');
					if(folderViewer != undefined && folderViewer != null)
					{
						var $folderScope = angular.element('#folderViewerListing').scope();
						if($folderScope != null)
						{
							$folderScope.close();
						}
					}
				}
				else{
					window.document.querySelector('body').classList.add('folderViewerListingModalOpen');
				}

                if($scope.currentInstance != "FILE_CHOOSER")
                	contextMenuHandler.openFile(document);
        	}
        };

        $scope.openFolderInDocumentModule = function (document)
        {
        	var parentBaseUuid = document.objectBaseUuid;

            repositoryService.getDocumentOrFolderParentObjectIds(parentBaseUuid).then(function(data){
                if(!data)
                    return;

                if(data.parentFolderIds.length > 1){
                    var listOfParents = data.parentFolderIds.reverse();

                    var k = 1;
                    function fetchAllTree(){

                        if(allowedTree)
                        {
                            treeSelectionModel.getSelectedNode().collapsed = false;
                            setSelectedTreeNodeFromList(_.find(treeSelectionModel.getSelectedNode().children, {objectBaseUuid: listOfParents[k]}), function(){
                                if(k < listOfParents.length - 1){
                                    k++;
                                    fetchAllTree()
                                }
                                else{
                                    if($scope.openFolder.open && StringUtils.isFolder(parentBaseUuid))
                                        $scope.openDocument(document);
                                    else
                                        $scope.getDocumentsForParentBaseUuid(listOfParents[k]);

                                    $state.params.folderBaseUuid = null;
                                    $state.params.open = null;
                                }
                            });
                        }
                    }

                    fetchAllTree();
                }
                else{
                    if($scope.openFolder.open && StringUtils.isFolder(parentBaseUuid))
                        $scope.openDocument(document);
                    else
                        $scope.openDocument(treeSelectionModel.getSelectedNode());
                }
            });
        }

        var uploadFilePermission = function(document)
        {
        	if ($scope.callParentController && (typeof $scope.callParentController === 'function'))
			{
//				if(permissionService.isOwner(document) || permissionService.isEdit(document) || permissionService.isShareAllowed(document))
//				{
//					$scope.callParentController("SHOW_HIDE_FILE_FOLDER",[true]);
//				}
//				else
//					$scope.callParentController("SHOW_HIDE_FILE_FOLDER",[false]);

				$scope.callParentController("SHOW_HIDE_FILE_FOLDER",[document]);
			}
        }

        $scope.unregisterBreadCrumb = function(selectedIndex, bo){
			if($scope.breadCrumbList.length > selectedIndex + 1)
			{
				$scope.breadCrumbList = $scope.breadCrumbList.slice(0, 1+selectedIndex);
				$scope.getDocumentsForParentBaseUuid(bo.objectBaseUuid);
			}

			console.log(selectedIndex + bo.name);
        };

		$scope.dragStartHandler = function(event, element, dragDropService)
		{
			var selectedBaseUuids =  $scope.getSelectedItemsBaseUuid();
			var selectedItem = event.currentTarget;
			var validItems = [];
			var invalidItems = [];

			if(selectedBaseUuids.length > 1)
			{
				selectedBaseUuids = [];
				angular.forEach($scope.selectedItems, function(bo)
		        {
					if (permissionService.isOwner(bo))
					{
						validItems.push(bo);
						selectedBaseUuids.push(bo.objectBaseUuid)
					}
					else
					{
						invalidItems.push(bo);
					}
				});

				if(validItems.length == 0)
				{
					event.dataTransfer.effectAllowed = 'none';
				}

				var newElement = document.createElement('div');
				newElement.id = "drag-drop-coveredup";
				newElement.innerHTML = '      '+selectedBaseUuids.length+' file(s) selected.';

			    document.body.appendChild(newElement);
			   // event.dataTransfer.setDragImage(newElement, 0, 0);

				documentsToJSONMove = angular.toJson(selectedBaseUuids);
			}
			else
			{
				var bo = dragDropService.allDraggedItems[0];

				if (permissionService.isOwner(bo))
				{
					validItems.push(bo);
				}
				else
				{
					event.dataTransfer.effectAllowed = 'none';
					return;
				}

				selectedBaseUuids = [selectedItem.id];
				documentsToJSONMove = angular.toJson(selectedBaseUuids);
			}

			var dragDropObject = new Object();
			dragDropObject.dragInitiator = "repository";
			dragDropObject.allDraggedItems = documentsToJSONMove;

			event.dataTransfer.setData('Text', angular.toJson(dragDropObject));
		}

		$scope.handleDragOver = function(target, dragDropJSONObject, dragDropService)
		{

		}

		$scope.handleDrop = function(target, dragDropJSONObject, dragDropService)
		{
			var dragDropObject = angular.fromJson(dragDropJSONObject);

			var targetBaseUuid = "";

			if(target.attributes.id)
				targetBaseUuid = target.attributes.id.value;

			var itemsToBeMoved = angular.fromJson(dragDropObject.allDraggedItems);

			if(itemsToBeMoved.length == 0)
				return;

			if(itemsToBeMoved.toString().indexOf(targetBaseUuid) != -1) // Add Folder in same folder.
				return;

			if(!StringUtils.isFolder(targetBaseUuid))
				targetBaseUuid = treeSelectionModel.getSelectedNode().objectBaseUuid

			var itemParent = "";
			
			if(StringUtils.isFolder(itemsToBeMoved[0]))
			{
				var movedObject = treeService.findNodeUsingBaseUuid(itemsToBeMoved[0],$scope.repositoryTreeModel[0]);
				
				var subFolderParentObject = treeService.findParentNodeById(targetBaseUuid, movedObject);
				
				if(!StringUtils.isNullEmpty(subFolderParentObject))
					return;
				
				itemParent = treeService.findParentNodeById(itemsToBeMoved[0], $scope.repositoryTreeModel[0]);
			}
			else	
			{
				itemParent = treeSelectionModel.getSelectedNode();
			}

			if(targetBaseUuid === itemParent.objectBaseUuid) // Droping file into the same parent.
				return;

			repositoryService.moveDocuments(itemParent.objectBaseUuid, targetBaseUuid , dragDropObject.allDraggedItems, dragDropObject.dragInitiator).then(function(data)
        	{
				if(data.data.boName == "FailureBO")
				{
//					ngDialog.open({
//						template: '<div style="text-align:center">' + localizedMessages.get('NO_PERMISSION_TO_MOVE_THE_SELECTED_DOCUMENTS') +
//						'<hr><button class="yesButton" ng-click="closeThisDialog();">' +  localizedMessages.get('CONTINUE') + '</button>' +
//						'</div>',
//						plain: true,
//						scope: $scope
//					});
                    bvDialog.showMessage(localizedMessages.get('NO_PERMISSION_TO_MOVE_THE_SELECTED_DOCUMENTS'));

					return;
				}

				 //Reloading the current Load

				$scope.setSelectedNode(treeSelectionModel.getSelectedNode());

				if(itemParent.objectBaseUuid == treeSelectionModel.getSelectedNode().objectBaseUuid)
				{
					var targetNode = treeService.findNodeUsingBaseUuid(targetBaseUuid, $scope.repositoryTreeModel[0]);
					if(targetNode.collapsed == false)
						treeSelectionModel.getChildrenForTreeNode(targetNode, repositoryService.findFoldersAndSearchObjectsForParentUuid); // Refreshing the target Node
				}
				else
					treeSelectionModel.getChildrenForTreeNode(itemParent, repositoryService.findFoldersAndSearchObjectsForParentUuid); // Refreshing the target Node

				//repositoryModel.repositoryList(data.data);
				if(dragDropObject.dragInitiator == "recycle")
        		{
					refreshRecyleData();
        		}
        	});

		};

//		var getRecyleData = function()
//		{
//			repositoryService.loadRecycleBin().then(function(data)
//        	{
//				repositoryModelService.getInstance("RECYCLE").repositoryList(data.data);
//        	});
//		}

		var loadConfiguationForUploadFile = function(){
        	repositoryService.getConfigForUploadFiles().then(function(data)
        	{
				var uploadFileConfig = data.data;
				uploadFileService.allowedDocumentSize = uploadFileConfig["MAX_FILE_UPLOAD_SIZE"];
				uploadFileService.enableFileUploadSizeRestrict = uploadFileConfig["ENABLE_FILEUPLOAD_SIZE_RESTRICTION"];
				uploadFileService.allowedFileTypes = uploadFileConfig["FILE_EXTENSIONS_ALLOWED"];
				uploadFileService.disAllowedFileTypes = uploadFileConfig["FILE_EXTENSIONS_DISALLOWED"];
				$scope.disableUploadButton = true;
        	});
        }

		var refreshRecyleData = function(){
			loadRecycle.loadRecyleData();
		}

        var getDocumentRootFolder = function (callback)
        {
        	repositoryService.getDocumentRootFolder().then(function(data)
        	{
        		if(data.data.boName != "FailureBO") {
                	rootFolder = data.data;
                	allowedTree = true;
                    var selectedNode = rootFolder

                    backUtiltiy.reloadBackButtonStack(selectedNode);

                    //Check for lastSelectedFolder to be load at, if current instance is a valid one.
                    if ($scope.allowedRememberFolderModules.contains($scope.currentInstance) && sessionStorage.getItem($scope.currentInstance +"-lastSelectedFolder-"+ $scope.$root.SELECTED_PORTAL_BASEUUID)) {
                        selectedNode = JSON.parse(sessionStorage.getItem($scope.currentInstance +"-lastSelectedFolder-" + $scope.$root.SELECTED_PORTAL_BASEUUID));
                    }

					if(!callback)
					    $scope.getDocumentsForParentBaseUuid(selectedNode.objectBaseUuid);

                    //Note: First if is requried when user refreshes broswers from document mdoule url and default landing module is not document, then url router
                    //      calls repository controller and then state is changed to other from home controller. But since this call is made to server and response for
                    //      this call comes after other controller is loaded, it overrides middle bar title set by other controller. HTML-1290.
                    if($state.current.name && $state.current.name=="home.documents")
                    {
                        if(!StringUtils.isNullEmpty($scope.middleBarObject))
						  $scope.middleBarObject.title = backUtiltiy.title;
                    }

                    selectedNode.collapsed = false;

                    uploadFilePermission(selectedNode);

					// $scope.getDocumentForRepositoryTree(rootFolder, callback);

                    $scope.getDocumentForRepositoryTreeRemeberingFolderRemeberingFolder(rootFolder, selectedNode, callback);

                    setSelectedTreeNodeFromList(selectedNode);

					context.homeModel("rootFolderForDocuments",data.data);
                }
        		else {
        			$scope.noDocuments = true;
        			$scope.msg_no_documents = localizedMessages.get('MSG_NO_DOCUMENTS');
        			$scope.none_items=localizedMessages.get("MSG_COMMON_NO_DOCUMENTS");
        		}

        	});

        	if(uploadFileService.allowedDocumentSize == -1 || StringUtils.isNullEmpty(uploadFileService.allowedDocumentSize)) //Load config value for uploading file/folder if it is not loaded.
        	{
        		loadConfiguationForUploadFile();
        	}
        };

		var getAgendaTemplatesRootFolder = function()
		{
			repositoryService.getAgendaTemplatesFolderUuidForCurrentPortal()
			.then(function(data)
			{
				treeSelectionModel.setSelectedNode(data);
			});
		}

        var getFavoritesRootFolder = function ()
        {
        	$scope.showPortalName = true;
            repositoryService.getFavoritesRootFolder().then(function(data)
            {
                var briefCaseRootFolder = data.data;

                treeSelectionModel.setSelectedNode(briefCaseRootFolder);
            });
            //$scope.getDocumentsJSONForBriefCase();
        }

        var getFolderListing = function ()
        {
        	if(!$scope.folderBaseUuid){
        		getDocumentRootFolder();
        	}
        	else{
	            homeService.getBusinessObjectVOJSONByBaseuuid($scope.folderBaseUuid).then(function(data)
	            {
	                rootFolder = data.data;

	                $scope.getDocumentsForParentBaseUuid(rootFolder.objectBaseUuid);

	                if($scope.currentInstance="FOLDER_LISTING")
	                	$scope.hideCheckBox = true;

	                backUtiltiy.reloadBackButtonStack(rootFolder);
	                $scope.middleBarObject.title = backUtiltiy.title;

	                //$scope.getDocumentForRepositoryTree(rootFolder);
	            });
        	}
        };

        var getDocumentSearched = function()
        {

        	repositoryModel = repositoryModelService.getInstance($scope.currentInstance);

        	$scope.repositoryList = repositoryModel.repositoryList(homeContext.searchData);

        }

        var loadModelsForRepository = function()
        {

            treeSelectionModel = treeService.getInstance($scope.currentInstance);

            repositoryModel = repositoryModelService.getInstance($scope.currentInstance);

            $scope.repositoryList = repositoryModel.repositoryList();

        }

        //$scope.rootFolder = {}
        $scope.loadRepositoryList = function(selectLoad)
        {			
            var callback;
            if($scope.openFolder){

                callback = function(){
                    $scope.openFolderInDocumentModule({ objectBaseUuid : $scope.openFolder.objectBaseUuid });
                }
            }
            else{
                callback = undefined;
            }

        	if(selectLoad.indexOf('FOLDER_LISTING') == 0)
        		selectLoad = 'FOLDER_LISTING'

        	loadModelsForRepository();

        	switch(selectLoad)
        	{
    			case 'FAVORITES':getFavoritesRootFolder();break;
    			case 'NEWITEMS':getNewItemsRootFolder();break;
    			case 'FOLDER_LISTING':getFolderListing();break;
    			case 'SEARCH':getDocumentSearched();break;
    			case 'TRASH':refreshRecyleData();break;
				case 'AGENDA_TEMPLATES' :getAgendaTemplatesRootFolder();break;
    			default: getDocumentRootFolder(callback);

                $scope.repositoryList = repositoryModel.repositoryList();
        	}
        }

		var openFile = function(businessObject)
		{
			$scope.openDocument(businessObject);

			addDocumentToBackButtonStack(businessObject);
		};

		var deleteBusinessObject = function(data)
		{
			var successData = data.success;

			if(successData && successData.length > 0)
	        {
	    		var firstChildBaseUuid = successData[0];

	    		if(StringUtils.isFolder(firstChildBaseUuid))
    			{
	    			var parentNode = treeService.findParentNodeById(firstChildBaseUuid, $scope.repositoryTreeModel[0]);

	    			if(!StringUtils.isNullEmpty(parentNode))
    				{
	    				$scope.setSelectedNode(parentNode);
    				}
    			}
	    		else
    			{
	    			for(var i = 0 ,len = successData.length; i < len ; i++)
	    			{
	    				removeDocumentFromList(successData[i]);
	    			}
    			}
	        }
		};

		var onDocumentMove = function(targetFolder, selectedObjects, parent)
		{
			if(StringUtils.isNullEmpty(targetFolder))
	        {
	            return "";
	        }

			var fromBaseUuid  = "";

			if(!StringUtils.isNullEmpty(parent) && parent.indexOf("TREE") != -1)
			{
				fromBaseUuid  = treeService.findParentNodeById(selectedObjects.objectBaseUuid, $scope.repositoryTreeModel[0]).objectBaseUuid;
			}
			else
			{
				fromBaseUuid = treeSelectionModel.getSelectedNode().objectBaseUuid;
			}

	        if(selectedObjects && selectedObjects.length > 0)
	        {
	            documentsToJSONMove = angular.toJson(selectedObjects);
	        }
	        else
	        {
	            documentsToJSONMove = angular.toJson([selectedObjects.objectBaseUuid]);
	        }

	        var request = repositoryService.moveDocuments(fromBaseUuid, targetFolder.objectBaseUuid, documentsToJSONMove, "");

	        return request.then(function(data)
	        {
	        	if(data.data && data.data.boName == "FailureBO")
        		{
	        		bvDialog.showMessage(data.data.message);
	        		return;
        		}
	        	
	        	if(!StringUtils.isNullEmpty(parent) && parent.indexOf("TREE") != -1)
				{
	        		$scope.getDocumentForRepositoryTree(rootFolder);
				}
	        })
		}

		var moveDocument = function(businessObjects, parentContainer)
		{
			bvDialog.openFolderChooser(localizedMessages.get("MOVE"), onDocumentMove, businessObjects, parentContainer);
		}

		function getNewItemsRootFolder()
		{
			repositoryService.getNewItemsRootFolder().then(function(data)
        	{
				var newItemsRootFolder = data.data;

				treeSelectionModel.setSelectedNode(newItemsRootFolder);

				getDocumentsForNewItems();
        	});
		}

		$scope.getDocumentForRepositoryTree = function (rootFolder, callback)
        {
        	$scope.repositoryTreeModel = [rootFolder];

        	treeSelectionModel.getChildrenForTreeNode(rootFolder, repositoryService.findFoldersAndSearchObjectsForParentUuid, callback);
        };

        $scope.getDocumentForRepositoryTreeRemeberingFolderRemeberingFolder = function (rootFolder, selectedNode, callback)
        {
            $scope.repositoryTreeModel = [rootFolder];
            

            if (selectedNode != rootFolder){
                var parentBaseUuid = selectedNode.objectBaseUuid;    
                var callbackTree = function(){
                    repositoryService.getDocumentOrFolderParentObjectIds(parentBaseUuid).then(function(data){
                        var recursiveChain = []
                        recursiveChain[0] = function(){
                            setSelectedTreeNodeFromList(selectedNode, callback, true)
                        };                      

                        if(data && data.parentFolderIds.length > 1){
                            //var listOfParents = data.parentFolderIds.reverse();
                            var listOfParents = data.parentFolderIds;
                            var len = listOfParents.length -1; //Length excluding root

                            var nodes = [];

                            //remove root from the list
                            listOfParents.splice(len);

                            for (var i = 0; i < len; i++) {
                                var node = [];
                                var prevIndex = i;
                                node.objectBaseUuid = listOfParents[i]
                                nodes[i] = node;

                                recursiveChain[i+1] = function(){ 
                                    setSelectedTreeNodeFromList(nodes[prevIndex], recursiveChain[prevIndex], true);
                                    prevIndex = prevIndex-1; 
                                };

                            }

                            recursiveChain[len]();

                        } else {
                            recursiveChain[0]();
                        }
                        
                    });
                }

                treeSelectionModel.getChildrenForTreeNode(rootFolder, repositoryService.findFoldersAndSearchObjectsForParentUuid, callbackTree);

            } else {
                treeSelectionModel.getChildrenForTreeNode(rootFolder, repositoryService.findFoldersAndSearchObjectsForParentUuid, callback);
            }
        };

        var setSelectedTreeNodeFromList = function(parentDoc, callback, addToBackButton = false)
        {
            var children = treeSelectionModel.getSelectedNode().children;

            if(children == undefined)
                return;

            var len = children.length

            for (var i = 0; i < len; i++)
            {
        		if(children[i].objectBaseUuid == parentDoc.objectBaseUuid)
        		{
                    if (addToBackButton) {
                        addDocumentToBackButtonStack(children[i]);
                    }
        			treeSelectionModel.getChildrenForTreeNode(children[i], repositoryService.findFoldersAndSearchObjectsForParentUuid, callback);
                    break;
        		}
			}
			if ( document.getElementById("selectAllCheckBox") && document.getElementById("selectAllCheckBox").classList.contains('deSelectAllLbl') ){
				document.getElementById("selectAllCheckBox").classList.remove('deSelectAllLbl');
				$rootScope.selectAll = false;
			}
        };

        $scope.selectedTreeNode = {};

        /**
        ** Select Tree Node and load listing data
        */

        $scope.setSelectedNode = function(selectedNode, outside_source = false)
        {
        	$scope.reverseSort = false;
        	
            if (outside_source){
                if(allowedTree)
                {
                    treeSelectionModel.getSelectedNode().collapsed = false;

                    setSelectedTreeNodeFromList(selectedNode);
                }

            } else {
                addDocumentToBackButtonStack(selectedNode);

                treeSelectionModel.getChildrenForTreeNode(selectedNode, repositoryService.findFoldersAndSearchObjectsForParentUuid);       
            }

        	uploadFilePermission(selectedNode);
        	$scope.getDocumentsForParentBaseUuid(selectedNode.objectBaseUuid)


            if ($scope.allowedRememberFolderModules.contains($scope.currentInstance)) {
                sessionStorage.setItem($scope.currentInstance +"-lastSelectedFolder-"+ $scope.$root.SELECTED_PORTAL_BASEUUID, JSON.stringify(selectedNode));
            }
        }

        var addToBriefcase = function()
        {
        	var documentsToJSON = getBusinessObjectArrayJSONforSelectedContextMenu();

        	if(documentsToJSON != null)
        	{
	        	repositoryService.addToBriefCase(documentsToJSON);
	        }
        };


        var addToNewItems = function()
        {
        	var documentsToJSON = getBusinessObjectArrayJSONforSelectedContextMenu();

        	if(documentsToJSON != null)
        	{
			    $scope.fileAttachments = [];

			    if($scope.selectedObjectFromContextMenu instanceof Array)
	    	    {
	    		    $scope.fileAttachments = $scope.selectedObjectFromContextMenu;
	    		}
	    		else
	    		{
	    			$scope.fileAttachments.push($scope.selectedObjectFromContextMenu);
	    		}

	    		var bvModal = new modal.open({
	    	        windowClass : 	'bv-modal-window-compose-new-message',
	    	        templateUrl:  	'modules/newitems/AddToNewItems.tpl.html',
	    	        scope:      	$scope,
	    	        controller: 	'AddToNewItemsController',
	    	    });
        	}
        };

        var secureMessage = function()
        {
        	//$rootScope.initiateExternalChat = true;
        	//$state.go('home.securechat');
        }

        var getBusinessObjectArrayJSONforSelectedContextMenu = function()
        {
        	var businessObjectToJSON = null;

			if($scope.selectedObjectFromContextMenu instanceof Array)
			{
				businessObjectToJSON = angular.toJson(StringUtils.getBaseUuidsFromBusinessObjects($scope.selectedObjectFromContextMenu));
			}
			else
			{
				businessObjectToJSON = angular.toJson([$scope.selectedObjectFromContextMenu.objectBaseUuid]);
			}

			return businessObjectToJSON;
        }

        $scope.getLocalizedDate = function(date) {
           return moment($filter('date')(date, 'medium')).format('lll');
        }

        $scope.isFolder = function(docID){
            return StringUtils.isFolder(docID);
        }

        $scope.defaultAgendaTemplateBaseUuid = repositoryService.getDefaultAgendaTemplateBaseUuid();
        $scope.$on('defaultAgendaTemplateBaseUuid:updated', function(event, defaultAgendaTemplateBaseUuid)
        {
            $scope.defaultAgendaTemplateBaseUuid = defaultAgendaTemplateBaseUuid;
        });

        $scope.selectedIndex = -1;
        
        $scope.reverseSort = false;

        $scope.nameToggle = false;
        $scope.typeToggle = false;
        $scope.modifiedToggle = false;


		$scope.loadRepositoryList($scope.currentInstance);

        var sortingByName = function(){      
            repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['name', 'extensionType', 'lastModifiedOnGMTMillis'], $scope.reverseSort);
            $scope.reverseSort = !$scope.reverseSort;               
            document.getElementById("nameToggle").classList.add('add-icon');
            document.getElementById("typeToggle").classList.remove('add-icon');
            document.getElementById("modifiedToggle").classList.remove('add-icon');
            document.getElementById("nameToggle").classList.remove('asc-rotate');  
            document.getElementById("nameToggle").classList.remove('desc-rotate');
            if($scope.reverseSort == true){
                document.getElementById("nameToggle").classList.add('asc-rotate');  
            }           
            else{
                document.getElementById("nameToggle").classList.add('desc-rotate');
            }
        }
        var sortingByType = function(){
            repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, 'extensionType', $scope.reverseSort);
            $scope.reverseSort = !$scope.reverseSort;
            document.getElementById("typeToggle").classList.add('add-icon');
			document.getElementById("nameToggle").classList.remove('add-icon');
            document.getElementById("modifiedToggle").classList.remove('add-icon');
            document.getElementById("typeToggle").classList.remove('asc-rotate');  
            document.getElementById("typeToggle").classList.remove('desc-rotate');
			if($scope.reverseSort == true){
                document.getElementById("typeToggle").classList.add('asc-rotate');  
            }           
            else{
                document.getElementById("typeToggle").classList.add('desc-rotate');
            }
        }
        var sortingByModified = function(){
            repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, ['lastModifiedOnGMTMillis', 'extensionType', 'name'], $scope.reverseSort);
            $scope.reverseSort = !$scope.reverseSort;
            document.getElementById("modifiedToggle").classList.add('add-icon');
			document.getElementById("typeToggle").classList.remove('add-icon');
            document.getElementById("nameToggle").classList.remove('add-icon');
            document.getElementById("modifiedToggle").classList.remove('asc-rotate');  
            document.getElementById("modifiedToggle").classList.remove('desc-rotate');
			if($scope.reverseSort == true){
                document.getElementById("modifiedToggle").classList.add('asc-rotate');  
            }           
            else{
                document.getElementById("modifiedToggle").classList.add('desc-rotate');
            }
        }
        var sortingByStatus = function(){
            repositoryModel.repositoryList().browseList = SortUtility.sortByField(repositoryModel.repositoryList().browseList, 'downloadStatus', $scope.reverseSort);
            $scope.reverseSort = !$scope.reverseSort;
		}
		$rootScope.selectAll = false;
        var checkboxCallback = function(checkboxOn){
			$rootScope.selectAll = !$rootScope.selectAll;
            if($state.$current.name == 'home.trash')
                $scope.callParentController('TRASH_CHECKBOX');

            repositoryModel.repositoryList().browseList.forEach(function(item){
				/*if(item.isPdfACompliant != undefined && item.isPdfACompliant != null && item.isPdfACompliant==="false"){
            		console.log("skipping non-compliant document.");
            	}
            	else*/
            	{
            		item.selected = $rootScope.selectAll;
            	}
			});
			if(document.getElementById("selectAllCheckBox")){
				if($rootScope.selectAll	){
					document.getElementById("selectAllCheckBox").classList.add('deSelectAllLbl');
				}else{
					document.getElementById("selectAllCheckBox").classList.remove('deSelectAllLbl');
				}
			}
        }

        var DivResizeHelper = function () {
            this.width = 379;
            this.x = null;
            this.fileDetWidth = 230;

            this.clearSelections = function () {
                if (document.selection) {
                    document.selection.empty();
                } else {
                    try {
                        window.getSelection().removeAllRanges()
                    } catch (e) {}
                }
            }

            this.onResize = function(evt) {
                this.clearSelections();
                var newWidth = this.width + (this.x - evt.clientX);
                this.width = newWidth < 359 ? 359 : newWidth;
                this.fileDetWidth = newWidth < 359 ? 230 : newWidth-149;
                this.x = evt.clientX;
                $scope.$apply();
            }.bind(this);

            this.onEndResize = function (evt) {
                document.removeEventListener('mousemove', this.onResize);
                document.removeEventListener('mouseup', this.onEndResize);
                this.clearSelections();
                this.x = null;
            }.bind(this);

            this.onStartResize = function (evt) {
                this.x = evt.clientX;
                document.addEventListener('mousemove', this.onResize);
                document.addEventListener('mouseup', this.onEndResize);
            };

        }
        $scope.fileNameDiv = new DivResizeHelper();

		$scope.topRepositoryPanel = {
        templateUrl: 'modules/repository/toppanel/toppanel.template.html',
        name: 'REPOSITORY',
		    events : [
		        { name: 'type',         value : localizedMessages.get('TYPE_UPPERCASE')},
		        { name: 'name',         value : localizedMessages.get('NAME_UPPERCASE')},
				{ name: 'modified',      value : localizedMessages.get('MODIFIED_UPPERCASE')},
				{name:'selectAllLbl', 	value:  localizedMessages.get("SELECT_ALL")},
				{name:'deSelectAllLbl', 	value:  localizedMessages.get("DE_SELECT_ALL")},
		        { name: 'nameClk',      value : sortingByName },
		        { name: 'typeClk',      value : sortingByType },
		        { name: 'modifiedClk',   value : sortingByModified },
		        { name: 'checkboxCallback',   value : checkboxCallback },
				{ name: 'fileNameDiv', value: $scope.fileNameDiv },
                { name: 'hideSelectAll',   value : $scope.hideSelectAll } ,
                { name: 'typeToggle',   value : $scope.typeToggle } ,
                { name: 'nameToggle',   value : $scope.nameToggle } ,
                { name: 'modifiedToggle',   value : $scope.modifiedToggle } 
			]
		}

}

function BVRepositoryListing(legacyUserService){
    return{
        restrict: 'A',
        scope:{
        	repositoryDisplayObjects: '=',
            currentInstance: '@',
            middleBarObject: '=',
            folderBaseUuid: '@',
            callParentController:'=',
			openFolder: '=',
			hideSelectAll:'='
        },
        templateUrl: !legacyUserService.isNasdaqUI ? 'modules/legacyuser/templates/RepositoryListing.tpl.html' : 'modules/repository/RepositoryListing.tpl.html',
        controller: 'RepositoryListingController'
    }
}

function BVInputDialog(){
    return{
        restrict: 'A',
        scope:{
             inputTextValue: '=',
             selectionDone: '=',
             cancelDialog: '=',
             bvinputTitle: '@',
             bvinputPlaceHolder:'@',

        },
        templateUrl: 'modules/repository/CreateFolder.tpl.html',
        controller: function($scope)
        {
            $scope.save = function()
            {
                $scope.selectionDone();
            }

            $scope.cancel = function()
            {
                $scope.cancelDialog();
            }
        }
    }
}

function BVUploadFile(){
    return{
        restrict: 'A',
        scope:
        {
             uploadFileObject:'='
        },
        templateUrl: 'modules/repository/UploadFile.tpl.html',
        controller:['$scope', '$filter', 'permissionsService', 'Upload', 'context', 'constants', 'localizedMessages', 'bvDialogService', 'uploadFileService', 'repositoryService', 'bvDialog', 'sessionTimeoutService', 'legacyUserService', 'repositoryModelService', 'templatesService', 'SmartSelection', 'companyUserService', function($scope, $filter, permissionsService, Upload, context, constants, localizedMessages, bvDialogService, uploadFileService, repositoryService, bvDialog, sessionTimeoutService, legacyUserService, repositoryModelService, templatesService, SmartSelection, companyUserService)
        {
            var loadingFilesIndex;
            angular.element('.documentsList').removeClass('dragDropEvent');//for drag and drop 
            $scope.listofUploadFiles = [];

            $scope.CONTINUE = localizedMessages.get('CONTINUE');
            $scope.CANCEL = localizedMessages.get('CANCEL');
            $scope.alertMsg = localizedMessages.get('ERR_UPLOARD_EXISTING_FILE_NAME_ALERT');

            $scope.ADD = localizedMessages.get('ADD');
            $scope.REMOVE = localizedMessages.get('REMOVE');
            $scope.PERMISSIONS = localizedMessages.get('PERMISSIONS');
            $scope.VIEW_PERMISSIONS = localizedMessages.get('VIEW_PERMISSIONS');
            $scope.SEARCH_TO_ADD_USERS = localizedMessages.get('SEARCH_TO_ADD_USERS');
            $scope.UPLOAD_PERMISSION_TOOLTIP = localizedMessages.get("UPLOAD_PERMISSION_TOOLTIP");

            //ADDED REMOVE_USER, VIEW_PERMISSIONS

            $scope.closeIconSmall = 'resources/assets/svgs/closeDialogSmall.svg';
            $scope.disableUploadButton = true;

            var replaceExistingFile = false;
            var baseId = $scope.uploadFileObject.baseId;
            
            if(!StringUtils.isNullEmpty($scope.uploadFileObject.listofUploadFiles)) {
            	$scope.listofUploadFiles = $scope.uploadFileObject.listofUploadFiles;
            }
            
            if($scope.uploadFileObject.action == "replace")
            {
                $scope.UI_SELECT_FILES_MSG =  localizedMessages.get('MSG_SELECT_FILE_TO_REPLACE_DOCUMENT');
                $scope.SELECT_FILE = localizedMessages.get('SELECT_FILE');
                $scope.UPLOAD = localizedMessages.get('REPLACE');
                $scope.allowMultiple = false;
            }
            else if($scope.uploadFileObject.action == "checkin")
            {
                $scope.UI_SELECT_FILES_MSG =  localizedMessages.get('MSG_SELECT_FILE_TO_CHECKIN_DOCUMENT');
                $scope.SELECT_FILE = localizedMessages.get('SELECT_FILE');
                $scope.UPLOAD = localizedMessages.get('CHECK_IN');
                $scope.allowMultiple = false;
              //  $scope.uploadFileObject.action = "replace";
            }
            else
        	{
                $scope.UI_SELECT_FILES_MSG =  localizedMessages.get('SELECT_FILES_TO_UPLOAD');
            	$scope.allowMultiple = true;
            	$scope.UPLOAD = localizedMessages.get('UPLOAD');
            	$scope.SELECT_FILE = localizedMessages.get('SELECT');
        	}

            //PERMISSIONS:

            $scope.leftArrow = 'resources/assets/svgs/backarrow.svg';
            $scope.rightArrow = 'resources/assets/svgs/arrow.svg';
            $scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
            $scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
            $scope.closeIcon = templatesService.getUrl('closeBtn');

            $scope.dialogTitle = localizedMessages.get('PERMISSIONS');
            $scope.inheritedPermissionsFromParentFolderLabel = localizedMessages.get('INHERITED_PERMISSIONS_FROM_PARENT_FOLDER');
            $scope.explicitPermissionsLabel = localizedMessages.get('EXPLICIT_PERMISSIONS');
            $scope.nameLabel = localizedMessages.get('NAME');
            $scope.descriptionLabel = localizedMessages.get('DESCRIPTION');
            $scope.saveButtonLabel = localizedMessages.get('SAVE');
            $scope.colorLabel = localizedMessages.get('COLOR');
            $scope.typeLabel = localizedMessages.get('TYPE');
            $scope.nameLabel = localizedMessages.get('NAME');
            $scope.permissionLabel = localizedMessages.get('PERMISSION');
            $scope.popupTitle = localizedMessages.get('PERMISSIONS');
            $scope.actionBtnTxt = localizedMessages.get('SAVE');
            $scope.closeBtnTxt = localizedMessages.get('CANCEL');
            $scope.folder = localizedMessages.get('FOLDER');
            $scope.users = localizedMessages.get('USERS');
            $scope.addLabel = localizedMessages.get('ADD');

            $scope.tooltipOwner = localizedMessages.get('PERMISSION_TOOLTIP_OWN');
            $scope.tooltipShare = localizedMessages.get('PERMISSION_TOOLTIP_COLLABORATE');
            $scope.tooltipPrint = localizedMessages.get('PERMISSION_TOOLTIP_PRINT');
            $scope.tooltipRead = localizedMessages.get('PERMISSION_TOOLTIP_VIEW');
            $scope.tooltipDeny = localizedMessages.get('TOOLTIP_DENY');

            var teamspaceTitle = localizedMessages.get('TEAMSPACE');
            var companyTitle = localizedMessages.get('COMPANY');

            $scope.folderHierarchyLabel = localizedMessages.get('FOLDER_HIERARCHY');
            $scope.applyToSubfoldersAndFilesLabel = localizedMessages.get('APPLY_TO_SUBFOLDERS_AND_FILES');
            $scope.dragDropLabel = localizedMessages.get('DRAG_AND_DROP_USER_TO_ADD');

            $scope.listOfPermissions = [];
            $scope.folderUserList = [];

            $scope.showApplyToSubFoldersOption = context.getModel().enableApplyToSubFolders; //HTML-2171
            $scope.modelCheckbox = {
                //                  showInherit: true,
                //                  showExplicit: true,
                applyToSub: false //HTML-2171
            }
            $scope.allCompanyUsers = angular.copy(companyUserService.getPortalGroupsAndUsers()); 

            var options = []
            for (var i in context.getModel().permissionDisplayNames) {
                options.push(context.getModel().permissionDisplayNames[i]);
            }
            $scope.selectOptions = [];

            $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['owner'].toUpperCase()), value: context.getModel().permissionDisplayNames['owner'], title: $scope.tooltipOwner });
            $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['share'].toUpperCase()), value: context.getModel().permissionDisplayNames['share'], title: $scope.tooltipShare });
            $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['read'].toUpperCase()), value: context.getModel().permissionDisplayNames['read'], title: $scope.tooltipRead });
            $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['print'].toUpperCase()), value: context.getModel().permissionDisplayNames['print'], title: $scope.tooltipPrint });
            $scope.selectOptions.push({ label: localizedMessages.get(context.getModel().permissionDisplayNames['deny'].toUpperCase()), value: context.getModel().permissionDisplayNames['deny'], title: $scope.tooltipDeny });

            //              if(StringUtils.isFolder($scope.itemObjectBaseUuid))
            //                  $scope.selectOptions.push( { label: localizedMessages.get(context.getModel().permissionDisplayNames['deny'].toUpperCase()), value: context.getModel().permissionDisplayNames['deny'], title:  $scope.tooltipDeny  } );

            var selectOptions_ = angular.copy($scope.selectOptions);

            $scope.tabsUsersList = [
                { title: teamspaceTitle, content: teamspaceTitle },
                { title: companyTitle, content: companyTitle }
            ]

            $scope.showUserList = false;
            $scope.showFolderList = false;
            $scope.grouping = true;

            $scope.permission = {};
            $scope.permissionOpen = false;
            $scope.permissionIndex = -1;
            $scope.showUserFiltering = false;
            $scope.filteredUserList = [];

            // $timeout(function () {
            //  $timeout(function () {
            //      commonService.getUsers().then(function (data) {
            //          $scope.allUsers = data;
            //      });
            //  }, 0);
            // }, 1000);


            $scope.openPermissions = function(index)
            {
                if (!$scope.permissionOpen && $scope.permissionIndex != index) {
                    var uploadFileModal = document.getElementsByClassName("bv-modal-window-repository-upload-file")[0];
                    uploadFileModal.classList.add("show-permissions");

                    $scope.permissionOpen = true;
                    $scope.permissionIndex = index;
                }
            }

            $scope.closePermissions = function(index)
            {              

                var uploadFileModal = document.getElementsByClassName("bv-modal-window-repository-upload-file")[0];
                uploadFileModal.classList.remove("show-permissions")

                $scope.permissionOpen = false;
                $scope.permissionIndex = -1;
                $scope.showUserFiltering = false;
            }

            

            $scope.isUser = function(user)
            {
                 return user.objectBaseUuid == context.getModel().userVO.objectBaseUuid;
            }

            $scope.isUserSelected = function(user)
            {
                var result = false;
                $scope.listOfPermissions[$scope.permissionIndex].forEach(function (userSelected, i) {
                    if (userSelected.objectBaseUuid == user.objectBaseUuid) {
                        result = true;
                    }
                });

                return result
            }

            $scope.removeUser = function(user)
            {
                $scope.listOfPermissions[$scope.permissionIndex].forEach(function (userSelected, i) {
                    if (userSelected.objectBaseUuid == user.objectBaseUuid) {
                        $scope.listOfPermissions[$scope.permissionIndex].splice(i, 1)
                    }
                });
            }

            $scope.addUser = function(user)
            {
                $scope.listOfPermissions[$scope.permissionIndex].push({
                    name: user.name,
                    type: user.type,
                    objectBaseUuid: user.objectBaseUuid,
                    permission: context.getModel().permissionDisplayNames[context.getModel().defaultDocumentPermission]
                });
            }

            var getPermissionsByObjectBaseUuid = function (objectBaseUuid) {
                permissionsService.getPermissionFromProperties(objectBaseUuid).then(function (data) {
                    $scope.folderUserList = data;
                    $scope.folderUserList = getUpperCaseName($scope.folderUserList);

                    $scope.userListEnable = true;
                    var hasCurrentUser = false;
                    $scope.folderUserList.forEach(function (item, i) {
                        if ($scope.isUser(item)) {
                            ($scope.folderUserList.splice(0, 0, $scope.folderUserList[i]), $scope.folderUserList.splice(i + 1, 1));
                            hasCurrentUser = true;  
                        }
                    });

                    //add current user if he is not present on the folder permissions
                    if (!hasCurrentUser) {
                        var currentUser = context.getModel().userVO
                        $scope.folderUserList.unshift({
                            name: currentUser.name,
                            type: currentUser.type,
                            objectBaseUuid: currentUser.objectBaseUuid,
                            permission: context.getModel().permissionDisplayNames['owner']
                        });    
                    }

                    for(var i = 0; i < $scope.listofUploadFiles.length; i++) {
                        $scope.listOfPermissions.push(JSON.parse(JSON.stringify($scope.folderUserList)));
                    }
                });
            }

            $scope.toggleDropdown = function ($event, user) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.selectOptions = selectOptions_;

                user.isopen = !user.isopen;
            };

            $scope.toggled = function (isopen) {
                if (!isopen) {
                    $scope.selectOptions = selectOptions_;
                }
            }

            getPermissionsByObjectBaseUuid($scope.uploadFileObject.parentId)

            function getUpperCaseName(array) {
                if (array) {
                    for (var i = 0; i < array.length; i++) {
                        var type = '';
                        for (var k = 0; k < array[i].type.length; k++) {
                            if (array[i].type[k] == array[i].type[k].toUpperCase()) {
                                type = type + array[i].type[k];
                            }
                        }
                        type = constants.get('GET_SHORT_OBJECT_NAME_' + type);
                        if (array[i].typeM != type) {
                            array[i].typeM = type;
                        }
                    }
                }
                return array;
            }

            $scope.checkFilter = function(filterValue)
            {
                console.log('checkFilter > ' + filterValue);
                if(filterValue && filterValue.trim() !=="")
                {
                    $scope.showUserFiltering = true;
                    $scope.filteredUserList = $filter('filter')($scope.allCompanyUsers, filterValue);;
                } else {
                    $scope.showUserFiltering = false;
                    $scope.filteredUserList = [];
                }
            }

            //END PERMISSIONS

            $scope.cancel = function ()
            {
            	$scope.uploadFileObject.cancel();
            }
            
            var repositoryModel = repositoryModelService.getInstance('DOCUMENTS');
            var replist = repositoryModel.repositoryList();

            $scope.checkExistingFiles = function()
            {
            	var fileExisting = false;
            	if($scope.uploadFileObject.action == "upload"){
        			for(var i = 0; i < $scope.listofUploadFiles.length; i++) {
        				if(fileExisting) {
        					break;
        				}
        				for(var j = 0; j < replist.browseList.length; j++){
        					if(replist.browseList[j].boName == "DocumentBO" && $scope.listofUploadFiles[i].name == replist.browseList[j].name ) 
        					{
        						fileExisting = true;
        					}
        				}
        			}
                }
            	
            	if(fileExisting)
            	{
            		bvDialog.showContinue($scope.alertMsg, localizedMessages.get('CONTINUE'), $scope.uploadSelectedFiles);
            	} else {
            		$scope.uploadSelectedFiles();
            	}
            }
            
            var validateUpload = function()
            {
            	if ($scope.listofUploadFiles.length == 0) {

            		if($scope.uploadFileObject.action == "replace" || $scope.uploadFileObject.action == "checkin")
                        bvDialog.showMessage(localizedMessages.get('MSG_FILE_MUST_BE_SELECTED_TO_CONTINUE'));
                    else
                        bvDialog.showMessage(localizedMessages.get('MST_ATLEAST_ONE_FILE_MUST_BE_ADDED_TO_CONTINUE'));

            		return false;
                }

            	var temp = [];

            	var invalidFileSize = "";
            	var invalidFileType = "";

        		for (var i = 0, len = $scope.listofUploadFiles.length; i < len; i++) {
        		   var badFile = false;

        		   if (uploadFileService.enableFileUploadSizeRestrict && $scope.listofUploadFiles[i].size > (uploadFileService.allowedDocumentSize * 1024 * 1024))
      			   {
        			   invalidFileSize =  invalidFileSize + (i+1)+" : "+$scope.listofUploadFiles[i].name + "\n\r" ;
        			   badFile = true;
      			   }

        		   var extension = $scope.listofUploadFiles[i].name.substr($scope.listofUploadFiles[i].name.lastIndexOf('.') + 1).toLowerCase();

        		   if(!StringUtils.isNullEmpty(uploadFileService.allowedFileTypes) && uploadFileService.allowedFileTypes.split("#").indexOf(extension) == -1)
                   {
        			   invalidFileType =  invalidFileType + (i+1)+" : "+$scope.listofUploadFiles[i].name + "\n\r" ;
      				   badFile = true;
                   }
        		   else if (uploadFileService.disAllowedFileTypes.split("#").indexOf(extension) != -1) {
      				   invalidFileType =  invalidFileType + (i+1)+" : "+$scope.listofUploadFiles[i].name + "\n\r" ;
      				   badFile = true;
  				   }

      			   if(!badFile)
  				   {
      				   temp.push($scope.listofUploadFiles[i])
  				   }
      			}

        		$scope.listofUploadFiles = temp;

        		if(invalidFileSize != "")
    			{
        			var fileSizeString = localizedMessages.get('MSG_FILES_SIZE', {n: uploadFileService.allowedDocumentSize});
        			// fileSizeString = fileSizeString.replace("#n", uploadFileService.allowedDocumentSize);
        			//bvDialogService.showBvAlertMessage(fileSizeString+ "\n"+ invalidFileSize);

        			bvDialog.showMessage(fileSizeString+ "\n\r"+ invalidFileSize,'bv-file-size-confirm ');

        			return false;
    			}

        		if(invalidFileType != "")
    			{
        			bvDialog.showMessage(localizedMessages.get('MSG_INVALID_FILE_TYPE')+ "\n\r"+ invalidFileType,'bv-file-size-confirm');
        			return false;
    			}

            	return true;
            }

            $scope.uploadSelectedFiles = function()
            {
                loadingFilesIndex = 0;

                if(!validateUpload())
            	{
                	return
            	}

                var invalidFiles = [];
                validateUploadFile($scope.listofUploadFiles, invalidFiles);

                if($scope.uploadFileObject.action == "replace")
                {
                	replaceSelectedFile();
                }  
                else if($scope.uploadFileObject.action == "checkin"){
                	var selectedFileName = $scope.uploadFileObject.docName;
                	var ext = selectedFileName.substring(selectedFileName.lastIndexOf('.')+1);
                	var len = $scope.listofUploadFiles.length;
                	$scope.isExtDiff = false;
                	for (var i = 0; i < len; i++) {
                		var uploadedDocName = $scope.listofUploadFiles[i].name;
                		var uploadedDocExt = uploadedDocName.substring(uploadedDocName.lastIndexOf('.')+1);
                		if(ext !== uploadedDocExt){
                			$scope.isExtDiff = true;
                			var documentFileType = localizedMessages.get('DOCUMENT_FILE_TYPE');
                			bvDialog.confirm(documentFileType, replaceSelectedFile, null);
                		}else{
                			replaceSelectedFile();
                		}
               	 	}
//                	if(!$scope.isExtDiff){
//                		replaceSelectedFile();
//                	}
                }
                else
                {
                	if($scope.selectedNode)
            		{
                		for(var i=0; i<$scope.selectedNode.length; i++)
                        {
                        	if($scope.selectedNode[i].name == $scope.listofUploadFiles[loadingFilesIndex].name && !replaceExistingFile)
                        	{
                        		$scope.uploadFileObject.uploadComplete();
//                        		ngDialog.open({
//                		        	template:'modules/repository/UploadFileAlert.tpl.html',
//                		    	    scope: $scope
//                		    	});
                        		if(!replaceExistingFile)
                        		{
                        			return;
                        		}

                        	}
                        }
            		}

                	loadSingleFile($scope.listofUploadFiles[loadingFilesIndex], null, $scope.listOfPermissions[loadingFilesIndex]);
                }
            };

            var replaceSelectedFile = function()
            {
                loadSingleFile($scope.listofUploadFiles[loadingFilesIndex], baseId);
            };

            var validateUploadFile = function(files, invalidFiles)
            {
            	 for (var i = 0, len = files.length; i < len; i++) {

            	 }
            }

            var convertPermissions = function(permissions, objectbaseuuid) {
                var convertedPemissions = []
                if (permissions != undefined) {
                    for (var i = 0, len = permissions.length; i < len; i++) {
                        var convertePemission = {
                            uuid: objectbaseuuid,
                            permission: permissions[i].permission,
                            isReadPermissionThroughShortcut: false,
                            name: permissions[i].name,
                            objectBaseUuid: permissions[i].objectBaseUuid,
                            isInHerit: false,
                            type: permissions[i].type,
                            versionNumber: 0,
                            showInBriefCase: false,
                            locked: false,
                            isShortCut: false,
                            isFavorite: false,
                            hasWorkflow: false,
                            typeM: convertTypeMUser(permissions[i].type),
                            isopen: false   
                       }
                       convertedPemissions.push(convertePemission);
                    }
                }
                return convertedPemissions
            }

            var convertTypeMUser = function(type) {
                if (type == "User") return "U"
                if (type == "Portal") return "TS"
                if (type == "Group") return "G"
            }


            var loadSingleFile = function(file, objectBaseUuid, permissions = undefined)
            {
				if ( document.getElementById("selectAllCheckBox") && document.getElementById("selectAllCheckBox").classList.contains('deSelectAllLbl') ){
					document.getElementById("selectAllCheckBox").classList.remove('deSelectAllLbl');
					$scope.$root.selectAll = false;
				}

                if (permissions != undefined) {
                    $scope.uploadFileObject.action = "uploadWithPermissions";
                }

            	$scope.showUploadButton = true;
            	file.uploadFile = true;
            	var fileNotSupported = "";
            	if($scope.uploadFileObject.action == "checkin" || $scope.uploadFileObject.action == "replace"){
            		$scope.uploadFileObject.action = "replace"; 
              }
              
              const fields = {
                csrfToken: context.getModel().csrfToken,
                action: $scope.uploadFileObject.action,
                totalFiles: $scope.listofUploadFiles.length,
                objectbaseuuid: objectBaseUuid,
                uploadNumber: loadingFilesIndex,
                parentbaseuuid: $scope.uploadFileObject.parentId,
                permissions: convertPermissions(permissions, objectBaseUuid)
              }

              if($scope.uploadFileObject.annotationChoice)
                fields.annotationChoice = $scope.uploadFileObject.annotationChoice
               
              Upload.upload({
                url: '/services/fileupload',
                file:file,
                fields: fields
              })
              .progress(function(evt) {
                console.log('percent: ' + parseInt(100.0 * evt.loaded / evt.total));
                file.uploadProgressValue = parseInt(100.0 * evt.loaded / evt.total);
                var isIdle = sessionTimeoutService.isIdle();
              console.log(" viewer is idle " + isIdle);
              }).success(function(data, status, headers, config){
                console.log("File Uploaded Successfully "+data);
                file.uploadProgressValue = 100;
                continueUpload(fileNotSupported);
                
                if(!StringUtils.isNullEmpty(objectBaseUuid))
                    repositoryService.checkInCheckOut(objectBaseUuid, false, false);

              }).error(function(data, status, headers, config){
                console.log("Error Uploading File "+data);
                $scope.showUploadButton = false;
                fileNotSupported = config.file.name;
                continueUpload(fileNotSupported);
              });
            }

            /**
             * Upload next file after sucess/error of previous file.
             * Close the PopUp window after all the files uploaded
             */
            var continueUpload = function(fileNotSupported )
            {
                loadingFilesIndex ++;
                var filesNotSupported = [];
                var errMsg = "";
                if (fileNotSupported != "")
           	 	{
                	filesNotSupported.push(fileNotSupported);
           	 	}
                if($scope.listofUploadFiles.length > loadingFilesIndex)
                {
                    var nextFile = $scope.listofUploadFiles[loadingFilesIndex];

                    loadSingleFile(nextFile, null, $scope.listOfPermissions[loadingFilesIndex]);
                }
                else
                {
                	var fn = $scope.uploadFileObject.uploadComplete;

                    if (fn && (typeof fn === 'function'))
				    {
                    	fn();
				    }
//                    else
//                    {
//                    	ngDialog.close();
//                    }
                }
                for (var i = 0, len = filesNotSupported.length; i < len; i++) {
         			   errMsg =  errMsg + (i+1)+": "+filesNotSupported[i] + "\n\r" ;
   			    }
                if(errMsg != "")
             	{
                	var faultMsg = localizedMessages.get('MSG_FILES_NOT_UPLOADED')
                	bvDialog.showMessage(faultMsg +"\n\r"+ errMsg,'bv-files-not-uploaded');
             	}

            }

            /**
              * onFileSelect store all the files in listofUploadFiles which are selected from Browser
              *
            */

            $scope.onFileSelect = function($files)
            {
                for (var i = 0; i < $files.length; i++)
                {
                    var file = $files[i];
                    console.log(file);
                    if($scope.uploadFileObject.action == "replace" || $scope.uploadFileObject.action == "checkin")
                    {
                        $scope.listofUploadFiles = [];
                        $scope.listofUploadFiles.push(file);
                        $scope.listOfPermissions = [];
                        $scope.listOfPermissions.push(JSON.parse(JSON.stringify($scope.folderUserList)));
                    }
                    else
                    {
                        $scope.listofUploadFiles.push(file)
                        $scope.listOfPermissions.push(JSON.parse(JSON.stringify($scope.folderUserList)));
                    }
                }

            }

            $scope.handleExistingFiles = function(arg){
            	if(arg == 'continue')
            	{
            		replaceExistingFile = true;
                	$scope.uploadSelectedFiles();
            	}
            	//ngDialog.close();
            }

            $scope.removeFromUploadFile = function(index)
            {
                $scope.listofUploadFiles.splice( index, 1 );
                $scope.listOfPermissions.splice( index, 1 );

            }

            if(!legacyUserService.isNasdaqUI && $scope.listofUploadFiles.length > 0)
        		$scope.uploadSelectedFiles();

        }]
    }

};

function UploadFileService()
{
	this.allowedDocumentSize = -1;
	this.enableFileUploadSizeRestrict = -1;
	this.allowedFileTypes = "";
	this.disAllowedFileTypes = "";
};

angular
	.module('repositoryListing.module',['repository.service','localization.service','bvdialog.service','common.service','sortUtility.service','bvDocTreeView','context.service','conference.module', 'ui.bootstrap','collate.module','url.service'])
	.controller('RepositoryListingController', ['$rootScope', '$scope', 'repositoryService','localizedMessages','bvDialogService', 'conferenceService', 'commonService','$state','SortUtility', '$modal','treeService','context', 'constants','documentContextMenu','permissionService','bvPollingProcessor','registerStatusCategory','repositoryModelService','copyPasteService','backUtiltiyService','homeService','permissionsService','urlService','contextMenuModel','contextMenuHandler', 'loadRecycle','selectionStack','bvDialog','homeContext','$filter', 'homeModel','uploadFileService','newItemsService', 'toppanelService', 'templatesService', 'SmartSelection', 'companyUserService', '$timeout',RepositoryListingController])
	.directive('bvInputDialog', BVInputDialog)
    .directive('bvUploadFile', BVUploadFile)
    .service('uploadFileService', [UploadFileService])
    .directive('bvRepositoryListing', BVRepositoryListing)
