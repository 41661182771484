adSearchDialogService.$inject = ['bvDialogService', 'adSearchService', '$timeout', 'homeService'];

function adSearchDialogService(bvDialogService, adSearchService, $timeout, homeService){

    this.dialog = {}

    this.showAdSearch = function (searchString, element){

        var dialogElement = document.querySelector('.ad-search-dialog');

        if('close' in this.dialog && dialogElement){
            var searchObject = adSearchService.getDefaultInstance();
            adSearchService.getSearchResult(searchObject, searchString);
        }
        else{
            this.dialog = bvDialogService.showBvModal({
                windowClass : homeService.homeContext.expandedMenu ? 'ad-search-dialog sidebar-expanded' : 'ad-search-dialog',
                backdropClass: homeService.homeContext.expandedMenu ? 'ad-search-dialog sidebar-expanded' : 'ad-search-dialog',
                controller : 'adSearchDialogController',
                buttons      : null,
                showClose : false,
                showHeader : false,
                keyboard : false,
                bodyTemplate : 'modules/dialogs/adsearch/adSearchDialog.tpl.html',
                inputs : {
                    searchString: searchString,
                    element: element
                }
            });

            this.dialog.rendered.then(function(){
                if(element){
                    element[0].focus();
                }
            });
        }
    }

}