function PermissionService()
{
	var ownerPermissions = ['CREATE','DELETE','READ','EDIT','GRANT','SAVE'];
	var readPermissions = ['READ'];
	var printPermissions = ['READ','SAVE'];
	var editPermissions = ['EDIT'];
	var sharingPermissions = ['READ','SHARE','SAVE'];
	var deletePermissions = ['DELETE'];
	var sharingandeditPermissions = ['CREATE','READ','EDIT','SHARE','SAVE'];
	var deniedPermissions = ['DENY'];
	var viewPermissions = ['READ'];

	this.isOwner = function(businessObject)
	{
		if(StringUtils.isNullEmpty(businessObject))
			return false;

		return getPermission(businessObject.permissions,ownerPermissions)
	}


	 this.isPasteAllowed = (businessObject, copyPasteService,folderObject) => {
	
		if(StringUtils.isNullEmpty(businessObject))
			return true;

	    let isPasteAllowed = (copyPasteService.currentAction && copyPasteService.currentAction=== copyPasteService.COPY)|| !folderObject ?  true : false;
		let viewPermissionPresent=false;
		if(!isPasteAllowed) {

			viewPermissionPresent = getPermission(businessObject.permissions,viewPermissions);
			isPasteAllowed = viewPermissionPresent && businessObject.permissions.length===1?false:true; //make a strict check for only view permission with permission.length

		}
		return isPasteAllowed;
	}

	var getPermission = function(permission, permissionsCheck)
	{
		if(permission === undefined)
			return false;

		var objectPermission = permission.toString()

		for (var i=0; i < permissionsCheck.length; i++)
		{
		    if(objectPermission.indexOf(permissionsCheck[i]) == -1)
		        return false
		}
		return true;
	}

	this.isRead = function(businessObject)
	{
		return getPermission(businessObject.permissions, readPermissions);
	}

	this.isEdit = function(businessObject)
	{
		return getPermission(businessObject.permissions, editPermissions);
	}

	this.isDeleteAllowed = function(businessObject)
	{
		return getPermission(businessObject.permissions, deletePermissions);
	}

	this.isShareAllowed = function(businessObject)
	{
		if(StringUtils.isNullEmpty(businessObject))
			return false;

		return getPermission(businessObject.permissions, sharingPermissions);
	}

	this.isPrintAllowed = function(businessObject)
	{
		return getPermission(businessObject.permissions, printPermissions);
	}

	this.isDenied = function(businessObject)
	{
		return getPermission(businessObject.permissions, deniedPermissions);
	}
	
	this.isCollaborate = function(businessObject)
	{
		return getPermission(businessObject.permissions, sharingandeditPermissions);
	}

}