httpServiceConfig.inject = ['$provide', '$httpProvider'];

function httpServiceConfig($provide, $httpProvider) {

	// Intercept http calls.
	$provide.factory('httpInterceptor',['$q', '$cacheFactory', '$rootScope', '$injector', '$timeout', 'bvLoadingSpinner', 'constants', 'legacyUserService', function ($q, $cacheFactory, $rootScope, $injector, $timeout, bvLoadingSpinner, constants, legacyUserService) {
		var reqsTotal = 0;
		var reqsCompleted = 0;
		var reqsUrls = new Set();

		function setComplete() {
			bvLoadingSpinner.complete();
			reqsCompleted = 0;
			reqsTotal = 0;
		}

		function isCached(config) {
			var cache;
			var defaults = $httpProvider.defaults;

			if(!config){
				return false;
			}

			if (config.method !== 'GET' || config.cache === false) {
				config.cached = false;
				return false;
			}

			if (config.cache === true && defaults.cache === undefined) {
				cache = $cacheFactory.get('$http');
			} else if (defaults.cache !== undefined) {
			  	cache = defaults.cache;
			} else {
			  	cache = config.cache;
			}

			var cached = cache !== undefined ?
			  	cache.get(config.url) !== undefined : false;

			if (config.cached !== undefined && cached !== config.cached) {
			  	return config.cached;
			}
			config.cached = cached;
			return cached;
		}

	    return {
	      // On request success
	      request: function (config) {
	        // attach the jsonwebtoken in header.
	    	// if(window.console)  console.log(config);
	        // Return the config or wrap it in a promise if blank.

    	  config.headers = config.headers || {};
          if(localStorage.requestCsrfToken)
    	  {
        	  config.headers.Authorization = "requestCsrfToken:" + localStorage.requestCsrfToken + ";";
    	  }
          var fragment = window.location.hash;
          if(fragment && fragment.length>0){        	  
        	  config.headers['Fragment'] = fragment.substr(1);
          }

          if(legacyUserService.isNasdaqUI){
        	  config.headers['X-NBV-Pname']='NBV Browser';
          }else{  
        	  if(window.userActivityIdForHeader)
        		config.headers['X-NBV-Pname']='H5 Browser';
		  }
		  //Adding ActivityID to request header to link EUM and APM data
          if(window.userActivityIdForHeader)
		  		config.headers["nbv_ActivityID"] = window.userActivityIdForHeader;
		  //Adding service and method name values to request header 
		  if(config.data){
			var dat = config.data.toString().split('&');
			for (var i in dat) {
				if (dat[i].indexOf("servicename=") == 0) {
					config.headers["nbv_ServiceName"] = dat[i].substring(12);
				}
				if (dat[i].indexOf("methodname=") == 0) {
					config.headers["nbv_MethodName"] = dat[i].substring(11);
				}
			}			  
		  }

	      if(MobileUtil.isHttpProxyCheckRequired())
	    	  	config.headers['htmlProxy'] = 'htmlProxy';
	    	  
	      var acceptHeader = config.headers.Accept;
	          if(acceptHeader) {
	        	  // below code changes: replaced ',' with ';' for concat client revision with acceptheader its give bad request for rest calls
	        	  // need to test this code properly in all environments
	        	  acceptHeader += ';' + constants.get('CLIENT_REVISION');
	        	  
	        	  config.headers.Accept = acceptHeader;
	          }


				if (!isCached(config))
				{
					if(config.data === undefined || config.data.toString().indexOf('servicename=BvJsonPollingService') == -1)
					{
						reqsUrls.add(config.url);
//						$rootScope.$broadcast('bvLoadingSpinner:loading', {url: config.url});
						if(typeof config.data == 'object' && config.data.filterCriteria.username != null){
							bvLoadingSpinner.complete();
						}else{
							var bvContainer = document.getElementById('bvContainer');
							if(bvContainer && bvContainer.parentNode.getAttribute('state') == 'home.email'){
								bvLoadingSpinner.complete();
							}else{
								bvLoadingSpinner.start();
							}
						}
						reqsTotal++;
						
					}
				}
				return config || $q.when(config);
	      },

	      // On request failure
	      requestError: function (rejection) {
	        // console.log(rejection); // Contains the data about the error on the request.
	        if (rejection.status === 401) {
	             $injector.get('$state').transitionTo('logout');
	         }

	        // Return the promise rejection.
	        return $q.reject(rejection);
	      },

	      // On response success
	      response: function (response) {
	        // console.log(response); // Contains the data from the response.

	    	if (!isCached(response.config)) {
	    		reqsUrls.delete(response.config.url);
	    		reqsCompleted++;
//				$rootScope.$broadcast('bvLoadingSpinner:loaded', {url: response.config.url});
				if (reqsCompleted >= reqsTotal && reqsUrls.size == 0) {
				  setComplete();
				}
			}

			if(response && response.data && response.data.exceptionCode && (response.data.exceptionCode == 9 || response.data.exceptionCode == 12)) {
				var currentState = $injector.get('$state');
				if(!currentState.current.name || currentState.current.name.indexOf('login') == 0 ||
						currentState.current.name === 'logout' || currentState.current.name === 'notsupportedbrowsertypeorversion')
				{
					// just continue
				} else {
					$timeout(function() {
						currentState.transitionTo('nosession');
					});
				}
				return $q.reject(response);
			}

	        // Return the response or promise.
	        return response || $q.when(response);
	      },

	      // On response failture
	      responseError: function (rejection) {
	        // console.log(rejection); // Contains the data about the error.
	        if (rejection.status === 401) {
	        	$injector.get('$state').transitionTo('logout');
	        }

	    	if (!isCached(rejection.config)) {
	    		reqsUrls.delete(rejection.config.url);
				reqsCompleted++;
//				$rootScope.$broadcast('bvLoadingSpinner:loaded', {url: rejection.config.url});
				if (reqsCompleted >= reqsTotal && reqsUrls.size == 0) {
				  setComplete();
				}
			}
	        // Return the promise rejection.
	        return $q.reject(rejection);
	      }
	    };
	}])

	$httpProvider.interceptors.push('httpInterceptor');
}